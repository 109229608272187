import { paymentApi } from "./endpoint";
import { TransactionPredebited, TransactionsQuery, QuoteQuery } from "../models/transactions";
import { PaymentAPIError, Partner } from "@/store/models/partnerSettings";
import { getPartner } from "../apis/partnerSettings";

export async function transactionList(query: TransactionsQuery): Promise<TransactionPredebited[] | PaymentAPIError> {
  const data = {
    start_seconds: query.start_date,
    end_seconds: query.end_date,
    from: query.from,
    size: query.size,
  };
  const params = Object.keys(data).map((k) => `${k}=${data[k]}`).join("&");
  return (await paymentApi()).get(`/transaction-searches?${params}`)
    .then((response) => response.data.result.results as TransactionPredebited[])
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function createQuote(amount: number): Promise<{ [key: string]: boolean } | PaymentAPIError> {
  const userToken = localStorage.getItem("user-token");
  const data = {
    credit_gateway_name: "pa_tabapay",
    sender_amount: amount,
    sender_currency_code: "USD",
    sender_country_code: "US",
    beneficiary_currency_code: "USD",
    beneficiary_country_code: "US",
  };
  try {
    const response = await (await paymentApi()).post("/demo/quotes", data, { headers: { Authorization: `Bearer ${userToken}` } });
    return response.data as { [key: string]: boolean };
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function acceptQuote(query: QuoteQuery): Promise<{ [key: string]: boolean } | PaymentAPIError> {
  const userToken = localStorage.getItem("user-token");
  const data = {
    sender: {
      first_name: query.senderFirstName,
      last_name: query.senderLastName,
      email: query.senderEmail,
      phone: query.senderPhone,
    },
    beneficiary: {
      first_name: query.beneficiaryFirstName,
      last_name: query.beneficiaryLastName,
      email: query.beneficiaryEmail,
      phone: query.beneficiaryPhone,
    },
  };
  try {
    const response = await (await paymentApi()).post(`/demo/quotes/${query.quoteId}/acceptances`, data, { headers: { Authorization: `Bearer ${userToken}` } });
    return response.data as { [key: string]: boolean };
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function getTransaction(transaction_id: string | string[], jwt: string | string[]): Promise<TransactionPredebited | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).get(`/transactions/${transaction_id}`, { headers: { Authorization: `Bearer ${jwt}` } });
    return response.data as TransactionPredebited;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function getTransactionDetails(transaction_id: string | string[], jwt: string | string[]): Promise<TransactionPredebited | PaymentAPIError> {
  const userToken = localStorage.getItem("user-token");
  try {
    const response = await (await paymentApi()).get(`/transactions/detail/${transaction_id}`, { headers: { Authorization: `Bearer ${userToken}` } });
    return response.data as TransactionPredebited;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function completeTransaction(transaction_id: string, jwt: string, card: any, address: any): Promise<{ [key: string]: boolean } | PaymentAPIError> {
  const data = {
    PAN: card.number,
    zip: card.zip,
    exp_date: card.expDate,
    name_on_card: card.nameOnCard,
    address: {
      street: [address.line1, address.line2].filter(Boolean),
      city: address.city,
      state: address.state,
      country: address.country,
      postal: address.zip,
    },
  };
  try {
    const response = await (await paymentApi()).post(`/transactions/${transaction_id}/complete`, data, { headers: { Authorization: `Bearer ${jwt}` } });
    return response.data as { [key: string]: boolean };
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

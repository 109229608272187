
































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import AddressForm from "@/components/AddressForm.vue";
import { TheMask } from "vue-the-mask";
import onboarding from "@/store/modules/onboarding";
import File from "@/components/onboarding/File.vue";
import { uploadFiles } from "@/helpers/files";

@Component({ components: { AddressForm, TheMask, File } })
export default class Compliance extends Vue {
  @Prop() submit: (saveOnly: boolean) => void;
  @Prop() deleteFile: (file: any) => void;

  onboarding = onboarding;
  orgChartDocuments = [];
  programDocuments = [];
  independentDocuments = [];
  managementDocuments = [];
  officerDocuments = [];
  riskDocuments = [];
  bcpDocuments = [];
  drDocuments = [];
  bcpDrDocuments = [];

  async uploadOrgChart(e) {
    this.orgChartDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.orgChartDocuments,
      onboarding.complianceDetails.orgChart.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceOrgChart,
      this.submit,
    );
  }

  async uploadProgramDocs(e) {
    this.programDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.programDocuments,
      onboarding.complianceDetails.programDocs.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceProgramDocs,
      this.submit,
    );
  }

  async uploadIndependentReviews(e) {
    this.independentDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.independentDocuments,
      onboarding.complianceDetails.independentReviews.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceIndependentReviews,
      this.submit,
    );
  }

  async uploadManagementResponseToReviews(e) {
    this.managementDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.managementDocuments,
      onboarding.complianceDetails.managementResponseToReviews.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceManagementResponseToReviews,
      this.submit,
    );
  }

  async uploadOfficerResumes(e) {
    this.officerDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.officerDocuments,
      onboarding.complianceDetails.officerResumes.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceOfficerResumes,
      this.submit,
    );
  }

  async uploadRiskAssessment(e) {
    this.riskDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.riskDocuments,
      onboarding.complianceDetails.riskAssessment.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceRiskAssessment,
      this.submit,
    );
  }

  async uploadBcp(e) {
    this.bcpDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.bcpDocuments,
      onboarding.complianceDetails.bcp.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceBcp,
      this.submit,
    );
  }

  async uploadDr(e) {
    this.drDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.drDocuments,
      onboarding.complianceDetails.dr.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceDr,
      this.submit,
    );
  }

  async uploadBcpDrSchedulesAndResults(e) {
    this.bcpDrDocuments = Array.from(e.target.files);
    await uploadFiles(
      this.bcpDrDocuments,
      onboarding.complianceDetails.bcpDrSchedulesAndResults.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateComplianceBcpDrSchedulesAndResults,
      this.submit,
    );
  }
}

import axios from "axios";
import { notifyUser, isTypePaymentAPIError } from "@/helpers/template";
import { createFileUploadUrl } from "@/store/apis/users";

export async function getFileUrl(file: { size: number, name: string, box?: object }): Promise<any> {
  const { size, name } = file;
  Object.defineProperty(file, "name", {
    writable: true,
    value: `${parseInt(Date.now().toString(), 10).toString(16)}-${name}`,
  });
  const response = await createFileUploadUrl({ size, name });
  if (isTypePaymentAPIError(response)) {
    notifyUser("Error", `Failed to get Upload URL for ${name.slice(name.indexOf("-") + 1)}`, "error");
    return false;
  } else {
    file.box = response;
    return file;
  }
}

export async function uploadFile(file: any): Promise<any> {
  const { name } = file;
  const formData = new FormData();
  const url = file.box.upload_url;
  const token = file.box.accessToken;
  const folderId = file.box.folder_id;
  delete file.box;
  formData.append("file", file);
  formData.append("attributes", JSON.stringify({ name, parent: { id: folderId } }));
  const response = await axios.post(url, formData, {
    headers: { authorization: `Bearer ${token}` },
  });
  if (isTypePaymentAPIError(response)) {
    notifyUser("Error", `Failed Upload ${name.slice(name.indexOf("-") + 1)}`, "error");
    return false;
  } else {
    return response.data.entries[0];
  }
}

export async function uploadFiles(
  files: Array<{ size: number, name: string, box?: object }>,
  checklistItemId: string,
  updateFiles: (payload: any) => void,
  updateIds: (payload: string) => void,
  submit: (saveOnly: boolean) => void,
) {
  for (const file of files) {
    const fileWithUrl = await getFileUrl(file);
    const uploadedFile = await uploadFile(fileWithUrl);
    if (uploadedFile) {
      uploadedFile.checklist_item_id = checklistItemId;
      updateIds(uploadedFile.id);
      updateFiles(uploadedFile);
      submit(true);
    }
    delete files[files.indexOf(file)];
  }
}





































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import AddressForm from "@/components/AddressForm.vue";

@Component({
  components: { AddressForm },
})
export default class Owner extends Vue {
  @Prop() param: object;
  @Prop() error: object;
  @Prop() changeState: void;
  @Prop() changeCountry: void;
  @Prop() index: number;
}

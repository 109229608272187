<template >
    <b-card no-body class="mb-3 accordionsCard">
            <b-card-header header-tag="header" class="p-1 ridge" role="tab">
                <b-button block v-b-toggle.accordion-4 class="accordions text-left">Quote</b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="input-group mb-3">
                        <input :value="title"  @change="onInputChange" id="quote_id" type="text" class="form-control" placeholder="ID" />
                    </div>
                    <label for="quote_expiration">Quote expiration date</label>
                    <div class="input-group mb-3">
                       
                        <input :value="title"  @change="onInputChange" id="quote_expiration" type="date" class="form-control"
                            placeholder="Expiration date mm-dd-yyyy hh:mm:ss" />
                    </div>
                    <label for="quote_expiration">Quote created date</label>
                    <div class="input-group mb-3">
                       
                        <input :value="title"  @change="onInputChange" id="quote_instant" type="date" class="form-control"
                            placeholder="Created date mm-dd-yyyy hh:mm:ss" />
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
</template>
    
    
<script>
import { Vue, Component, Props } from "vue-property-decorator";
export default {
    name: "QuotesFilter",
    props: {
        title: String,
    },
    data() {
        return {
            initialArray: [{
                quote_id: "",
                quote_expiration_date: "",
                quote_created_date: "",
            }],
        };
    },
    methods: {
        onInputChange(event) {
            this.initialArray[0][event["target"]["id"]] = event["target"]["value"];
            this.$emit("data", this.initialArray);
        },
    },
};
</script>
export function togglePasswordField(type) {
  const data: any = {
    type: "password",
    name: "eye-off",
  };
  if (type === "password") {
    data.type = "text";
    data.name = "eye";
  }
  return data;
}

export function setPasswordIcon(name) {
  return "images/feather-sprite.svg#" + name;
}

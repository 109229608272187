














import { Vue, Component, Prop } from "vue-property-decorator";
import sendMoney from "@/store/modules/sendMoney";

@Component({
  beforeRouteEnter: Result.preRoute,
  beforeRouteUpdate: Result.preRoute,
})
export default class Result extends Vue {
  static async preRoute(to, from, next) {
    if (from.name === "send-review") {
      next();
    } else {
      next({ name: "send" });
    }
  }

  @Prop() next: () => void;

  sendMoney = sendMoney;
}

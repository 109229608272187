<template >
    <b-card no-body class="mb-3 accordionsCard">
        <b-card-header header-tag="header" class="p-1 ridge" role="tab">
            <b-button block v-b-toggle.accordion-2 class="accordions text-left">Sender</b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <div class="input-group mb-3">
                    <input :value="title"  @change="onInputChange" id="sender_amount" type="text" class="form-control"
                        placeholder="Amount" />
                </div>

                <div class="input-group mb-3">
                    <input :value="title"  @change="onInputChange" id="sender_given_name" type="text" class="form-control" placeholder="Name" />
                </div>

                <div class="input-group mb-3">
                    <input :value="title"  @change="onInputChange" id="sender_address" type="text" class="form-control" placeholder="Address" />
                </div>

                <div class="input-group mb-3">
                    <input :value="title"  @change="onInputChange" id="sender_email" type="text" class="form-control" placeholder="Email" />
                </div>

                <div class="input-group mb-3">
                    <input :value="title"  @change="onInputChange" id="sender_phone" type="text" class="form-control" placeholder="Number" />
                </div>

                <div class="input-group mb-3">
                    <input :value="title"  @change="onInputChange" id="sender_id" type="text" class="form-control" placeholder="ID" />
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>
    
    
<script>
import { Vue, Component, Props } from "vue-property-decorator";
export default {
    name: "SenderFilter",
    props: {
        title: String,
    },
    data() {
        return {
            initialArray: [{
                sender_amount: "",
                sender_given_name: "",
                sender_address: "",
                sender_email: "",
                sender_id: "",
                sender_number: "",
            }],
        };
    },
    methods: {
        onInputChange(event) {
            this.initialArray[0][event["target"]["id"]] = event["target"]["value"];
            this.$emit("data", this.initialArray);
        },
    },
};
</script>























import { Vue, Component, Prop } from "vue-property-decorator";
import { footerFixedRoutes } from "@/store/constants";
import axios from "axios";
import metaModule from "@/store/modules/meta";
import { isTypeMeta, isTypePaymentAPIError } from "@/helpers/template";

@Component
export default class AppFooter extends Vue {
  fixedFooterPaths = footerFixedRoutes;
  versionText = "";

  async getVersionText() {
    let frontendVersion = process.env.NODE_ENV;
    let apiVersion = "";

    try {
      const response = await axios.get("/meta");
      frontendVersion = response.data.version;
    } catch (error) {

    }
    try {
      await metaModule.getMeta();
      const response = metaModule.response;
      if (isTypeMeta(response)) {
        apiVersion = response.version;
      } else if (isTypePaymentAPIError(response)) {

      }
    } catch (error) {

    }

    return `${frontendVersion}, API${apiVersion}`;
  }
  async created() {
    this.versionText = await this.getVersionText();
  }
}

<template>
  <public-layout>
    <template slot="right-content">
      <form v-on:submit.prevent="onSubmit">
        <h2 class="display-4 mb-10 font-44 pt-20">Reset password</h2>
        <p class="mb-30">Enter your user profile email and new password</p>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            :class="resetPasswordErrors.email && resetPasswordErrors.email.length ? 'invalid-input' : ''"
            placeholder="Email"
            name="email"
            v-model="resetPassword.email"
            required
          />
          <div
            class="error"
            v-for="e in resetPasswordErrors.email"
            :key="e"
          >{{e.substring(e.indexOf(' '))}}</div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            :class="resetPasswordErrors.password && resetPasswordErrors.password.length ? 'invalid-input' : ''"
            placeholder="Password"
            name="password"
            v-model="resetPassword.password"
            required
          />
          <div
            class="error"
            v-for="e in resetPasswordErrors.password"
            :key="e"
          >{{e.substring(e.indexOf(' '))}}</div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input
              type="password"
              class="form-control"
              :class="resetPasswordErrors.confirm_password && resetPasswordErrors.confirm_password.length ? 'invalid-input' : ''"
              placeholder="Confirm Password"
              name="confirm_password"
              v-model="resetPassword.confirm_password"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <span class="feather-icon">
                  <svg class="feather">
                    <use xlink:href="images/feather-sprite.svg#eye-off" />
                  </svg>
                </span>
              </span>
            </div>
          </div>
          <div
            class="error"
            v-for="e in resetPasswordErrors.confirm_password"
            :key="e"
          >{{e.substring(e.indexOf(' '))}}</div>
        </div>
        <button class="btn btn-green btn-block" type="submit">Reset Password</button>
      </form>
    </template>
  </public-layout>
</template>

<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import users from "@/store/modules/users";
import { validateOrReject } from "class-validator";
import FormElement from "@/components/FormElement.vue";
import PublicLayout from "@/components/PublicLayout.vue";
import { ResetPassword } from "@/store/models/users";
import {
  isTypePaymentAPIError,
  notifyUser,
  errorsToAssociative,
} from "../helpers/template";

@Component({ components: { FormElement, PublicLayout } })
export default class ResetPasswordPost extends Vue {
  resetPassword = new ResetPassword();
  resetPasswordErrors = {};

  @Watch("resetPassword.confirm_password")
  confirmPasswordCheck(value) {
    this.resetPasswordErrors["confirm_password"] = [];
    if (value !== this.resetPassword.password) {
      this.resetPasswordErrors["confirm_password"] = ["confirm_password does not match"];
    }
  }

  async onSubmit() {
    this.resetPassword.code = this.$router.history.current.params.code;

    try {
      await validateOrReject(this.resetPassword);
      this.resetPasswordErrors = {};
    } catch (errors) {
      notifyUser("", "Please check form data.", "error");
      this.resetPasswordErrors = errorsToAssociative(errors);
      return;
    }

    try {
      const result = await users.resetPassword(this.resetPassword);
      const response = users.resetPasswordResponse;
      if (isTypePaymentAPIError(response)) {
        notifyUser("Reset Password Error", response.message, "error");
        } else {
          notifyUser("Success", "Successfully reset your password", "success");
          this.$router.push("/login");
        }
    } catch (error) {
      notifyUser("Reset Password Error", error.message, "error");
    }
  }
}
</script>

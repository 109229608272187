import {
  VuexModule, Module, getModule, Mutation, Action,
} from "vuex-module-decorators";
import store from "@/store";
// import {
//   GeoCodeResponse,
// } from "../models/geocoding";
// import {
//   getGeocodes,
// } from "../apis/geocoding";

@Module({
  namespaced: true,
  name: "onboarding",
  store,
  dynamic: true,
})
class OnboardingModule extends VuexModule {

  // private static async getAddressComponentsForZipCode(zipCode: string) {
  //   const response: GeoCodeResponse | boolean = await getGeocodes(zipCode);
  //   if (typeof response === "boolean" || response.status !== "OK") {
  //     return false;
  //   }
  //
  //   return {
  //     city: response.results[0]["address_components"].filter((item) => item.types.indexOf("locality") !== -1)[0].short_name,
  //     state: response.results[0]["address_components"].filter((item) => item.types.indexOf("administrative_area_level_1") !== -1)[0].short_name,
  //     country: response.results[0]["address_components"].filter((item) => item.types.indexOf("country") !== -1)[0].short_name,
  //   };
  // }

  chooseProducts = {
    product: { checklistItemId: "", value: "" },
  };
  card = {
    type: { checklistItemId: "", value: "Select One" },
    number: { checklistItemId: "", value: "" },
  };
  bank = {
    accountNumber: { checklistItemId: "", value: "" },
  };
  representative = {
    firstName: { checklistItemId: "", value: "" },
    lastName: { checklistItemId: "", value: "" },
    email: { checklistItemId: "", value: "" },
    address: {
      checklistItemId: "",
      value: { state: "AL", country: "US", city: "" },
    },
    title: { checklistItemId: "", value: "" },
    phone: { checklistItemId: "", value: "" },
    birth: { checklistItemId: "", value: "" },
    ssn: { checklistItemId: "", value: "" },
  };
  businessDetails = {
    name: { checklistItemId: "", value: "" },
    address: {
      checklistItemId: "",
      value: { state: "AL", country: "US", city: "" },
    },
    description: { checklistItemId: "", value: "" },
    documentsIds: { checklistItemId: "", value: [] },
    phone: { checklistItemId: "", value: "" },
    website: { checklistItemId: "", value: "" },
    linkedIn: { checklistItemId: "", value: "" },
    facebook: { checklistItemId: "", value: "" },
    twitter: { checklistItemId: "", value: "" },
  };
  incorporationDetails = {
    ein: { checklistItemId: "", value: "" },
    dba: { checklistItemId: "", value: "" },
    state: { checklistItemId: "", value: "AL" },
    documentsIds: { checklistItemId: "", value: [] },
    businessPurpose: {
      checklistItemId: "",
      value: "11: Agriculture Forestry Fishing and Hunting",
    },
    states: { checklistItemId: "", value: ["None"] },
    countries: { checklistItemId: "", value: ["None"] },
  };
  complianceDetails = {
    orgChart: { checklistItemId: "", value: [] },
    programDocs: { checklistItemId: "", value: [] },
    adverseFindings: { checklistItemId: "", value: "" },
    independentReviews: { checklistItemId: "", value: [] },
    managementResponseToReviews: { checklistItemId: "", value: [] },
    officerResumes: { checklistItemId: "", value: [] },
    riskAssessment: { checklistItemId: "", value: [] },
    software: { checklistItemId: "", value: "" },
    bcp: { checklistItemId: "", value: [] },
    dr: { checklistItemId: "", value: [] },
    bcpDrSchedulesAndResults: { checklistItemId: "", value: [] },
  };
  businessDocuments = {
    owners: { checklistItemId: "", value: [] }, // OwnerCreate[]
    ownerLicenseIds: { checklistItemId: "", value: [] },
    fictitiousNameCertificateIds: { checklistItemId: "", value: [] },
    ownershipOrganizationalChartIds: { checklistItemId: "", value: [] },
    resumesIds: { checklistItemId: "", value: [] },
    licensesIds: { checklistItemId: "", value: [] },
  };

  files = [];

  @Mutation
  updateOnboarding(payload: any | undefined) {
    this.chooseProducts = payload.form_data.chooseProducts || this.chooseProducts;
    this.card = payload.form_data.card;
    this.card.type = payload.form_data.card.type || "Select One";
    this.bank = payload.form_data.bank || this.bank;

    type T = [string, { checklistItemId, value }][];
    for (const [field, data] of Object.entries(payload.form_data.representative) as T) {
      this.representative[field].value = data.value || this.representative[field].value;
    }
    for (const [field, data] of Object.entries(payload.form_data.businessDetails) as T) {
      this.businessDetails[field].value = data.value || this.businessDetails[field].value;
    }
    for (const [field, data] of Object.entries(payload.form_data.incorporationDetails) as T) {
      this.incorporationDetails[field].value = data.value || this.incorporationDetails[field].value;
    }
    for (const [field, data] of Object.entries(payload.form_data.businessDocuments) as T) {
      this.businessDocuments[field].value = data.value || this.businessDocuments[field].value;
    }
    for (const [field, data] of Object.entries(payload.form_data.complianceDetails) as T) {
      this.complianceDetails[field].value = data.value || this.complianceDetails[field].value;
    }

    this.files = payload.files;
  }

  @Mutation
  updateIncorporationDocumentsIds(payload: string | undefined) {
    this.incorporationDetails.documentsIds.value.push(payload);
  }

  @Mutation
  updateBusinessDocumentsIds(payload: string | undefined) {
    this.businessDetails.documentsIds.value.push(payload);
  }

  @Mutation
  updateComplianceOrgChart(payload: string | undefined) {
    this.complianceDetails.orgChart.value.push(payload);
  }

  @Mutation
  updateComplianceProgramDocs(payload: string | undefined) {
    this.complianceDetails.programDocs.value.push(payload);
  }

  @Mutation
  updateComplianceIndependentReviews(payload: string | undefined) {
    this.complianceDetails.independentReviews.value.push(payload);
  }

  @Mutation
  updateComplianceManagementResponseToReviews(payload: string | undefined) {
    this.complianceDetails.managementResponseToReviews.value.push(payload);
  }

  @Mutation
  updateComplianceOfficerResumes(payload: string | undefined) {
    this.complianceDetails.officerResumes.value.push(payload);
  }

  @Mutation
  updateComplianceRiskAssessment(payload: string | undefined) {
    this.complianceDetails.riskAssessment.value.push(payload);
  }

  @Mutation
  updateComplianceBcp(payload: string | undefined) {
    this.complianceDetails.bcp.value.push(payload);
  }

  @Mutation
  updateComplianceDr(payload: string | undefined) {
    this.complianceDetails.dr.value.push(payload);
  }

  @Mutation
  updateComplianceBcpDrSchedulesAndResults(payload: string | undefined) {
    this.complianceDetails.bcpDrSchedulesAndResults.value.push(payload);
  }

  @Mutation
  updateOwnerLicenseIds(payload: string | undefined) {
    this.businessDocuments.ownerLicenseIds.value.push(payload);
  }

  @Mutation
  updateFictitiousNameCertificateIds(payload: string | undefined) {
    this.businessDocuments.fictitiousNameCertificateIds.value.push(payload);
  }

  @Mutation
  updateOwnershipOrganizationalChartIds(payload: string | undefined) {
    this.businessDocuments.ownershipOrganizationalChartIds.value.push(payload);
  }

  @Mutation
  updateResumesIds(payload: string | undefined) {
    this.businessDocuments.resumesIds.value.push(payload);
  }

  @Mutation
  updateLicensesIds(payload: string | undefined) {
    this.businessDocuments.licensesIds.value.push(payload);
  }

  @Mutation
  deleteFile(payload: any | undefined) {
    const updatedFiles = [];
    for (const item of this.files) {
      if (item.name === payload.name) { item.status = "Delete"; }
      updatedFiles.push(item);
    }
    this.files = updatedFiles;
    this.businessDetails.documentsIds.value = this.businessDetails.documentsIds.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.orgChart.value = this.complianceDetails.orgChart.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.programDocs.value = this.complianceDetails.programDocs.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.independentReviews.value = this.complianceDetails.independentReviews.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.managementResponseToReviews.value = this.complianceDetails.managementResponseToReviews.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.officerResumes.value = this.complianceDetails.officerResumes.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.riskAssessment.value = this.complianceDetails.riskAssessment.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.bcp.value = this.complianceDetails.bcp.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.dr.value = this.complianceDetails.dr.value.filter((id) => id !== payload.fileId);
    this.complianceDetails.bcpDrSchedulesAndResults.value = this.complianceDetails.bcpDrSchedulesAndResults.value.filter((id) => id !== payload.fileId);
    this.businessDocuments.ownerLicenseIds.value = this.businessDocuments.ownerLicenseIds.value.filter((id) => id !== payload.fileId);
    this.businessDocuments.fictitiousNameCertificateIds.value = this.businessDocuments.fictitiousNameCertificateIds.value.filter((id) => id !== payload.fileId);
    this.businessDocuments.ownershipOrganizationalChartIds.value = this.businessDocuments.ownershipOrganizationalChartIds.value.filter((id) => id !== payload.fileId);
    this.businessDocuments.resumesIds.value = this.businessDocuments.resumesIds.value.filter((id) => id !== payload.fileId);
    this.businessDocuments.licensesIds.value = this.businessDocuments.licensesIds.value.filter((id) => id !== payload.fileId);
  }

  @Mutation
  updateFiles(payload: any | undefined) {
    const file = {
      name: payload.name,
      fileId: payload.id,
      checklistItemId: payload.checklist_item_id,
      size: payload.size,
      createdAt: payload.created_at,
      modifiedAt: payload.modified_at,
      status: payload.item_status,
    };
    this.files.push(file);
  }

  @Mutation
  updateRepresentativeContact(payload: any | undefined) {
    this.representative.firstName.value = payload.first_name;
    this.representative.lastName.value = payload.last_name;
    this.representative.email.value = payload.email;
  }

  @Mutation
  updateRepresentativeState(payload: string | undefined) {
    this.representative.address.value.state = payload;
  }

  @Mutation
  updateRepresentativeCountry(payload: string | undefined) {
    this.representative.address.value.country = payload;
  }

  // @Mutation
  // async updateRepresentativeFromZipCode(payload: string | undefined) {
  //   const components: { city: string, state: string, country: string } | boolean = await OnboardingModule.getAddressComponentsForZipCode(payload);
  //   if (components) {
  //     this.representative.address.value.city = components.city;
  //     this.representative.address.value.state = components.state;
  //     this.representative.address.value.country = components.country;
  //   }
  // }

  @Mutation
  updateBusinessDetailsState(payload: string | undefined) {
    this.businessDetails.address.value.state = payload;
  }

  @Mutation
  updateBusinessDetailsCountry(payload: string | undefined) {
    this.businessDetails.address.value.country = payload;
  }

  // @Mutation
  // async updateBusinessDetailsFromZipCode(payload: string | undefined) {
  //   const components: { city: string, state: string, country: string } | boolean = await OnboardingModule.getAddressComponentsForZipCode(payload);
  //   if (components) {
  //     this.businessDetails.address.value.city = components.city;
  //     this.businessDetails.address.value.state = components.state;
  //     this.businessDetails.address.value.country = components.country;
  //   }
  // }

  @Mutation
  addIncorporationState(payload: string) {
    this.incorporationDetails.states.value.push(payload);
  }

  @Mutation
  addIncorporationCountry(payload: string) {
    this.incorporationDetails.countries.value.push(payload);
  }

  @Mutation
  updateIncorporationStates(payload: any | undefined) {
    this.incorporationDetails.states.value[payload.index] = payload.value;
  }

  @Mutation
  updateIncorporationCountries(payload: any | undefined) {
    this.incorporationDetails.countries.value[payload.index] = payload.value;
  }

  @Mutation
  deleteIncorporationState(index: number) {
    this.incorporationDetails.states.value.splice(index, 1);
  }

  @Mutation
  deleteIncorporationCountry(index: number) {
    this.incorporationDetails.countries.value.splice(index, 1);
  }

  @Mutation
  updateIncorporationJurisdictions(payload: any | undefined) {
    this.incorporationDetails.countries.value = payload.countries;
    this.incorporationDetails.states.value = payload.states;
  }

  @Mutation
  updateBusinessDocumentsOwnerState(payload: any | undefined) {
    this.businessDocuments.owners.value[payload.index].address.state = payload.value;
  }

  @Mutation
  updateBusinessDocumentsOwnerCountry(payload: any | undefined) {
    this.businessDocuments.owners.value[payload.index].address.country = payload.value;
  }

  // @Mutation
  // async updateBusinessDocumentsOwnerFromZipCode(payload: any | undefined) {
  //   const components: { city: string, state: string, country: string } | boolean = await OnboardingModule.getAddressComponentsForZipCode(payload.value);
  //   if (components) {
  //     this.businessDocuments.owners.value[payload.index].address.value.city = components.city;
  //     this.businessDocuments.owners.value[payload.index].address.value.state = components.state;
  //     this.businessDocuments.owners.value[payload.index].address.value.country = components.country;
  //   }
  // }
}

export default getModule(OnboardingModule);

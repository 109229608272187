






















































































import { TheMask } from "vue-the-mask";
import { Vue, Component, Prop } from "vue-property-decorator";
import onboarding from "@/store/modules/onboarding";

@Component({ components: { TheMask }})
export default class Card extends Vue {
  @Prop() submit: (saveOnly: boolean) => void;

  onboarding = onboarding;
}

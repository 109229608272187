import {
  IsEmail,
  IsNotEmpty,
  IsPhoneNumber,
  Matches,
  ValidateNested,
  IsISO31661Alpha3,
  MinLength,
  Length,
  IsNumberString,
} from "class-validator";

export class UserCreate {
  @IsNotEmpty()
  first_name: string;

  @IsNotEmpty()
  last_name: string;

  @IsEmail()
  email: string;

  @IsPhoneNumber("US")
  phone: string;

  @MinLength(8)
  @Matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).*$/, {
    message: "The password must contain lowercase and uppercase letters, numbers, and special symbols.",
  })
  password: string;

  @IsNotEmpty()
  confirm_password: string;
}

export interface UserSuccess {
  id: string;
  partner_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role: string;
}

export interface User extends UserSuccess {
  tags?: undefined;
  verified_at: number;
}

export class UserVerify {
  @IsNotEmpty() code: string;
}

export class UserLogin {
  @IsEmail() email: string;
  @IsNotEmpty() password: string;
}

export class ResetPassword {
  @IsEmail()
  email: string;

  @IsNotEmpty()
  code: string;

  @MinLength(8)
  @Matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).*$/, {
    message: "The password must contain lowercase and uppercase letters, numbers, and special symbols.",
  })
  password: string;

  @IsNotEmpty()
  confirm_password: string;
}

export class ForgotPassword {
  @IsEmail() email: string;
}

export class ResendVerification {
  @IsEmail() email: string;
}

export class UserProfile {
  @IsNotEmpty() first_name: string;
  @IsNotEmpty() last_name: string;
  @IsEmail() email: string;
  @IsPhoneNumber("US") phone: string;
  constructor(params: {} = {}) {
    Object.assign(this, params);
  }
}

export class Address {
  @IsNotEmpty() street: Array<string>;
  @IsNotEmpty() city: string;
  @IsNotEmpty() state: string;
  @IsNotEmpty() postal: string;
  @IsISO31661Alpha3() country: string;
}

export class PartnerCreate {
  @Length(3, 30) name: string;
  @IsEmail() email: string;
  @IsPhoneNumber("US") phone: string;
  @ValidateNested() administrator: UserCreate = new UserCreate();
  terms: boolean;
  constructor(params: {} = {}) {
    Object.assign(this, params);
  }
}

export class Partner extends PartnerCreate {
  status_id?: number;
  @ValidateNested() address: Address = new Address();
}

export class BusinessDocuments {
  checklistItemId: string;
  owners: OwnerCreate[];
  ownerLicenseIds: [];
  fictitiousNameCertificateIds: [];
  ownershipOrganizationalChartIds: [];
  resumesIds: [];
  licensesIds: [];
  constructor(params: any = {}) {
    this.checklistItemId = params.checklistItemId;
    this.owners = [];
    this.ownerLicenseIds = [];
    this.fictitiousNameCertificateIds = [];
    this.ownershipOrganizationalChartIds = [];
    this.resumesIds = [];
    this.licensesIds = [];
    for ( const owner of params.owners) {
      this.owners.push(new OwnerCreate(owner));
    }
  }
}

export class OwnerCreate {
  @IsNumberString({
    message: "Stake must be a number",
  })
  stake: number;
  fullName: string;
  address: Address = new Address();
  birthDate: string;
  taxId: string;
  citizenship: string;
  constructor(params: {} = {}) {
    Object.assign(this, params);
  }
}

export interface PartnerSuccess {
  id: string;
  partner_token: string;
  email: string;
  name: string;
}

export interface PaymentAPIMessage {
  message: string;
}

export interface PaymentAPIError {
  name: string;
  message: string;
  stack: number;
  code: number;
  details?: Array<any>;
}

export enum OnboardingStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  ReadyToSubmit = "ReadyToSubmit",
  InReview = "InReview",
  Approved = "Approved",
  Rejected = "Rejected",
}

export class OnboardingField {
  id: string;
  name: string;
}

export class OnboardingSection {
  name: string;
  value: boolean;
  fields: OnboardingField[];
}

<template>
  <public-layout>
    <template slot="right-content">
      <form v-on:submit.prevent="onSubmit">
        <h2 class="display-4 mb-10 font-44 pt-20">Forgot your password?</h2>
        <p class="mb-30">Enter your user profile email to receive a password reset link</p>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            :class="forgotPasswordErrors.email && forgotPasswordErrors.email.length ? 'invalid-input' : ''"
            placeholder="Email"
            name="email"
            v-model="forgotPassword.email"
            required
          />
          <div class="error" v-for="e in forgotPasswordErrors.email" :key="e">{{e.substring(e.indexOf(' '))}}</div>
        </div>
        <button class="btn btn-green btn-block" type="submit">Submit</button>
        <br>
        <p class="text-center"><router-link to="/login">Back to login</router-link>.</p>
        <!-- <p class="text-center">Don't have an account? <router-link to="/register">Sign up</router-link></p> -->
      </form>
    </template>
  </public-layout>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { notifyUser, errorsToAssociative } from "@/helpers/template";
import users from "@/store/modules/users";
import { validateOrReject } from "class-validator";
import FormElement from "@/components/FormElement.vue";
import PublicLayout from "@/components/PublicLayout.vue";
import { ForgotPassword } from "@/store/models/users";
import {isTypePaymentAPIError, isTypePaymentAPIMessage} from "../helpers/template";

@Component({components: { FormElement, PublicLayout }})
export default class ForgotPasswordPost extends Vue {
  forgotPassword = new ForgotPassword();
  forgotPasswordErrors = {};

  async onSubmit() {
    try {
      await validateOrReject(this.forgotPassword);
      this.forgotPasswordErrors = {};
    } catch (errors) {
      this.forgotPasswordErrors = errorsToAssociative(errors);
      notifyUser("", "Please check form data", "error");
      return;
    }

    try {
      await users.forgotPassword(this.forgotPassword);
      const response = users.forgotPasswordResponse;
      if (isTypePaymentAPIMessage(response)) {
        notifyUser("Reset Code Sent", response.message, "success");
        this.$router.push("/reset_instruction");
      } else if (isTypePaymentAPIError(response)) {
        notifyUser("Reset Code Failed", response.message, "error");
      }
    } catch (error) {
      notifyUser("Reset Code Error", "Unknown error. Please try again.", "error");
    }
  }
}
</script>
import {
  VuexModule,
  Module,
  getModule,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import {
  UserCreate,
  UserLogin,
  User,
  UserSuccess,
  ForgotPassword,
  ResendVerification,
  UserProfile,
  PartnerSuccess,
  PaymentAPIError,
  ResetPassword, PartnerCreate, PaymentAPIMessage, OnboardingSection,
} from "../models/users";
import {
  loginUser,
  getUser,
  getUsers,
  setJWT,
  createUser,
  verifyUser,
  clearJWT,
  resetPassword,
  sendResetCode,
  updateUser,
  createPartner,
  resendVerificationCode,
  createOnboarding,
  updateOnboarding,
  getOnboardingUI,
  getOnboardingData,
} from "../apis/users";
import {getChecklistItemIdByName, isTypeUserSuccess} from "@/helpers/template";
import OnboardingModule from "@/store/modules/onboarding";
import _ from "lodash";

@Module({
  namespaced: true,
  name: "users",
  store,
  dynamic: true,
})
class UsersModule extends VuexModule {

  private static setRepresentativeFieldChecklistIds(onboardingUIResponse) {
    const section = _.find(onboardingUIResponse, ["name", "representative"]);
    OnboardingModule.representative.firstName.checklistItemId = getChecklistItemIdByName(section, "representative-first-name");
    OnboardingModule.representative.lastName.checklistItemId = getChecklistItemIdByName(section, "representative-last-name");
    OnboardingModule.representative.email.checklistItemId = getChecklistItemIdByName(section, "representative-email");
    OnboardingModule.representative.address.checklistItemId = getChecklistItemIdByName(section, "representative-address");
    OnboardingModule.representative.title.checklistItemId = getChecklistItemIdByName(section, "representative-job-title");
    OnboardingModule.representative.phone.checklistItemId = getChecklistItemIdByName(section, "representative-phone");
    OnboardingModule.representative.birth.checklistItemId = getChecklistItemIdByName(section, "representative-dob");
    OnboardingModule.representative.ssn.checklistItemId = getChecklistItemIdByName(section, "representative-ssn");
  }

  private static setBusinessFieldChecklistIds(onboardingUIResponse) {
    const section = _.find(onboardingUIResponse, ["name", "business-details"]);
    OnboardingModule.businessDetails.name.checklistItemId = getChecklistItemIdByName(section, "business-name");
    OnboardingModule.businessDetails.description.checklistItemId = getChecklistItemIdByName(section, "business-description");
    OnboardingModule.businessDetails.documentsIds.checklistItemId = getChecklistItemIdByName(section, "business-flow-of-funds");
    OnboardingModule.businessDetails.address.checklistItemId = getChecklistItemIdByName(section, "business-address");
    OnboardingModule.businessDetails.phone.checklistItemId = getChecklistItemIdByName(section, "business-phone");
    OnboardingModule.businessDetails.website.checklistItemId = getChecklistItemIdByName(section, "business-website");
    OnboardingModule.businessDetails.linkedIn.checklistItemId = getChecklistItemIdByName(section, "business-linkedin");
    OnboardingModule.businessDetails.facebook.checklistItemId = getChecklistItemIdByName(section, "business-facebook");
    OnboardingModule.businessDetails.twitter.checklistItemId = getChecklistItemIdByName(section, "business-twitter");
  }

  private static setComplianceFieldChecklistIds(onboardingUIResponse) {
    const section = _.find(onboardingUIResponse, ["name", "compliance-details"]);
    OnboardingModule.complianceDetails.orgChart.checklistItemId = getChecklistItemIdByName(section, "compliance-org-chart");
    OnboardingModule.complianceDetails.programDocs.checklistItemId = getChecklistItemIdByName(section, "compliance-program-docs");
    OnboardingModule.complianceDetails.adverseFindings.checklistItemId = getChecklistItemIdByName(section, "compliance-adverse-findings");
    OnboardingModule.complianceDetails.independentReviews.checklistItemId = getChecklistItemIdByName(section, "compliance-independent-reviews");
    OnboardingModule.complianceDetails.managementResponseToReviews.checklistItemId = getChecklistItemIdByName(section, "compliance-management-response-to-reviews");
    OnboardingModule.complianceDetails.officerResumes.checklistItemId = getChecklistItemIdByName(section, "compliance-officer-resumes");
    OnboardingModule.complianceDetails.riskAssessment.checklistItemId = getChecklistItemIdByName(section, "compliance-risk-assessment");
    OnboardingModule.complianceDetails.software.checklistItemId = getChecklistItemIdByName(section, "compliance-software");
    OnboardingModule.complianceDetails.bcp.checklistItemId = getChecklistItemIdByName(section, "compliance-bcp");
    OnboardingModule.complianceDetails.dr.checklistItemId = getChecklistItemIdByName(section, "compliance-dr");
    OnboardingModule.complianceDetails.bcpDrSchedulesAndResults.checklistItemId = getChecklistItemIdByName(section, "compliance-bcp-dr-schedules-and-results");
  }

  private static setIncorporationFieldChecklistIds(onboardingUIResponse) {
    const section = _.find(onboardingUIResponse, ["name", "incorporation-details"]);
    OnboardingModule.incorporationDetails.ein.checklistItemId = getChecklistItemIdByName(section, "incorporation-ein");
    OnboardingModule.incorporationDetails.dba.checklistItemId = getChecklistItemIdByName(section, "incorporation-dba");
    OnboardingModule.incorporationDetails.state.checklistItemId = getChecklistItemIdByName(section, "incorporation-state");
    OnboardingModule.incorporationDetails.documentsIds.checklistItemId = getChecklistItemIdByName(section, "incorporation-docs");
    OnboardingModule.incorporationDetails.businessPurpose.checklistItemId = getChecklistItemIdByName(section, "incorporation-purpose");
    OnboardingModule.incorporationDetails.states.checklistItemId = getChecklistItemIdByName(section, "incorporation-service-states");
    OnboardingModule.incorporationDetails.countries.checklistItemId = getChecklistItemIdByName(section, "incorporation-service-countries");
  }

  private static setDocumentsFieldChecklistIds(onboardingUIResponse) {
    const section = _.find(onboardingUIResponse, ["name", "business-documents"]);
    OnboardingModule.businessDocuments.owners.checklistItemId = getChecklistItemIdByName(section, "docs-owners");
    OnboardingModule.businessDocuments.ownerLicenseIds.checklistItemId = getChecklistItemIdByName(section, "docs-owners-licenses");
    OnboardingModule.businessDocuments.fictitiousNameCertificateIds.checklistItemId = getChecklistItemIdByName(section, "docs-fictitious-name");
    OnboardingModule.businessDocuments.ownershipOrganizationalChartIds.checklistItemId = getChecklistItemIdByName(section, "docs-org-chart");
    OnboardingModule.businessDocuments.resumesIds.checklistItemId = getChecklistItemIdByName(section, "docs-resumes");
    OnboardingModule.businessDocuments.licensesIds.checklistItemId = getChecklistItemIdByName(section, "docs-licenses");
  }
  user: User = undefined;
  users: User[] = [];
  accessToken: string = "";
  forgotPasswordResponse: object = {};
  resendVerificationResponse: object = {};
  loginResponse: object = {};
  registerResponse: object = {};
  onboardingUIResponse: OnboardingSection[] | PaymentAPIError = [];
  onboardingDataResponse: { [key: string]: any } = {};
  onboardingResponse: object = {};
  verifyResponse: object = {};
  getUsersResponse: User[] | PaymentAPIError = [];
  resetPasswordResponse: object = {};
  updateProfileResponse: object = {};

  get username() {
    if (this.user) {
      return this.user.first_name + " " + this.user.last_name;
    }
    return "";
  }

  get isLoggedIn() {
    return this.accessToken ? true : false;
  }

  get useremail() {
    if (this.user) {
      return this.user.email;
    }
    return "";
  }

  get profile() {
    return this.user
      ? this.user
      : {
        id: "",
        partner_id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        tags: undefined,
      };
  }

  @MutationAction({ mutate: ["user", "accessToken", "loginResponse"] })
  async login(userlogin: UserLogin) {
    try {
      const response: any = await loginUser(userlogin);
      if (response.token) {
        setJWT(response.token, userlogin.email);
        const user: User = await getUser(response.partner_id, userlogin.email);
        return { user, accessToken: response.token, loginResponse: response };
      }
    } catch (e) {
      return { user: undefined, accessToken: undefined, loginResponse: e.response };
    }
  }

  @MutationAction({ mutate: ["registerResponse"] })
  async registerUser(user: UserCreate) {
    const registerResponse: UserSuccess | PaymentAPIError = await createUser(localStorage.getItem("partner_id"), user);
    return { registerResponse };
  }

  @MutationAction({ mutate: ["verifyResponse"] })
  async verifyUser(code: string) {
    const verifyResponse: PaymentAPIMessage | PaymentAPIError = await verifyUser(code);
    return { verifyResponse };
  }

  @MutationAction({ mutate: ["resendVerificationResponse"] })
  async resendVerificationCode(requestBody: ResendVerification) {
    const resendVerificationResponse: PaymentAPIMessage | PaymentAPIError = await resendVerificationCode(requestBody);
    return { resendVerificationResponse };
  }

  @MutationAction({ mutate: ["users", "getUsersResponse"] })
  async getUsers() {
    const getUsersResponse: User[] | PaymentAPIError = await getUsers(localStorage.getItem("partner_id"));
    let users;
    if (Array.isArray(getUsersResponse)) {
      users = getUsersResponse;
    }
    return { users, getUsersResponse };
  }

  @MutationAction({ mutate: ["user", "accessToken"] })
  async logout() {
    clearJWT();
    return { user: undefined, accessToken: undefined };
  }

  @MutationAction({ mutate: ["user", "accessToken"] })
  async forceLogin() {
    try {
      const user: User = await getUser(localStorage.getItem("partner_id"), localStorage.getItem("user-email"));
      return { user, accessToken: localStorage.getItem("user-token") };
    } catch {
      clearJWT();
      return { user: undefined, accessToken: undefined };
    }
  }

  @MutationAction({ mutate: ["forgotPasswordResponse"] })
  async forgotPassword(requestBody: ForgotPassword) {
    const forgotPasswordResponse: PaymentAPIMessage | PaymentAPIError = await sendResetCode(requestBody);
    return { forgotPasswordResponse };
  }

  @MutationAction({ mutate: ["resetPasswordResponse"] })
  async resetPassword(requestBody: ResetPassword) {
    const resetPasswordResponse: User | PaymentAPIError = await resetPassword(requestBody);
    return { resetPasswordResponse };
  }

  @MutationAction({ mutate: ["user", "updateProfileResponse"] })
  async updateProfile(updateProfile: UserProfile) {
    const updateProfileResponse: User | PaymentAPIError = await updateUser(updateProfile);
    let user: User;
    if (isTypeUserSuccess(updateProfileResponse)) {
      user = updateProfileResponse;
    }
    return { user, updateProfileResponse };
  }

  @MutationAction({ mutate: ["registerResponse"] })
  async registerPartner(partner: PartnerCreate) {
    const registerResponse: PartnerSuccess | PaymentAPIError = await createPartner(partner);
    return { registerResponse };
  }

  @MutationAction({ mutate: ["onboardingResponse"] })
  async createOnboarding(onboarding: { [key: string]: any }) {
    const onboardingResponse: boolean | PaymentAPIError = await createOnboarding(onboarding);
    return { onboardingResponse };
  }

  @MutationAction({ mutate: ["onboardingResponse"] })
  async updateOnboarding(onboarding: { [key: string]: any }) {
    const onboardingResponse: boolean | PaymentAPIError = await updateOnboarding(onboarding);
    return { onboardingResponse };
  }

  @MutationAction({ mutate: ["onboardingUIResponse"] })
  async getOnboardingUI() {
    const onboardingUIResponse: OnboardingSection[] | PaymentAPIError = await getOnboardingUI();
    if (Array.isArray(onboardingUIResponse)) {
      UsersModule.setRepresentativeFieldChecklistIds(onboardingUIResponse);
      UsersModule.setBusinessFieldChecklistIds(onboardingUIResponse);
      UsersModule.setIncorporationFieldChecklistIds(onboardingUIResponse);
      UsersModule.setDocumentsFieldChecklistIds(onboardingUIResponse);
      UsersModule.setComplianceFieldChecklistIds(onboardingUIResponse);
    }
    return { onboardingUIResponse };
  }

  @MutationAction({ mutate: ["onboardingDataResponse"] })
  async getOnboardingData() {
    const onboardingDataResponse: { [key: string]: any } | PaymentAPIError = await getOnboardingData();
    return { onboardingDataResponse };
  }
}

export default getModule(UsersModule);

<template>
  <public-layout>
    <template slot="right-content">
      <form v-on:submit.prevent="verifyCode">
        <h2 class="display-4 mb-10 font-44 pt-20">Please check your email</h2>
        <p
          class="mb-30"
        >We've sent you a verification code. When you receive it, please enter the code below.</p>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            :class="formErrors.code && formErrors.code.length ? 'invalid-input' : ''"
            placeholder="Enter verification code"
            name="code"
            v-model="formData.code"
            required
          />
          <div class="error" v-for="e in formErrors.code" :key="e">{{e.substring(e.indexOf(' '))}}</div>
        </div>
        <button class="btn btn-green btn-block" type="submit">Submit</button>
        <br />
        <p class="text-center">
          Didn't receive your code?
          <router-link to="/resend_verification">Send a new one</router-link>
        </p>
      </form>
    </template>
  </public-layout>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import users from "@/store/modules/users";
import {
  errorsToAssociative,
  isTypePaymentAPIError,
  isTypePaymentAPIMessage,
  notifyUser,
} from "../helpers/template";
import { validateOrReject } from "class-validator";
import { UserVerify } from "@/store/models/users";
import PublicLayout from "@/components/PublicLayout.vue";

@Component({ components: { PublicLayout } })
export default class EmailVerify extends Vue {
  formData = new UserVerify();
  formErrors = {};

  async mounted() {
    const { code } = this.$router.currentRoute.query;
    if (code) {
      this.formData.code = code;
      this.submit();
    }
  }

  async verifyCode() {
    try {
      await validateOrReject(this.formData);
      this.formErrors = {};
    } catch (errors) {
      notifyUser("Verification Failed", "Please enter a valid code.", "error");
      this.formErrors = errorsToAssociative(errors);
      return;
    }
    this.submit();
  }

  async submit() {
    try {
      await users.verifyUser(this.formData.code);
      const response = users.verifyResponse;
      if (isTypePaymentAPIMessage(response)) {
        notifyUser(
          "Verification Successful",
          "Continue with login.",
          "success",
        );
        this.$router.push("/login");
      } else if (isTypePaymentAPIError(response)) {
        notifyUser("Verification Failed", response.message, "error");
      }
    } catch (error) {
      notifyUser(
        "Verification Error",
        "Unknown error. Please try again.",
        "error",
      );
    }
  }
}
</script>

import {
  VuexModule, Module, getModule, Mutation,
} from "vuex-module-decorators";
import store from "@/store";

@Module({
  namespaced: true,
  name: "sendMoney",
  store,
  dynamic: true,
})
class SendMoneyModule extends VuexModule {
  senderAmount: number;
  senderFirstName: string;
  senderLastName: string;
  senderEmail: string;
  senderPhone: string;
  senderCountry = "United States";
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  beneficiaryEmail: string;
  beneficiaryPhone: string;
  beneficiaryCountry = "United States";
  quoteId = "";

  @Mutation
  updateSenderCountry(payload: string | undefined) {
    this.senderCountry = payload;
  }

  @Mutation
  updateQuoteId(payload: string | undefined) {
    this.quoteId = payload;
  }

  @Mutation
  updateBeneficiaryCountry(payload: string | undefined) {
    this.beneficiaryCountry = payload;
  }
}

export default getModule(SendMoneyModule);


<template>
  <div id="chart">
    <apexchart type="line" height="435" width="100%" :options="chartOptions" :series="series" />
  </div>
</template>


<script>
export default {
  name: "DailyAnalysis",
  props: ["graphData"],
  data: () => ({
    series: [{
      name: "Sender Amount",
      /* Initializing data with amount = 0 for each day of the week*/
      data: [0, 0, 0, 0, 0, 0, 0],
    }],
    categories: ["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday"],
    chartOptions: {
      chart: {
        id: "chart",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
        responsive: true,
      },
      colors: ["#00CD98"],
      stroke: {
        width: 4,
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
        },
        y: {
          formatter: undefined,
        },
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: ["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday"],
      },
    },

  }),
  mounted() {
    this.series = [{
      data: this.$props.graphData.series,
    }];
    this.categories = [{
      data: this.$props.graphData.xAxis,
    }];
  },

};
</script>
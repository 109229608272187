import { paymentApi } from "./endpoint";
import axios from "axios";
import { MailConfig, SMSConfig, PaymentAPIError, Partner, PartnerBase, PartnerUpdate, DiscoveryQuery, AcceptanceQuery, OptionsResponse, QuoteResponse, AcceptanceResponse, DeliveryQuery } from "../models/partnerSettings";
import partner from "@/store/modules/partnerSettings";

export async function getPartner(partnerID: string): Promise<Partner | PaymentAPIError> {
  return (await paymentApi()).get(`/partner/${partnerID}`)
    .then((response) => response.data as Partner)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function discoveryList(token: string, query: DiscoveryQuery): Promise<OptionsResponse | PaymentAPIError> {
  return axios.post(`/discovery/options`, query, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data as OptionsResponse)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function discoveryQuote(token: string, query: DiscoveryQuery): Promise< QuoteResponse | PaymentAPIError> {
  /* const token = query.token; */

  return axios.post(`/quotes`, query, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data as QuoteResponse)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function deliveryList(token: string, query: DeliveryQuery): Promise< [] | PaymentAPIError> {
  /* const token = query.token; */

  return axios.get(`/discovery/delivery-types/${query.country}/${query.currency}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data as [])
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function quoteAcceptance(token: string, query: AcceptanceQuery): Promise< AcceptanceResponse | PaymentAPIError> {
  /* const token = query.token; */
  const data = {
    sender: query.sender,
    beneficiary: query.beneficiary,
    cuit: query.cuit,
    cbu: query.cbu,
    account_type: query.account_type,
  };
  return axios.post(`/quotes/${query.quote}/acceptances`, data, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data as AcceptanceResponse)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function updatePartner(partnerID: string, newValues: PartnerBase): Promise<PartnerUpdate | PaymentAPIError> {
  return (await paymentApi()).post(`/partner/${partnerID}`, newValues)
    .then((response) => response.data as PartnerUpdate)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function regenerateToken(partnerID: string): Promise<Partner | PaymentAPIError> {
  return (await paymentApi()).post(`/partner/${partnerID}/regenerate_token`)
    .then((response) => response.data as Partner)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function createMailConfig(emailAccount: MailConfig): Promise<MailConfig> {
  const response = await (await paymentApi()).post(`/partner/${localStorage.getItem("partner_id")}/MailConfig`, emailAccount);
  return response.data as MailConfig;
}
export async function createSMSConfig(smsAccount: SMSConfig): Promise<SMSConfig> {
  const response = await (await paymentApi()).post(`/partner/${localStorage.getItem("partner_id")}/SMSConfig`, smsAccount);
  return response.data as SMSConfig;
}
export async function readMailConfig(): Promise<MailConfig> {
  const response = await (await paymentApi()).get(`/partner/${localStorage.getItem("partner_id")}/MailConfig`);
  return response.data[0] as MailConfig;
}
export async function readSMSConfig(): Promise<SMSConfig> {
  const response = await (await paymentApi()).get(`/partner/${localStorage.getItem("partner_id")}/SMSConfig`);
  return response.data[0] as SMSConfig;
}
export async function deleteMailConfig(): Promise<boolean> {
  const response = await (await paymentApi()).delete(`/partner/${localStorage.getItem("partner_id")}/MailConfig`);
  return true;
}
export async function deleteSMSConfig(): Promise<boolean> {
  const response = await (await paymentApi()).delete(`/partner/${localStorage.getItem("partner_id")}/SMSConfig`);
  return true;
}

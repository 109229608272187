<template >
    <div class="row">
        <div class="col-md-6 col-sm-12 col-lg-6">
            <label class="amounts_label" for="first_name">First Name</label>
            <div class="input-group mb-3">
                <input @change="onInputChange" v-model="vendor.first_name" id="first_name" type="text"
                    class="form-control forms" placeholder="Enter first name" />
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6">
            <label class="amounts_label" for="last_name">Last Name</label>
            <div class="input-group mb-3">
                <input @change="onInputChange" v-model="vendorData.last_name" id="last_name" type="text"
                    class="form-control forms" placeholder="Enter last name" />
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6">
            <label class="amounts_label" for="email">Email</label>
            <div class="input-group mb-3">
                <input @change="onInputChange" v-model="vendorData.email" id="email" type="text" class="form-control forms"
                    placeholder="Enter email address" />
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6">
            <label class="amounts_label" for="phone">Phone</label>
            <div class="input-group mb-3">
                <input @change="onInputChange" v-model="vendorData.phone" id="phone" type="text" class="form-control forms"
                    placeholder="Enter phone number" />
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-12">
            <label class="amounts_label" for="street">Street</label>
            <div class="input-group mb-3">
                <input @change="onInputChange" v-model="vendorData.address.street[0]" id="street" type="textarea"
                    class="form-control forms" placeholder="Enter address" />
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6">
            <label class="amounts_label" for="country">Country</label>
            <div class="input-group mb-3 ">
                <select-country class="forms" v-if="country_list.length > 0" :param="vendorData.address.country"
                    :onChange="onChangeCountry" :country_list="country_list" />
            </div>
        </div>

        <div class="col-md-6 col-sm-12 col-lg-6">
            <label class="amounts_label" for="state">State</label>
            <div class="input-group mb-3 ">
                <select-state class="forms" :param="vendorData.address.state" :onChange="onChangeState"
                    :state_list="state_list" />
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6">
            <label class="amounts_label" for="state">City</label>
            <div class="form-group">
                <select @change="onChangeCity" class="form-control" id="city" v-model="vendorData.address.city" required>
                    <option v-for="(city, i) of city_list" :key="i">
                        {{ city.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6">
            <label class="amounts_label" for="postal">Postal Code</label>
            <div class="input-group mb-3">
                <input @change="onInputChange" v-model="vendorData.address.postal" id="postal" type="text"
                    class="form-control forms" placeholder="Enter your postal code" />
            </div>
        </div>
        <div class="col-12 justify-content-between d-flex">
            <button type="button" class="btn btnCancel mr-5" style="float:left;" @click="cancelAction()">Cancel</button>
            <button type="button" class="btn btn-green reg-btnCredentials  ml-5 mr-5 exportReport" style="float:left;"
                @click="saveVendor()">Save
                Vendor</button>
        </div>
    </div>
</template>
    
    
<script>
import { Vue, Component, Props } from "vue-property-decorator";
import SelectState from "@/components/SelectState.vue";
import SelectCountry from "@/components/SelectCountry.vue";
import csc from "country-state-city";
export default {

    name: "VendorsAction",
    props: ["vendor"],
    components: { SelectState, SelectCountry },
    data() {
        return {
            vendorData: this.$props.vendor,
            city_list: [],
            country_list: [],
            state_list: [],
        };
    },
    created() {
        this.country_list = csc.getAllCountries();
        if (this.vendorData.id) {
            this.onChangeCountry(this.vendorData.address.country);
            this.onChangeState(this.vendorData.address.state);
            this.onChangeCity(this.vendorData.address.city);
        } else {
            this.onChangeCountry("US");
        }
    },
    methods: {
        onInputChange(event) {
            if (event["target"]["id"] === "first_name" || event["target"]["id"] === "last_name" || event["target"]["id"] === "email" || event["target"]["id"] === "phone") {
                this.vendorData[event["target"]["id"]] = event["target"]["value"];
            } else if (event["target"]["id"] === "street") {
                this.vendorData["address"]["street"][0] = event["target"]["value"];
            } else if (event["target"]["id"] === "postal") {
                this.vendorData["address"]["postal"] = event["target"]["value"];
            }
        },
        saveVendor() {
            this.$emit("save", this.vendorData);
            this.vendorData = [];
        },
        cancelAction() {
            this.$emit("save", []);
        },
        onChangeCountry(value) {
            this.vendorData.address.country = value;
            this.state_list = csc.getStatesOfCountry(value);
        },
        onChangeState(value) {
            this.vendorData.address.state = value;
            this.city_list = csc.getCitiesOfState(this.vendorData.address.country, this.vendorData.address.state);
        },
        onChangeCity: function changeCity(event) {
            this.vendorData.address.city = event.target.value;
        },
    },
};
</script>
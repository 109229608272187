<template>
  <div class="row">

    <div class="col-12">

      <section class="hk-sec-wrapper border rounded pa-20">
        <div v-if="tableData.url === 'Dashboard'" class="row">
          <div class="col-10">
            <h5 class="hk-pg-title bCredentials ml-2">Transaction History</h5>
          </div>
          <div class="col-2">
            <a href="transactions">
              <button class="btn btn-simple btn-block">View All Transactions</button>
            </a>
          </div>
        </div>
        <div v-else-if="tableData.url === 'Transactions'" class="row mr-5 mb-3">
          <div class="col-8">
            <h5 class="hk-pg-title bCredentials ml-2">Transaction History</h5>
          </div>
          <div class="col-3">
            <div class="input-group">
              <b-form-input id="filter-input" @input="onFiltered()" v-model="filter" type="search"
                placeholder="Search in list"></b-form-input>
              <div class="input-group-append">
                <button @click="showModal2()" class="btn p0 advFilter btn-block"> <img class="w-16p h-16p"
                    src="../assets/images/filter_24.png" alt="icon" /> </button>
              </div>
            </div>
          </div>
          <div class="col-1">
            <button @click="exportData" class="btn p0 exportReport  btn-block"> <img class="w-22p h-18p"
                src="../assets/images/download_cloud_outline_24.svg" alt="icon" />Export Report</button>
          </div>
        </div>
        <div v-if="tableData.url === 'Vendors'" class="row mr-5 mb-3">
          <div class="col-8">
            <h5 class="hk-pg-title bCredentials ml-2">Vendors List</h5>
          </div>
          <div class="col-3">
            <b-form-input id="filter-input" @input="onFiltered()" v-model="filter" type="search"
              placeholder="Search in list"></b-form-input>
          </div>
          <div class="col-1">
            <button @click="showModal3([])" class="btn p0 exportReport  btn-block"> <i class="fa fa-plus-square-o"></i>
              Add Vendor</button>
          </div>
        </div>
        <div v-if="tableData.url === 'BusinessPayment'" class="row">
          <div class="ml-5 pl-5">
            <h4 class="hk-pg-title">Select Vendor</h4>
            <p class="hk-pg-subtitle">Please choose a vendor to send payment to.</p>
          </div>
        </div>
        <div class="table-wrap">
          <b-table id="my-table" :items="tableData.data" :fields="[{
            key: 'checkmark',
            label: '',
            thClass: 'text-center',
            tdClass: 'text-center'
          }, ...tableData.header]" :per-page="perPage" :current-page="currentPage" :filter="filter"
            :filter-included-fields="filterOn" small>
            <template v-slot:fields>
              <div class="squareTable"><img class="w-5p squareIcons" src="../assets/images/Union.png" alt="icon" />
              </div>
            </template>
            <template v-if="tableData.header.some(column => column.key === 'checkmark')"
              v-slot:cell(checkmark)="{ item }">
              <b-form-radio @change="selectRow(item)" v-model="selectedItem" :value="item"></b-form-radio>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <b-dropdown id="dropdown-1" text="" class="m-md-2 dropdownRemoved dropdown-toggle-ellipsis">
                <b-dropdown-item @click="showModal(item)">View Details</b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:cell(action)="{ item }">
              <b-dropdown id="dropdown-1" text="" class="m-md-2 dropdownRemoved dropdown-toggle-ellipsis">
                <b-dropdown-item @click="showModal3(item)"><i class="fa fa-pencil"></i> Edit Vendor</b-dropdown-item>
                <b-dropdown-item @click="showModal4(item)"><i class="fa fa-trash"></i> Delete Vendor</b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:cell(vendor_name)="{ item }">
              <div class="tamounts"> {{ item.first_name }} {{ item.last_name }}</div>
            </template>
            <template v-slot:cell(contact_number)="{ item }">
              <div class="tamounts"> {{ item.contact_number }}</div>
            </template>
            <template v-slot:cell(phone)="{ item }">
              <div class="tamounts"> {{ item.phone }}</div>
            </template>
            <template v-slot:cell(email_address)="{ item }">
              <div class="tamounts"> {{ item.email_address }}</div>
            </template>
            <template v-slot:cell(email)="{ item }">
              <div class="tamounts"> {{ item.email }}</div>
            </template>
            <template v-slot:cell(address)="{ item }">
              <div class="tamounts"> {{ item.address }}</div>
            </template>
            <template v-slot:cell(vendor_country)="{ item }">
              <div v-if="item.address" class="tamounts"> {{ item.address.country }}</div>
            </template>
            <template v-slot:cell(vendor_address)="{ item }">
              <div v-if="item.address" class="tamounts"> {{ item.address.street[0] }}, {{ item.address.city }}, {{
                item.address.stateName }} {{ item.address.postal }}</div>
            </template>
            <template v-slot:cell(country)="{ item }">
              <div class="tamounts"> {{ item.country }}</div>
            </template>
            <template v-slot:cell(sender_amount)="{ item }">
              <div class="tamounts"> {{ item.sender_currency_code }} {{ item.sender_amount }} </div>
            </template>
            <template v-slot:cell(status)="{ item }">
              <div class="badge"
                :style="[item.status ? { backgroundColor: item.status.color } : { backgroundColor: 'FFF' }]"> <span
                  v-if="item.status">{{ item.status.label }}</span> </div>
            </template>
            <template v-slot:cell(created_at)="{ item }">
              <td class="tamounts">{{ item.created_at }}</td>
            </template>
            <template v-slot:cell(beneficiary_amount)="{ item }">
              <div class="tamounts"> {{ item.beneficiary_currency_code }} {{ item.beneficiary_amount }} </div>
            </template>
            <template v-slot:cell(beneficiary_given_name)="{ item }">
              <div class="tnames"> {{ item.beneficiary_given_name }} </div>
            </template>
            <template v-slot:cell(beneficiary_family_name)="{ item }">
              <div class="tnames"> {{ item.beneficiary_family_name }} </div>
            </template>
            <template v-slot:cell(sender_given_name)="{ item }">
              <div class="tnames"> {{ item.sender_given_name }} </div>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="my-table"></b-pagination>
          <vue-modal id="modal-xl" v-if="isModalVisible">
            <template slot="header">
              <h5 class="modal-title">Transaction Details</h5>
              <button type="button" class="close" @click="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </template>
            <div slot="body" ref="RefCreateApi">
              <TransactionDetails :data="infoDetailed" />
            </div>
            <div slot="footer">
              <button type="button" class="btn btn-secondary" style="float:left;" @click="closeModal()">Close</button>
              <button type="button" class="btn btn-primary" @click="saveAndClose()">Save changes</button>
            </div>
          </vue-modal>
          <vue-modal-centered id="modal-xl" v-if="isModalVisible2">
            <template slot="header">
              <h5 class="modal-title">Advanced Filter</h5>
              <button type="button" class="close" @click="closeModal2()">
                <span aria-hidden="true">&times;</span>
              </button>
            </template>
            <div class="d-grid" slot="body" ref="RefCreateApi">
              <AdvancedFilter @closeModal="closeModal2()" @refreshTable="refreshTable" @refresh="refresh" />
            </div>
          </vue-modal-centered>
          <vue-modal-centered id="modal-xl" v-if="isModalVisible3">
            <template slot="header">
              <h5 class="modal-title">{{ action }} Vendor</h5>
              <button type="button" class="close" @click="closeModal3()">
                <span aria-hidden="true">&times;</span>
              </button>
            </template>
            <div style="height: 800px;" class="d-grid" slot="body">
              <VendorsAction @save="onSaveVendor" :vendor="vendor" />
            </div>
          </vue-modal-centered>
          <vue-delete-modal id="modal-sm" v-if="isModalVisible4">
            <template slot="header">
              <h5 class="modal-title">Delete Users</h5>
              <button type="button" class="close" @click="closeModal4()">
                <span aria-hidden="true">&times;</span>
              </button>
            </template>
            <div slot="body">
              <div class="d-grid justify-content-center align-items-center">

                <img class="w-200 squareIcons align-self-center justify-self-center"
                  src="../assets/images/Illustration.png" alt="icon" />
              </div>
              <p class="modalMessage mt-5"> Are you sure you want to delete {{ vendor.name }} {{ vendor.lastname }} from
                vendor list?</p>
            </div>
            <div slot="footer">
              <div class="row justify-content-between">
                <div class="col-12 col-md-auto">
                  <button type="button" class="btn cancel" @click="closeModal4()">Cancel</button>
                </div>
                <div class="col-12 col-md-auto">
                  <button type="button" class="btn yesdelete" @click="deleteVendor()">Yes, delete</button>
                </div>
              </div>
            </div>
          </vue-delete-modal>
        </div>
      </section>
    </div>
  </div>
</template>


<script>
import { Vue, Component, Props } from "vue-property-decorator";
import TransactionDetails from "@/components/TransactionDetails.vue";
import AdvancedFilter from "@/components/AdvancedFilter.vue";
import VueModal from "@/components/VueModal.vue";
import VueModalCentered from "@/components/VueModalCentered.vue";
import VueDeleteModal from "@/components/VueDeleteModal.vue";
import VendorsAction from "@/views/VendorsAction.vue";
import exportFromJSON from "export-from-json";
import vendors from "@/store/modules/vendors";

export default {
  name: "Table",
  props: ["tableData"],
  components: { VueModal, TransactionDetails, VueModalCentered, AdvancedFilter, VendorsAction, VueDeleteModal },
  data() {
    return {
      selectedItem: undefined,
      perPage: 10,
      currentPage: 1,
      infoDetailed: [],
      vendor: [],
      allSelected: false,
      indeterminate: false,
      selectedRows: [],
      action: "",
      emptyVendor: {
        vendorId: 0,
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        address: {
          street: [],
          state: "",
          postal: "",
          country: "",
          city: "",
        },
      },
      isModalVisible: false,
      isModalVisible2: false,
      isModalVisible3: false,
      isModalVisible4: false,
      isHistoriesTab: true,
      newTableData: [],
      storedData: this.$props,
      filter: undefined,
      filterOn: [],
      exportTransactions: this.$props.tableData.data,
      showCheckbox: {
        type: Boolean,
        default: this.$props.showCheckbox,
      },
    };
  },
  computed: {
    rows() {
      return this.$props.tableData.data.length;
    },
    someSelected() {
      return (
        this.selectedRows.length > 0 && this.selectedRows.length < this.tableData.data.length
      );
    },
  },
  methods: {
    selectAll() {
      this.selectedRows = [];
      if (!this.allSelected) {
        this.tableData.data.forEach((item) => {
          item.isSelected = true;
          this.selectedRows.push(item);
        });
      } else {
        this.tableData.data.forEach((item) => {
          item.isSelected = false;
        });
      }
      this.indeterminate = false;
    },
    setInitialSelectedRows(user) {
      if (user) {
        const selectedRow = this.tableData.data.find((row) => row.id === user.id);
        if (selectedRow) {
          // Actualiza la variable "selectedItem" con el usuario seleccionado
          this.selectedItem = user;
          selectedRow.isSelected = true;
        }
      }
    },
    selectRow(item) {
      this.$emit("selectedRow", item);
      // Actualizar selectedItem con el elemento seleccionado actualmente
      this.selectedItem = item;
    },
    deleteVendor() {
      this.$emit("save", this.vendor.id);
      this.closeModal4();
    },
    onSaveVendor(updatedVendor) {
      if (updatedVendor.email) {
        this.$emit("save", updatedVendor);
      }
      this.isModalVisible3 = false;
    },
    showModal(transaction) {
      this.infoDetailed = transaction;
      this.isModalVisible = true;
    },
    showModal2() {
      this.isModalVisible2 = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onFiltered(filteredItems) {
      this.currentPage = 1;
    },
    closeModal2() {
      this.isModalVisible2 = false;
    },
    showModal3(vendor) {
      if (vendor.id === undefined) {
        this.vendor = this.emptyVendor,
          this.action = "Add";
      } else {
        this.action = "Edit";
        this.vendor = vendor;
      }

      this.isModalVisible3 = true;
    },
    closeModal3() {
      this.isModalVisible3 = false;
    },
    showModal4(vendor) {
      this.vendor = vendor;
      this.isModalVisible4 = true;
    },
    closeModal4() {
      this.isModalVisible4 = false;
    },
    pad(num) {
      let s = "" + num;
      if (num < 10) {
        s = num.substring(1, 2);
      }
      return s;
    },
    exportData() {
      const data = this.$props.tableData.data;
      const fileName = "exported-data";
      const exportType = exportFromJSON.types["csv"];
      exportFromJSON({ data, fileName, exportType });
    },
    refresh(value) {
      this.newTableData = [];
      if (value) {
        for (const x in value) {
          if (value.hasOwnProperty(x)) {
            for (const itemstoPush of this.$props.tableData.data) {
              if (itemstoPush[x] === value[x]) {
                this.newTableData.push(itemstoPush);
              }
              if (x === "sender_amount") {
                if (itemstoPush["sender_amount_currency"] === value[x]) {
                  this.newTableData.push(itemstoPush);
                }
              }
              if (x === "beneficiary_amount") {
                if (itemstoPush["beneficiary_amount_currency"] === value[x]) {
                  this.newTableData.push(itemstoPush);
                }
              }
              if (x === "sender_given_name") {
                if (itemstoPush["sender_full_name"] === value[x]) {
                  this.newTableData.push(itemstoPush);
                }
              }
              if (x === "beneficiary_given_name") {
                if (itemstoPush["beneficiary_full_name"] === value[x]) {
                  this.newTableData.push(itemstoPush);
                }
              }
              if (x === "status") {
                if (itemstoPush[x].label === value[x]) {
                  this.newTableData.push(itemstoPush);
                }
              }
              if (x === "typeDate" || x === "quote_expiration" || x === "quote_instant") {
                if (x === "quote_expiration" || x === "quote_instant") {
                  const createdUnixToDate = new Date(itemstoPush.quote_instant * 1000).toLocaleDateString("en-US");
                  const updatedUnixToDate = new Date(itemstoPush.quote_expiration * 1000).toLocaleDateString("en-US");
                  const dmy = this.pad(value[x].substring(8, 10)) + "-" + this.pad(value[x].substring(5, 7));
                  const year = value[x].substring(0, 4);
                  const month = dmy.substring(2, 3);
                  const day = dmy.substring(0, 1);
                  const date = month + "/" + day + "/" + year;
                  if (createdUnixToDate === date || updatedUnixToDate === date) {
                    this.newTableData.push(itemstoPush);
                  }
                } else {
                  const from = Math.floor(new Date(value[x].from).getTime() / 1000);
                  const to = Math.floor(new Date(value[x].to).getTime() / 1000);
                  if (itemstoPush[value[x].type] > from && itemstoPush[value[x].type] < to) {
                    this.newTableData.push(itemstoPush);
                  } else if (value[x].date !== "") {
                    const createdUnixToDate = new Date(itemstoPush.created_at * 1000).toLocaleDateString("en-US");
                    const updatedUnixToDate = new Date(itemstoPush.updated_at * 1000).toLocaleDateString("en-US");
                    const dmy = this.pad(value[x].date.substring(8, 10)) + "-" + this.pad(value[x].date.substring(5, 7));
                    const year = value[x].date.substring(0, 4);
                    const month = dmy.substring(2, 3);
                    const day = dmy.substring(0, 1);
                    const date = month + "/" + day + "/" + year;
                    if (createdUnixToDate === date || updatedUnixToDate === date) {
                      this.newTableData.push(itemstoPush);
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (this.newTableData.length === 0) {
        this.tableData.data = [];
      } else {

        this.tableData.data = this.newTableData;
      }
    },
    refreshTable() {
      this.tableData.data = [];
      const timeOutms = 300;
      setTimeout(() => {
        this.$emit("getTransactions");
      }, timeOutms);

    },
  },
};
</script>
<template >
    <div class="d-grid">
        <img v-if="data.image === 'Alert'" class="w-200 squareIcons align-self-center justify-self-center"
            src="../assets/images/Alert.png" alt="icon" />
        <img v-if="data.image === 'Lock'" class="w-200 squareIcons align-self-center justify-self-center"
            src="../assets/images/Lock.png" alt="icon" />
        <p class="modalMsg">{{ data.key.msg }} <b class="b">{{ data.key.msg2 }}</b> {{ data.key.msg3 }}</p>
        <div v-if="data.key.showInput == true">
            <b-input-group>
                <b-form-input id="copyKey" :value=" data.key.value.partner_token" type="text">{{
                    data.key.value.partner_token }} </b-form-input>

                <b-input-group-append>
                    <b-button @click="copyToClickboard()" class="bButon" variant="primary"> <img class="pt2 align-self-center justify-self-center"
                            src="../assets/images/copy_outline_24.svg" alt="icon" /></b-button>
                </b-input-group-append>
            </b-input-group>
        </div>
        <p class="modalMsg">{{ data.key.msg4 }} </p>

    </div>
</template>
  
  
<script>
import { Vue, Component, Props } from "vue-property-decorator";
import ExpensesAddress from "@/components/ExpensesAddress.vue";
import CardDetail from "@/components/CardDetail.vue";
import transactions from "@/store/modules/transactions";
import claimMoney from "@/store/modules/claimMoney";
import { getTransactionDetails } from "@/store/apis/transactions";
export default {
    name: "RegenerateToken",
    props: ["data"],
    components: {},
    data() {
        return {
            details: {
                data: [],
            },
            showLoading: true,
        };
    },
    methods: {
        copyToClickboard() {
            // Get the text field
            const copyText = document.getElementById("copyKey");

            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value.split(":").pop());
        },
    },
    async mounted() {
        const response = await getTransactionDetails(this.data.id, claimMoney.transaction.jwt);
        this.details = response;
        this.showLoading = false;
        /*  if (!isTypePaymentAPIError(response)) {
           claimMoney.setTransaction(response as TransactionPredebited);
         }
         this.isLoading = false; */
    },

};
</script>



































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  notifyUser,
  isUserRoleAdmin,
  isTypePaymentAPIError,
  isTypeBusinessInfo,
  errorsToAssociative,
} from "@/helpers/template";
import partnerSettings from "@/store/modules/partnerSettings";
import { BusinessInfo } from "@/store/models/partnerSettings";
import { validateOrReject } from "class-validator";
import FormElement from "@/components/FormElement.vue";
import VueModal from "@/components/VueModal.vue";
import { UserProfile } from "@/store/models/users";

@Component({ components: { VueModal, FormElement } })
export default class BusinessPlansTab extends Vue {
  isEditModalVisible = false;
  billing: BusinessInfo = new BusinessInfo();
  billingCurrent: BusinessInfo = new BusinessInfo();
  billingError = {};
  isUserAdmin = false;

  getBusinessInfo() {
    partnerSettings
      .getBusinessInfo()
      .then(() => {
        const response = partnerSettings.getBIResponse;
        if (isTypeBusinessInfo(response)) {
          this.setBusinessInfo(response);
        } else if (isTypePaymentAPIError(response)) {
          notifyUser("Business Info Failed", response.message, "error");
        }
      })
      .catch((error) => {
        notifyUser(
          "Business Info Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  async onBillingUpdate() {
    try {
      await validateOrReject(this.billing);
      this.billingError = {};
    } catch (errors) {
      notifyUser(
        "Update Failed",
        "Please correct the designated fields.",
        "error",
      );
      this.billingError = errorsToAssociative(errors);
      return;
    }

    partnerSettings
      .updateBusinessInfo({
        name: this.billing.name,
        email: this.billing.contact,
        phone: this.billing.phone,
        address: {
          street: this.billing.address.split(", "),
          city: this.billing.city,
          state: this.billing.state,
          postal: this.billing.zip,
          country: this.billing.country,
        },
      })
      .then(() => {
        const response = partnerSettings.updateBIResponse;
        if (isTypeBusinessInfo(response)) {
          this.isEditModalVisible = false;
          this.setBusinessInfo(response);
          notifyUser("Success", "Profile Updated", "success");
        } else if (isTypePaymentAPIError(response)) {
          notifyUser("Update Failed", response.message, "error");
        }
      })
      .catch((error) => {
        notifyUser("Update Error", "Unknown error. Please try again.", "error");
      });
  }

  showEditModal() {
    this.isEditModalVisible = true;
  }

  closeEditModal() {
    this.isEditModalVisible = false;
    this.resetBusinessInfo();
  }

  setBusinessInfo(billing) {
    this.billing = new BusinessInfo(billing);
    Object.assign(this.billingCurrent, this.billing);
  }

  resetBusinessInfo() {
    Object.assign(this.billing, this.billingCurrent);
  }

  mounted() {
    this.isUserAdmin = isUserRoleAdmin();
    this.getBusinessInfo();
  }
}













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SelectState extends Vue {
  @Prop() param: string;
  @Prop() onChange: void;
  @Prop() index: number;
  @Prop() state_list: object;
  get list() {
    return this.state_list;
  }
}

import {
  VuexModule,
  Module,
  getModule,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import { TransactionPredebited, TransactionsQuery } from "@/store/models/transactions";
import { transactionList } from "../apis/transactions";
import {PaymentAPIError} from "@/store/models/partnerSettings";

@Module({
  namespaced: true,
  name: "transactions",
  store,
  dynamic: true,
})
class TransactionsModule extends VuexModule {
  onTransactionListResponse: TransactionPredebited[] = [];
  allTransactions: TransactionPredebited[] = [];

  get todayTransactions() {
    return this.allTransactions.length <= 7
      ? this.allTransactions
      : this.allTransactions.slice(0, 7);
  }

  @MutationAction({ mutate: ["onTransactionListResponse"] })
  async onTransactionList(query: TransactionsQuery, from: number, size: number) {
    const onTransactionListResponse: TransactionPredebited[] | PaymentAPIError = await transactionList(query);
    return { onTransactionListResponse };
  }
}

export default getModule(TransactionsModule);

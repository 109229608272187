





















import { Vue, Component, Prop } from "vue-property-decorator";
import { isTypePaymentAPIError } from "@/helpers/template";
import { getTransaction } from "@/store/apis/transactions";
import { TransactionPredebited } from "@/store/models/transactions";
import claimMoney from "@/store/modules/claimMoney";

@Component
export default class Claim extends Vue {
  @Prop() next: () => void;

  claimMoney = claimMoney;
  isLoading = true;

  async mounted() {
    const { transaction_id, JWT } = this.$router.currentRoute.query;
    const response = await getTransaction(transaction_id, JWT);
    /*if (!isTypePaymentAPIError(response)) {
      claimMoney.setTransaction(response as TransactionPredebited);
    }*/
    this.isLoading = false;
  }
}

<template>
  <div class="hk-pg-inner">
    <div class="container containerDashboard mt-xl-50 mt-30">
      <div class="hk-pg-header hk-pg-headerCredentials">
        <div>
          <h4 class="hk-pg-title">Vendors</h4>
          <p class="hk-pg-subtitle">These are the persons to whom you frequently send money.</p>
        </div>
      </div>
      <Table @save="actionVendors" :tableData="tableData" />
    </div>
  </div>
</template>
  
<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import { scheduleOptions } from "@/store/constants";
import Datepicker from "vuejs-datepicker";
import { tableStatus } from "@/store/constants";
import vendors from "@/store/modules/vendors";
import VueDropdown from "@/components/VueDropdown.vue";
import { isTypePaymentAPIError, notifyUser } from "../helpers/template";
import "datatables.net-bs4";
import Table from "@/components/Table.vue";
import csc from "country-state-city";
@Component({ components: { VueDropdown, Datepicker, Table } })
export default class Vendors extends Vue {

  currentSort = "created_at";
  currentSortDir = "desc";
  vendorsList = [];
  countryList = [];
  allTransactions = [];
  data() {
    return {
      tableData: {
        modal3: false,
        url: "Vendors",
        header: [{
          key: "",
        },
        {
          key: "vendor_name",
        },
        {
          key: "phone",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "vendor_address",
          sortable: true,
        },
        {
          key: "vendor_country",
          sortable: true,
        },
        {
          key: "action",
          sortable: false,
        }],
        data: [],
      },
      availableBalance: {
        title: "Your Available Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Available",
      },
      unsettledBalance: {
        title: " Daily Unsettled Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Unsettled",
      },
      spendBalance: {
        title: "Daily Spend Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Spend",
      },
    };
  }
  methods() {

  }
  onScheduleSelect(selected) {
  }

  created() {
    this.getVendors();
  }
  updated() {
  }
  actionVendors(updatedVendor) {
    if (updatedVendor.vendorId === 0) {
      vendors
      .addVendors(updatedVendor)
      .then(() => {
        const response = vendors.getVendorsResponse;
        if (response) {
          this.getVendors();
        }
      })
      .catch((error) => {
        notifyUser(
          "Users List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
    } else if (updatedVendor.partner_id !== undefined) {
      vendors
      .editVendors(updatedVendor)
      .then(() => {
        const response = vendors.getVendorsResponse;
        if (response) {
          notifyUser(
          "Update Success",
          "Vendor edited successfully.",
          "success",
        );
          this.getVendors();
        }
      })
      .catch((error) => {
        notifyUser(
          "Users List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
    } else {
      vendors
      .deleteVendors(updatedVendor)
      .then(() => {
        const response = vendors.getVendorsResponse;
        if (response) {
          notifyUser(
          "Delete Success",
          "Vendor deleted successfully.",
          "success",
        );
          this.getVendors();
        }
      })
      .catch((error) => {
        notifyUser(
          "Users List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
    }
  }

  getVendors() {
    vendors
      .getVendors()
      .then(() => {
        const response = vendors.getVendorsResponse;
        if (response instanceof Array) {
          response.forEach((element) => {
            const country = element.address.country;
            this.countryList = csc.getStatesOfCountry(country);
            for (const states of this.countryList) {
              if (states.isoCode === element.address.state) {
                element.address["stateName"] =  states.name;
              }
            }
          });

          this.tableData.data = response;
        }
      })
      .catch((error) => {
        notifyUser(
          "Users List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  updatePagination() {
  }
  sort(field) {
    if (this.currentSort === field) {
      this.currentSortDir = this.currentSortDir === "desc" ? "asc" : "desc";
    } else {
      this.currentSort = field;
      this.currentSortDir = "desc";
    }
  }
  sortIcon(field) {
    return this.currentSort === field && this.currentSortDir === "desc"
      ? "arrow_drop_down"
      : "arrow_drop_up";
  }
}
</script>
  
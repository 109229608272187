





















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import AddressForm from "@/components/AddressForm.vue";
import { TheMask } from "vue-the-mask";
import onboarding from "@/store/modules/onboarding";
import File from "@/components/onboarding/File.vue";
import { uploadFiles } from "@/helpers/files";

@Component({ components: { AddressForm, TheMask, File } })
export default class Business extends Vue {
  @Prop() submit: (saveOnly: boolean) => void;
  @Prop() deleteFile: (file: any) => void;

  onboarding = onboarding;
  documents = [];

  async changeState(value: string) {
    await onboarding.updateBusinessDetailsState(value);
  }
  async changeCountry(value: string) {
    await onboarding.updateBusinessDetailsCountry(value);
  }
  async uploadDocuments(e) {
    this.documents = Array.from(e.target.files);
    await uploadFiles(
      this.documents,
      onboarding.businessDetails.documentsIds.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateBusinessDocumentsIds,
      this.submit,
    );
  }
  // async changeZipCode(value: string) {
  //   await onboarding.updateBusinessDetailsFromZipCode(value);
  // }
}































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import csc from "country-state-city";
import { Fragment } from "vue-fragment";

import AddressView from "@/components/AddressView.vue";
import onboarding from "@/store/modules/onboarding";
import { OnboardingStatus } from "@/store/models/users";

@Component({ components: { AddressView, Fragment } })
export default class Documents extends Vue {
  @Prop() submit: (saveOnly: boolean, status: string) => void;
  @Prop() navigate: (routeName: string) => void;

  onboarding = onboarding;
  representative_address = onboarding.representative.address.value;
  businessDetail_address = onboarding.businessDetails.address.value;

  complete() {
    this.submit(false, OnboardingStatus.ReadyToSubmit);
  }

  getStateByCode(stateCode, countryCode) {

    // Handle the special "All" and "None" cases that we added
    if ((stateCode === "None") || (stateCode === "All")) {
      return stateCode;
    }

    // Lookup the states for the country
    const states = csc.getStatesOfCountry(countryCode);
    if (!states) {
      return "UNKNOWN";
    }

    // Find state with matching iso code
    const state = states.find( (s) => s.isoCode === stateCode);
    return state?.name || "UNKNOWN";
  }

  getCountryByCode(code) {
    // Handle the special "None" case that we added
    if (code === "None") {
      return code;
    }

    return csc.getCountryByCode(code).name || "UNKNOWN";
  }

  getFilteredFiles(filter) {
    return onboarding.files.filter((file) => file && file.status !== "Delete" && filter.includes(file.fileId));
  }
}

<template >
  <div class=" row">
    <div class="col-12">
      <div class="card-title">
        <p class="pt-3 senderDetail">Sender Details </p>
      </div>
    </div>
    <div class="col-12">
      <div class="card-body pt-0 pl-0 mb-0 pb-0  card-body-action">
        <p class="detailTitles ml-0 pl-0 mt-0 ">Sender name:</p>
        <p class="detailDescription">{{ firstName }} {{ lastName }}</p>

        <p class="detailTitles">Sender address:</p>
        <p v-if="details.sender_address" class="detailDescription"> {{ details.sender_address.street[0] }}</p>
        <p v-if="details.sender_address" class="detailDescription"> {{ details.sender_address.city }} -
          {{ details.sender_address.state }} - {{ details.sender_address.postal }}, {{ details.sender_address.country }}
        </p>

        <p class="detailTitles">Sender email:</p>
        <p class="detailDescription">{{ details.sender_email }}</p>

        <p class="detailTitles">Sender number:</p>
        <p class="detailDescription">{{ details.sender_phone }}</p>

        <p class="detailTitles">Sender ID:</p>
        <p class="detailDescription">{{ details.sender_id }}</p>

        <p class="detailTitles">Sender currency code:</p>
        <p class="detailDescription">{{ details.sender_currency_code }}</p>

        <p class="detailTitles">Sender country code:</p>
        <p class="detailDescription">{{ details.sender_country_code }}</p>

      </div>
    </div>


  </div>
</template>
  
<script>
import { Vue, Component, Props } from "vue-property-decorator";
import users from "@/store/modules/users";
export default {
  name: "SenderDetail",
  props: ["details"],
  data() {
    return {
      firstName: "",
      lastName: "",
    };
  },
  created() {
    this.firstName = users.profile.first_name;
    this.lastName = users.profile.last_name;
  },
};
</script>
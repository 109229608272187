











































































import { Vue, Component } from "vue-property-decorator";
import OnboardingItem from "@/components/onboarding/OnboardingItem.vue";
import users from "@/store/modules/users";
import onboarding from "@/store/modules/onboarding";
import { notifyUser, isTypePaymentAPIError } from "@/helpers/template";
import { OnboardingStatus } from "@/store/models/users";
import * as _ from "lodash";

function isSectionAccessibleGlobal(section) {
  const response = users.onboardingUIResponse;
  if (isTypePaymentAPIError(response)) {
    return false;
  }
  return response.find((e) => e.name === section)?.value ?? false;
}
@Component({
  beforeRouteEnter: Onboarding.preRoute,
  beforeRouteUpdate: Onboarding.preRoute,
  components: { OnboardingItem },
})
export default class Onboarding extends Vue {

  static async preRoute(to, from, next) {
    await users.getOnboardingUI();
    if (!users.onboardingDataResponse?.status) {
      await users.getOnboardingData();
    }
    if (to.name === "summary") {
      setTimeout(() => {
          if (isSectionAccessibleGlobal("choose-products") && Object.keys(onboarding.chooseProducts).length === 0) {
            next("/onboarding/choose-products");
          } else if (isSectionAccessibleGlobal("card") && !onboarding.card.number) {
            next("/onboarding/card");
          } else if (isSectionAccessibleGlobal("bank") && !onboarding.bank.accountNumber) {
            next("/onboarding/bank");
          } else if (isSectionAccessibleGlobal("representative") && !onboarding.representative.firstName.value) {
            next("/onboarding/representative");
          } else if (isSectionAccessibleGlobal("business-details") && !onboarding.businessDetails.name.value) {
            next("/onboarding/business-details");
          } else if (isSectionAccessibleGlobal("incorporation-details") && !(onboarding.incorporationDetails.ein.value || onboarding.incorporationDetails.documentsIds.value.length > 0)) {
            next("/onboarding/incorporation-details");
          } else if (isSectionAccessibleGlobal("compliance-details") && !onboarding.complianceDetails.orgChart.value) {
            next("/onboarding/compliance-details");
          } else if (isSectionAccessibleGlobal("business-documents") && (onboarding.businessDocuments.owners.value.length === 0)) {
            next("/onboarding/business-documents");
          }
      }, 500);
    }

    const uiResponse = users.onboardingUIResponse;
    if (!isTypePaymentAPIError(uiResponse)) {
      const toPath = to.path.replace("/onboarding/", "");
      if (
          uiResponse.find((e) => e.name === toPath && e.value) ||
          to.name === "summary"
      ) {
        next();
      } else {
        const lastSectionIdx = uiResponse.findIndex((e) => e.name === users.onboardingDataResponse?.last_completed_section);
        const route = uiResponse.slice(lastSectionIdx + 1).find((e) => e.value === true);
        next({ name: route?.name ?? "summary" });
      }
    } else {
      next();
    }
  }

  onboarding = onboarding;

  createFormData() {
    return {
      chooseProducts: onboarding.chooseProducts,
      card: onboarding.card,
      bank: onboarding.bank,
      representative: onboarding.representative,
      businessDetails: onboarding.businessDetails,
      incorporationDetails: onboarding.incorporationDetails,
      complianceDetails: onboarding.complianceDetails,
      businessDocuments: onboarding.businessDocuments,
    };
  }

  mounted() {
    this.updateOnboarding();
  }

  isSectionAccessible(section) {
    const response = users.onboardingUIResponse;
    if (isTypePaymentAPIError(response)) {
      return false;
    }
    return response.find((e) => e.name === section)?.value ?? false;
  }

  async deleteFile(file) {
    await onboarding.deleteFile(file);
    return this.submit(true);
  }

  async updateOnboarding() {
    await users.getOnboardingData();
    if (_.get(users, "onboardingDataResponse.onboardingNotFound", false)) {
      const form_data = this.createFormData();
      await users.createOnboarding({
        form_data,
        files: onboarding.files,
        status: OnboardingStatus.InProgress,
      });
      if (typeof users.onboardingResponse === "boolean" && users.onboardingResponse) {
        return this.updateOnboarding();
      } else {
        notifyUser("Error", "Onboarding Create Failed", "error");
      }
    } else {
      await onboarding.updateOnboarding(users.onboardingDataResponse);
    }
  }

  next() {
    const uiResponse = users.onboardingUIResponse;
    if (!isTypePaymentAPIError(uiResponse)) {
      const currentRoute = this.$router.currentRoute.name;
      const currentRouteIndex = uiResponse.findIndex((e) => e.name === currentRoute);
      const nextRoute = uiResponse.slice(currentRouteIndex + 1).find((e) => e.value);
      if (!nextRoute) {
        this.$router.push("/onboarding/summary");
      } else if (currentRoute === "summary") {
        this.$router.push("/dashboard");
      } else {
        this.$router.push(`/onboarding/${nextRoute.name}`);
      }
    } else {
      this.$router.push("/dashboard");
    }
  }

  navigate(routeName: string) {
    this.$router.push(`/onboarding/${routeName}`);
  }

  async submit(saveOnly, status?) {
    const form_data = this.createFormData();
    if (this.$data.APP_CONFIG.vgs.enabled) { delete form_data.card; }
    users
      .updateOnboarding({
        form_data,
        files: onboarding.files,
        status,
        last_completed_section: this.$router.currentRoute.name,
      })
      .then(async () => {
        const response = users.onboardingResponse;
        if (typeof response === "boolean" && response) {
          notifyUser("Success", "Onboarding Update Successful", "success");
          await users.getOnboardingData();

          // Ensure current state of onboarding object is set to server side
          // This will ensure correct file objects
          onboarding.updateOnboarding(users.onboardingDataResponse);
          if (saveOnly) { return; }
          this.next();
        } else {
          notifyUser("Error", "Onboarding Update Failed", "error");
        }
      })
      .catch((error) => {
        notifyUser(
          "Onboarding Update Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }
}

<template>
  <public-layout>
    <template slot="right-content">
      <h2 class="display-4 mb-10 font-44 pt-20">Reset code sent</h2>
      <p
        class="mb-30"
      >If you are registered on our system, you will receive instructions to reset password via the email address you provided.</p>
      <p class="mb-30">
        <strong>Haven't received an email?</strong> Please check your spam folder to make sure it's not in there.
      </p>
    </template>
  </public-layout>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import PublicLayout from "@/components/PublicLayout.vue";

@Component({ components: { PublicLayout } })
export default class ResetInstruction extends Vue {}
</script>

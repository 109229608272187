













































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import AddressForm from "@/components/AddressForm.vue";
import onboarding from "@/store/modules/onboarding";
import users from "@/store/modules/users";

@Component({
  components: { AddressForm, TheMask },
})
export default class Representative extends Vue {
  @Prop() submit: (saveOnly: boolean) => void;

  onboarding = onboarding;

  async changeState(value: string) {
    await onboarding.updateRepresentativeState(value);
  }
  async changeCountry(value: string) {
    await onboarding.updateRepresentativeCountry(value);
  }
  // async changeZipCode(value: string) {
  //   await onboarding.updateRepresentativeFromZipCode(value);
  // }

  onChangeSignUp(value: boolean) {
    if (value) {
      onboarding.updateRepresentativeContact(users.profile);
    }
  }
}
































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import { createQuote } from "@/store/apis/transactions";
import { notifyUser, isTypePaymentAPIError } from "@/helpers/template";
import SelectCountry from "@/components/SelectCountry.vue";
import sendMoney from "@/store/modules/sendMoney";

@Component({ components: { SelectCountry, TheMask } })
export default class SendMoney extends Vue {
  @Prop() next: () => void;

  sendMoney = sendMoney;

  async createQuote() {
    const response = await createQuote(sendMoney.senderAmount);
    if (!isTypePaymentAPIError(response)) {
      sendMoney.updateQuoteId(response["id"]);
      this.next();
    } else {
      notifyUser("Error", "Quote Create Failed", "error");
    }
  }
}































import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class FormElement extends Vue {
  @Prop() name: string;
  @Prop({ default: "input" }) type: string;
  @Prop() label: string;
  @Prop() value: string;
  @Prop() errors: string;
  @Prop({ default: true }) editable: boolean;
  @Emit("input") handleInput(e) {
    return e.target.value;
  }
}

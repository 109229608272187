import axios, { AxiosInstance } from "axios";
import { getRuntimeConfig } from "@/store/apis/config";
export const token = localStorage.getItem("user-token");
import { router } from "../../router";
import { clearJWT } from "./users";

let axiosInstance: AxiosInstance;

async function getAxiosInstance() {
  if (!axiosInstance) {
    // this is probably not the most efficient approach
    const jsonConfig = await getRuntimeConfig();
    axiosInstance = axios.create({
      baseURL: jsonConfig.vgs.enabled ? jsonConfig.vgs.baseUrl : jsonConfig.clientEdgeBaseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export let paymentApi = async () => {
  await getAxiosInstance();
  // global interceptor to redirect to login
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      if (err?.response?.status === 401) {
        await clearJWT();
        getRuntimeConfig().then((json) => {
          const appConfig = { APP_CONFIG: json };
          const routerObject = router(appConfig);
          routerObject.push("/login");
        });
      }
      return Promise.reject(err);
    },
  );
  return axiosInstance;
};

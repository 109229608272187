













import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class OnboardingItem extends Vue {
  @Prop() label: string;
  @Prop() to: string;
  @Prop() active: boolean;
}

<template >
    <b-card no-body class="mb-3 accordionsCard">

        <b-card-header header-tag="header" class="p-1 ridge" role="tab">
            <b-button block v-b-toggle.accordion-1 class="accordions text-left">Select transaction status</b-button>
        </b-card-header>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-form-select :value="title" @change="onInputChange" id="status" :v-model="selected" :options="options"></b-form-select>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>
    
    
<script>
import { Vue, Component, Props } from "vue-property-decorator";
export default {
    name: "StatusFilter",
    props: {
        title: String,
        selected: String,
    },
    data() {
      return {
        initialArray: {
                status: "",
            },
        options: [
          { value: "Pending", text: "Pending" },
          { value: "Processing", text: "Processing" },
          { value: "Failed", text: "Failed" },
          { value: "Completed", text: "Completed" },
        ],
      };
    },
    methods: {
        onInputChange(event) {
            this.initialArray.status = event;
            this.$emit("data", this.initialArray);
        },
    },
};
</script>
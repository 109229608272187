





















































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { BIconPlusCircle } from "bootstrap-vue";
import {
  notifyUser,
  errorsToAssociative,
} from "@/helpers/template";
import Owner from "@/components/onboarding/Owner.vue";
import File from "@/components/onboarding/File.vue";
import onboarding from "@/store/modules/onboarding";
import { uploadFiles } from "@/helpers/files";
import { validateOrReject } from "class-validator";
import { OwnerCreate } from "@/store/models/users";

@Component({
  components: { File, Owner, BIconPlusCircle },
})
export default class Documents extends Vue {
  @Prop() submit: (saveOnly: boolean) => void;
  @Prop() deleteFile: (file: any) => void;

  onboarding = onboarding;
  owners: OwnerCreate[] = [];
  ownerErrors = [];
  ownerMissingError = "";
  ownerLicense = [];
  fictitiousNameCertificate = [];
  ownershipOrganizationalChart = [];
  resumes = [];
  licenses = [];

  created() {
    setTimeout(() => {
      // for ( const owner of onboarding.businessDocuments.owners.value) {
      //   this.owners.push(new OwnerCreate(owner));
      // }
      this.owners = onboarding.businessDocuments.owners.value;

    }, 500);
  }

  async submitDocument() {
    let hasError = false;
    const tmpOwnerErrors = [];
    if ( this.owners.length === 0 ) {
      this.ownerMissingError = "Owner is required";
      notifyUser(
          "Registration Failed",
          "Please correct the designated fields.",
          "error",
        );
      return;
    }
    for (const owner of this.owners) {
      try {
        await validateOrReject(owner);
        const stake = owner.stake;
        const ownerError = this.blankErrors();
        if (stake < 0 || stake > 100) {
          ownerError.stake = ["Stake must be between 0 and 100."];
          hasError = true;
        }
        tmpOwnerErrors.push(ownerError);
      } catch (errors) {
        const output = errorsToAssociative(errors);
        tmpOwnerErrors.push(output);
        hasError = true;
      }
    }
    this.ownerErrors = tmpOwnerErrors;
    if (hasError) {
      notifyUser(
          "Registration Failed",
          "Please correct the designated fields.",
          "error",
        );
      return;
    }
    this.submit(false);
  }

  async uploadOwnerLicense(e) {
    this.ownerLicense = Array.from(e.target.files);
    await uploadFiles(
      this.ownerLicense,
      onboarding.businessDocuments.ownerLicenseIds.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateOwnerLicenseIds,
      this.submit,
    );
  }
  async uploadFictitiousNameCertificate(e) {
    this.fictitiousNameCertificate = Array.from(e.target.files);
    await uploadFiles(
      this.fictitiousNameCertificate,
      onboarding.businessDocuments.fictitiousNameCertificateIds.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateFictitiousNameCertificateIds,
      this.submit,
    );
  }
  async uploadOwnershipOrganizationalChart(e) {
    this.ownershipOrganizationalChart = Array.from(e.target.files);
    await uploadFiles(
      this.ownershipOrganizationalChart,
      onboarding.businessDocuments.ownershipOrganizationalChartIds.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateOwnershipOrganizationalChartIds,
      this.submit,
    );
  }
  async uploadResumes(e) {
    this.resumes = Array.from(e.target.files);
    await uploadFiles(
      this.resumes,
      onboarding.businessDocuments.resumesIds.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateResumesIds,
      this.submit,
    );
  }
  async uploadLicenses(e) {
    this.licenses = Array.from(e.target.files);
    await uploadFiles(
      this.licenses,
      onboarding.businessDocuments.licensesIds.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateLicensesIds,
      this.submit,
    );
  }
  async addOwner() {
    this.owners.push( new OwnerCreate ({
      address: { state: "AL", country: "US" },
    }));
    this.ownerErrors.push(this.blankErrors());
  }
  async changeState(index, value) {
    if (this.owners[index]) {
      this.owners[index].address.state = value;
    }
  }
  async changeCountry(index, value) {
    if (this.owners[index]) {
      this.owners[index].address.country = value;
    }
  }
  blankErrors() {
    return {
      stake: [],
    };
  }
}

<template >
  <div class="col-4">
    <div class="card cards">
      <div class="card-body  card-body-action">

        <div class="greySquare">
          <div v-if="data.icon === 'Available'">
            <img class="w-30p squareIcons" src="../assets/images/Group.svg" alt="icon" />
          </div>
          <div v-else-if="data.icon === 'Unsettled'">
            <img class="w-30p squareIcons" src="../assets/images/dailySpend.svg" alt="icon" />
          </div>
          <div v-else-if="data.icon === 'Spend'">
            <img class="w-30p squareIcons" src="../assets/images/Combined Shape.svg" alt="icon" />
          </div>
          
          <!--  <i :class="[data.icon]"></i> -->

        </div>
        <div class="mt-3">
          <p class="cardTitle">{{ data.title }}</p>
        </div>
        <div>
          <p class="amounts">$ {{ data.amount }}</p>

        </div>
<!--        <div class="mt-0 d-flex">

          <i class="ion ion-md-trending-up font-16 ml-0 mt-5 mr-1 dashboardPercents"></i>
          <p class="dashboardPercents">{{ data.percent }}% </p>
          <p class="ml-1 montserrat" > from last week</p>

        </div>-->

      </div>
    </div>
  </div>
</template>


<script>
import { Vue, Component, Props } from "vue-property-decorator";
export default {
  name: "DashboardCard",
  props: ["data"],
};
</script>






























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { acceptQuote } from "@/store/apis/transactions";
import { notifyUser, isTypePaymentAPIError } from "@/helpers/template";
import sendMoney from "@/store/modules/sendMoney";

@Component({
  beforeRouteEnter: Rewiew.preRoute,
  beforeRouteUpdate: Rewiew.preRoute,
})
export default class Rewiew extends Vue {
  static async preRoute(to, from, next) {
    if (Boolean(sendMoney.quoteId)) {
      next();
    } else {
      next({ name: "send" });
    }
  }

  @Prop() next: () => void;

  sendMoney = sendMoney;

  async acceptQuote() {
    const response = await acceptQuote(sendMoney);
    if (!isTypePaymentAPIError(response)) {
      sendMoney.updateQuoteId("");
      this.next();
    } else {
      notifyUser("Error", "Quote Accept Failed", "error");
    }
  }
}

<template>
  <div class="hk-pg-inner">
    <div class="container containerDashboard mt-xl-50 mt-30">
      <div class="hk-pg-header hk-pg-headerCredentials">
        <div>
          <h4 class="hk-pg-title bCredentials">Dashboard</h4>
          <p class="hk-pg-subtitle">View and search through money transfer transactions that have taken place within the
            previous 30 days.</p>
        </div>
        <div class="d-flex btn-rightCredentials">
          <a href="onboarding"
            class="btn btn-sm  reg-btnDashboard btn-primary btn-wth-icon icon-wthot-bg mb-15 mr-15 alert-please-register">
            <span class="icon-label">
              <i class="fa fa-exclamation-circle mr-10"></i>
            </span>
            <span class="btn-text btnCredentials">Activate Your Account</span>
          </a>
          <a href="#" class="btn reg-btnDashboard btn-sm btn btn-wth-icon icon-wthot-bg mb-15 alert-registered disabled"
            v-if="isOnboardingPending">
            <span class="icon-label">
              <i class="fa fa-exclamation-circle mr-10"></i>
            </span>
            <span class="btn-text">Account Activation Pending</span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="row justify-content-around">
            <DashboardCard :data="availableBalance" />
            <!--            <DashboardCard :data="unsettledBalance" />-->
            <DashboardCard :data="spendBalance" />
            <div class="col-12">
              <div class="card bg-white">
                <div class="card-body card-body-action bg-white">
                  <div>
                    <div class="row ml-2">
                      <h5 class="hk-pg-title bCredentials ml-2">Transaction Stats</h5>
                    </div>

                    <Chart :graphData="graphData" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Table :tableData="tableData" />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Props } from "vue-property-decorator";
import { scheduleOptions } from "@/store/constants";
import VueDropdown from "@/components/VueDropdown.vue";
import txnsModule from "@/store/modules/transactions";
import dashboard from "@/store/modules/dashboard";
import { isTypePaymentAPIError, notifyUser } from "../helpers/template";
import "datatables.net-bs4";
import users from "@/store/modules/users";
import { OnboardingStatus } from "@/store/models/users";
import $ from "jquery";
import Chart from "@/components/VueChart.vue";
import Notifications from "@/components/Notifications.vue";
import Table from "@/components/Table.vue";
import DashboardCard from "@/components/DashboardCard.vue";
import { tableStatus } from "@/store/constants";

@Component({ components: { VueDropdown, Chart, Notifications, Table, DashboardCard } })
export default class Dashboard extends Vue {
  scheduleSelectOptions = scheduleOptions;
  startDate = "";
  endDate = new Date();
  // Hours * Minutes * Seconds * MS
  txnIntervalInMs = 480 * 60 * 60 * 1000;
  startDate = new Date(this.endDate.getTime() - this.txnIntervalInMs);
  currentSort = "created_at";
  currentSortDir = "desc";
  allTransactions = [];
  dailyBalance = "";
  data() {
    return {

      tableData: {
        url: "Dashboard",
        header: [{
          key: "",
        },
        {
          key: "sender_amount",
          sortable: true,
        },
        {
          key: "sender_given_name",
          sortable: true,
        },
        {
          key: "beneficiary_amount",
          sortable: true,
        },
        {
          key: "beneficiary_given_name",
          sortable: true,
        },
        {
          key: "beneficiary_family_name",
          sortable: true,
        },
        {
          key: "created_at",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        }],
        data: [""],
      },
      graphData: {
        series: [],
        xAxis: [],
      },
      availableBalance: {
        title: "Your Available Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Available",
      },
      unsettledBalance: {
        title: " Daily Unsettled Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Unsettled",
      },
      spendBalance: {
        title: "Daily Spend Balance",
        amount: "",
        percent: "5.31",
        icon: "Spend",
      },
    };
  }
  onScheduleSelect(selected) {
  }
  async getTransactions() {
    // Hours * Minutes * Seconds * MS
    const txnIntervalInMs = 840 * 60 * 60 * 1000;
    this.startDate = new Date(this.endDate.getTime() - txnIntervalInMs);
    const query = {
      start_date: parseInt(this.startDate.getTime() / 1000),
      end_date: parseInt(this.endDate.getTime() / 1000),
      from: 0,
      size: 10,
    };
    await txnsModule
      .onTransactionList(query)
      .then(() => {
        let response = txnsModule.onTransactionListResponse;
        if (response instanceof Array) {
          response = response.map((transaction) => ({
            ...transaction,
            status: tableStatus.get(transaction.status),
            beneficiary_full_name: `${transaction.beneficiary_given_name} ${transaction.beneficiary_family_name}`,
            beneficiary_amount_currency: `${transaction.beneficiary_currency_code} ${transaction.beneficiary_amount}`,
            sender_full_name: `${transaction.sender_given_name} ${transaction.sender_family_name}`,
            sender_amount_currency: `${transaction.sender_currency_code} ${transaction.sender_amount}`,
          }));
          this.allTransactions = response;
          this.tableData.data = response;
        } else if (isTypePaymentAPIError(response)) {
          notifyUser("Transactions List Failed", response.message, "error");
        }
      })
      .catch((error) => {
        notifyUser(
          "Transactions List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  async getOnboardingData() {
    await users.getOnboardingData();
  }

  async getGraphData() {
    const groupBy = [];
    const txnIntervalInMs = 840 * 60 * 60 * 1000;
    this.startDate = new Date(this.endDate.getTime() - txnIntervalInMs);
    const query = {
      start_date: parseInt(this.startDate.getTime() / 1000),
      end_date: parseInt(this.endDate.getTime() / 1000),
      from: 0,
      size: 9999,
    };
    await txnsModule.onTransactionList(query);
    const response = txnsModule.onTransactionListResponse;
    for (const serieResponse of response) {
      const pusheable = {
        amount: serieResponse.sender_amount,
        date: new Date(serieResponse.created_at * 1000).toLocaleDateString("en-US"),
      };
      groupBy.push(pusheable);
    }

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    const getDaysArray = function(start, end) {
      for (const arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        const currDate = new Date(dt);
        const amount = 0;
        groupBy.push({ amount, date: new Date(dt).toLocaleDateString("en-US") });
      }
      return groupBy;
    };
    const result2 = getDaysArray(startDate, new Date());
    const unitedArrays = groupBy.concat(result2);

    const result = Object.values(unitedArrays.reduce((r, o) => {
      r[o.date] = r[o.date] || { date: o.date, amount: 0 };
      r[o.date].amount += +o.amount;
      return r;
    }, {}));

    const graphResult = result.sort(function(a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    for (const xAxis of graphResult) {
      const dayOfTheWeek = new Date(xAxis.date).toLocaleDateString("en-US", { weekday: "long" });
      this.graphData.xAxis.push(dayOfTheWeek);
      /* var newNumber = this.formatNumber(xAxis.amount) */
      this.graphData.series.push(xAxis.amount.toFixed(2));
    }
  }

  formatNumber(num) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    const si = [
      { v: 1E3, s: "K" },
      { v: 1E6, s: "M" },
      { v: 1E9, s: "B" },
      { v: 1E12, s: "T" },
      { v: 1E15, s: "P" },
      { v: 1E18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
  }

  async getAvailableBalance() {
    try {
      await dashboard.onAvailableBalance();
      const response = dashboard.availableBalance;
      if (response) {
        this.availableBalance.amount = parseFloat(response.data.balance).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    } catch (error) {
    }
  }

  async getDailyBalance() {
    try {
      await dashboard.onDailyBalance();
      const response = dashboard.dailyBalance;
      if (response) {
        this.spendBalance.amount = parseFloat(response.data.balance).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    } catch (error) {
    }
  }

  created() {
    this.getGraphData();
    this.getTransactions();
    this.getOnboardingData();
    this.getAvailableBalance();
    this.getDailyBalance();
    // dashboard.onDashboardCounts();
  }
  updated() {
    const timeOutms = 300;
    setTimeout(() => {
      $("#datable_1").DataTable({
        responsive: {
          details: {
            type: "column",
          },
        },
        autoWidth: false,
        paging: true,
        searching: false,
        retrieve: true,
        columnDefs: [{
          className: "control",
          orderable: false,
          targets: 0,
        }],
        order: [[1, "asc"]],
      });
    }, timeOutms);
  }

  get onboardingStatus() {
    if (!users.onboardingDataResponse) { return; }
    if (!isTypePaymentAPIError(users.onboardingDataResponse) && users.onboardingDataResponse?.status) {
      return users.onboardingDataResponse.status;
    }

    return OnboardingStatus.NotStarted;
  }

  get isOnboardingRequired() {
    return [OnboardingStatus.NotStarted, OnboardingStatus.InProgress].includes(this.onboardingStatus);
  }

  get isOnboardingPending() {
    return [OnboardingStatus.ReadyToSubmit, OnboardingStatus.InReview].includes(this.onboardingStatus);
  }

  // get dashboardToltalCount() {
  //   return dashboard.dashboardToltalCount;
  // }

  get recentTransactions() {
    const filtered =
      this.allTransactions.length <= 7
        ? this.allTransactions
        : this.allTransactions.slice(0, 7);
    return filtered;
  }
  compareValues(key, order = "asc") {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
      const comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }
  sort(field) {
    if (this.currentSort === field) {
      this.currentSortDir = this.currentSortDir === "desc" ? "asc" : "desc";
    } else {
      this.currentSort = field;
      this.currentSortDir = "desc";
    }
  }
  sortIcon(field) {
    return this.currentSort === field && this.currentSortDir === "desc"
      ? "arrow_drop_down"
      : "arrow_drop_up";
  }
}
</script>















































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { BIconPlusCircle, BIconXCircle } from "bootstrap-vue";
import SelectState from "@/components/SelectState.vue";
import SelectCountry from "@/components/SelectCountry.vue";
import File from "@/components/onboarding/File.vue";
import onboarding from "@/store/modules/onboarding";
import { uploadFiles } from "@/helpers/files";
import csc from "country-state-city";

@Component({
  components: {
    SelectState, SelectCountry, BIconPlusCircle, BIconXCircle, File,
  },
})
export default class Incorporation extends Vue {
  @Prop() submit: (saveOnly: boolean) => void;
  @Prop() deleteFile: (file: any) => void;

  onboarding = onboarding;
  country_list = [];
  state_list = [];
  documents = [];

  created() {
    this.state_list = [
      {isoCode: "None", name: "None"},
      {isoCode: "All", name: "All"},
      ...csc.getStatesOfCountry("US"),
    ];
    const countries = csc.getAllCountries().filter((country) => country.isoCode !== "US");
    this.country_list = [{isoCode: "None", name: "None"}, ...countries];
  }
  async uploadDocuments(e) {
    this.documents = Array.from(e.target.files);
    await uploadFiles(
      this.documents,
      onboarding.incorporationDetails.documentsIds.checklistItemId,
      onboarding.updateFiles,
      onboarding.updateIncorporationDocumentsIds,
      this.submit,
    );
  }
  addCountry() {
    onboarding.addIncorporationCountry("None");
  }
  addState() {
    onboarding.addIncorporationState("None");
  }
  changeState(index, value) {
    onboarding.updateIncorporationStates({ index, value });
  }
  changeCountry(index, value) {
    // this.state_list[index] = csc.getStatesOfCountry(value);
    onboarding.updateIncorporationCountries({ index, value });
  }
  deleteState(index) {
    onboarding.deleteIncorporationState(index);
  }
  deleteCountry(index) {
    onboarding.deleteIncorporationCountry(index);
  }
}

<template >
  <div class="accordion" role="tablist">
    <TransactionId :key="title" :title="title" @data="transactionId" />
<StatusFilter :key="title" :selected="selected" :title="title" @data="statusData" />
<SenderFilter :key="title" :title="title" @data="senderData" />
<BeneficiaryFilter :key="title" :title="title" @data="beneficiaryData" />
<QuotesFilter :key="title" :title="title" @data="quotesData" />
<DateFilter :key="selected" :selected="selected" :title="title" @data="dateData" />
    <div class="row justify-content-between">
      <div>
        <button type="button" class="btn btnCancel mr-5 resetFilter"  @click="clearFilters()">Reset Filter</button>
      </div>
      <div>
        <button type="button" class="btn btnCancel ml-5 mr-5" @click="closeModal()">Cancel</button>
        <button type="button" class="btn btn-green reg-btnCredentials  ml-5 mr-5" style="float:right;"
          @click="applyFilters()">Apply Filters</button>
      </div>
    </div>


  </div>
</template>
 
 
<script>
import { Vue, Component, Props } from "vue-property-decorator";
import StatusFilter from "@/components/filters/StatusFilter.vue";
import SenderFilter from "@/components/filters/SenderFilter.vue";
import BeneficiaryFilter from "@/components/filters/BeneficiaryFilter.vue";
import QuotesFilter from "@/components/filters/QuotesFilter.vue";
import DateFilter from "@/components/filters/DateFilter.vue";
import TransactionId from "@/components/filters/TransactionId.vue";

export default {
  name: "AdvancedFilter",
  props: ["data"],
  components: { StatusFilter, SenderFilter, BeneficiaryFilter, QuotesFilter, DateFilter, TransactionId },
  data() {
    return {
      isRangeVisible: false,
      filterArray: [],
      refreshTable: [{}],
      title: "",
      selected: undefined,
    };
  },
  watch: {

  },
  methods: {
    filterBy(type) {
      this.isRangeVisible = true;
      document.getelementById(type).style.background = "red";
    },
    senderData(value) {
      this.filterArray[0] = value[0];
      this.refreshTable = this.filterArray;
    },
    beneficiaryData(value) {
      this.filterArray[2] = value[0];
      this.refreshTable = this.filterArray;
    },
    statusData(value) {
      this.filterArray[1] = value;
      this.refreshTable = this.filterArray;
    },
    quotesData(value) {
      this.filterArray[3] = value[0];
      this.refreshTable = this.filterArray;
    },
    dateData(value) {
      this.filterArray[4] = value[0];
      this.refreshTable = this.filterArray;
    },
    transactionId(value) {
      this.filterArray[5] = value[0];
      this.refreshTable = this.filterArray;
    },
    applyFilters() {
      this.$emit("refreshTable");
      const timeOutms = 1000;
      setTimeout(() => {
        const resultObject = this.refreshTable.reduce(function(result, currentObject) {
          for (const key in currentObject) {
            if (currentObject.hasOwnProperty(key)) {
              result[key] = currentObject[key];
            }
          }
          return result;
        }, {});
        /*          */
        this.$emit("refresh", resultObject);
      }, timeOutms);
    },
    clearFilters() {
  this.title = " ";
  this.selected = undefined;
  const timeOutms = 300;
  setTimeout(() => {
    this.$nextTick(() => {
      this.title = undefined;
    });
    this.$emit("refreshTable");
  }, timeOutms);
},
    closeModal() {
        this.$emit("closeModal");
    },
  },
};
</script>
import {
    VuexModule,
    Module,
    getModule,
    MutationAction,
  } from "vuex-module-decorators";
import store from "@/store";
import {
    Vendor,
    PaymentAPIError,

  } from "../models/vendors";
import { getVendors, addVendors, editVendors, deleteVendors } from "../apis/vendors";

import _ from "lodash";

@Module({
    namespaced: true,
    name: "vendors",
    store,
    dynamic: true,
  })
  class VendorsModule extends VuexModule {

    vendor: Vendor = undefined;
    vendors: Vendor[] = [];
    getVendorsResponse: Vendor[] | PaymentAPIError = [];
    createVendorResponse: object = {};

    @MutationAction({ mutate: ["vendors", "getVendorsResponse"] })
    async getVendors() {
      const getVendorsResponse: Vendor[] | PaymentAPIError = await getVendors(localStorage.getItem("partner_id"));
      let vendors;
      if (Array.isArray(getVendorsResponse)) {
        vendors = getVendorsResponse;
      }
      return { vendors, getVendorsResponse };
    }

    @MutationAction({ mutate: ["createVendorResponse"] })
    async addVendors(data: any) {
      const createVendorResponse = await addVendors(localStorage.getItem("partner_id"), data);
      return { createVendorResponse } ;
    }

    @MutationAction({ mutate: ["createVendorResponse"] })
    async editVendors(data: any) {
      const createVendorResponse = await editVendors(localStorage.getItem("partner_id"), data);
      return { createVendorResponse } ;
    }

    @MutationAction({ mutate: ["createVendorResponse"] })
    async deleteVendors(data: any) {
      const createVendorResponse = await deleteVendors(localStorage.getItem("partner_id"), data);
      return { createVendorResponse } ;
    }

  }

export default getModule(VendorsModule);

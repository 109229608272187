








import { Vue, Component, Prop } from "vue-property-decorator";
import { BIconXCircle, BIconArrowClockwise } from "bootstrap-vue";

@Component({ components: { BIconXCircle, BIconArrowClockwise } })
export default class OnboardingItem extends Vue {
  @Prop() loading: boolean;
  @Prop() file: any;
  @Prop() deleteFile: (file: any) => void;
}

import Vue from "vue";
import {
  UserSuccess, PartnerSuccess, PaymentAPIError, PaymentAPIMessage, OnboardingSection,
} from "@/store/models/users";
import { BusinessInfo, Partner, PartnerUpdate } from "@/store/models/partnerSettings";
import { GatewaySuccess } from "@/store/models/gateways";
import { Meta } from "@/store/models/meta";
import users from "@/store/modules/users";

export function errorsToAssociative(validatorErrors: any[]): {} {
  return validatorErrors.reduce((acc, e: any) => {
    if (e.constraints) {
      acc[e.property] = Object.values(e.constraints);
      return acc;
    } else if (e.children.length) {
      acc[e.property] = errorsToAssociative(e.children);
      return acc;
    }
  }, {});
}

export function notifyUser(title: string, text: string, type: string) {
  Vue.notify({
    group: "alert",
    title,
    text,
    type,
  });
}

export function getChecklistItemIdByName(section: OnboardingSection, name: string) {
  const item = section.fields.find((e) => e.name === name);
  if (!item) {
    console.error("Unable to resolve checklist item " + name + " from the backend ui-spec - please check if there is a mismatch between the back and front ends");
  }
  return item?.id || "";
}

export function isTypeUserSuccess(object: any): object is UserSuccess {
  return "role" in object;
}

export function isTypePartnerSuccess(object: any): object is PartnerSuccess {
  return "partner_token" in object;
}

export function isTypeGatewaySuccess(object: any): object is GatewaySuccess {
  return "gateway_name" in object;
}

export function isTypePaymentAPIMessage(object: any): object is PaymentAPIMessage {
  return "message" in object && !("stack" in object);
}

export function isTypePaymentAPIError(object: any): object is PaymentAPIError {
  return "stack" in object || ("httpCode" in object && "code" in object);
}

export function isTypePartner(object: any): object is Partner {
  return "partner_gateways" in object;
}

export function isTypePartnerUpdate(object: any): object is PartnerUpdate {
  return "status_id" in object;
}

export function isTypeBusinessInfo(object: any): object is BusinessInfo {
  return "contact" in object;
}

export function isTypeMeta(object: any): object is Meta {
  return ("name" in object && "version" in object);
}

export function isUserRoleAdmin() {
  const adminRoles = ["PartnerAdmin", "SuperAdmin"];
  return adminRoles.indexOf(localStorage.getItem("user-role")) > -1;
}

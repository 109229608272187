import { paymentApi } from "./endpoint";

export async function dashboardCounts(gateway_partner_id: string) {
  const response = await (await paymentApi()).get(
    "/partner/" + gateway_partner_id + "/customers_instruments_count",
  );
  return response ? response.data : { customers: 0, instruments: 0 };
}

export async function getAvailableBalance() {
  const response = await (await paymentApi()).get(
    "/ledger/partner/us/usd/balance",
  );
  return response;
}

export async function getGraphData(search_start: number, search_end: number, from: number, size: number) {
  const response = await (await paymentApi()).get(
    "transaction-searches?start_seconds" + search_start + "&end_seconds=" + search_end + "&from=" + from + "&size=" + size,
  );
  return response;
}

export async function getDailyBalance() {
  const response = await (await paymentApi()).get(
    "/ledger/daily-partner/us/usd/balance",
  );
  return response;
}

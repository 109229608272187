
























































import { Vue, Component, Prop } from "vue-property-decorator";
import SelectState from "@/components/SelectState.vue";
import SelectCountry from "@/components/SelectCountry.vue";
import csc from "country-state-city";
import { Address } from "@/store/models/users";

@Component({ components: { SelectState, SelectCountry } })
export default class AddressForm extends Vue {
  @Prop() param: Address;
  @Prop() changeState: (value: string) => void;
  @Prop() changeCountry: (value: string) => void;
  // @Prop() changeZipCode: (value: string) => void;
  @Prop() index: number;

  country_list = [];
  state_list = [];
  created() {
    this.country_list = csc.getAllCountries();
    this.onChangeCountry(this.param.hasOwnProperty("country") ?  this.param.country : "US");
  }

  onChangeCountry(value: string) {
    this.state_list = csc.getStatesOfCountry(value);
    this.changeCountry(value);
  }

  onChangeState(value: string) {
    this.changeState(value);
  }

  // onChangeZipCode(value: string) {
  //   this.changeZipCode(value);
  // }
}

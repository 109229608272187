<template class="notificationsTemplate">
    <div class="col-md-4 col-lg-4 col-ms-12">
        <div class="card cardNotifications bg-white">
            <div class="card-body card-header-action bg-white">
                <div class="row">
                    <div class="col-9">
                        <h5 class="hk-pg-title bCredentials"> {{titleNotifications}}</h5>
                    </div>
                    <div class="col-3">
                        <div class="row">
                            <i class="ion ion-md-done-all font-16 ml-0 mt-5 mr-1"></i>
                            <p class="dashboardPercents font-12 mt-1">Mark all as read</p>
                        </div>
                    </div>
                </div>


            </div>
            <div class="card-body notif card-body-action border-top bg-white">
                <div class="pt-3" v-for="index in 10">
                    <p class="notifTitle">• Your Account Has Been Successfully Created...</p>
                    <div class="row">
                        <div class="col-9">
                            <p class="pl-1 ml-1 notifDescription">Your subscription is going to expire... </p>
                        </div>
                        <div class="col-3">
                            <p class="pl-1 ml-1">15m ago </p>
                        </div>
                    </div>

                    <div class="line pt-2"></div>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import { Vue, Component, Props } from "vue-property-decorator";
export default {
    name: "Notifications",
    props: ["titleNotifications"],
  };
</script>
<template >
    <b-card no-body class="mb-3 accordionsCard">
        <b-card-header header-tag="header" class="p-1 ridge" role="tab">
            <b-button block v-b-toggle.accordion-5 class="accordions text-left">Date</b-button>
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <div class="input-group mb-3">
                    <input :v-model="selected" :value="title" @change="onInputChange" id="date" type="date" class="form-control"
                        placeholder="mm-dd-yyyy" />
                </div>
                <p class="p-1 range">Range</p>
                <div class="row" v-if="isRangeVisible">
                    <div class="col-6">
                        <label for="startDate">Select start date</label>
                        <div class="input-group mb-3">
                            <input :v-model="selected" :value="title" @change="onInputChange" id="from" type="date" class="form-control"
                                placeholder="Select start date" />
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="endDate">Select end date</label>
                        <div class="input-group mb-3">
                            <input :v-model="selected" :value="title" @change="onInputChange" id="to" type="date" class="form-control" />
                        </div>
                    </div>
                </div>
                <p class="p-1 filterDate">Filter date type by: {{ this.nameSearch }}</p>

                <div class="row">
                    <div class="col-12">
                        <b-button-group>
                        <b-button  variant="outline-primary" @click="filterBy('All')" id="All" class="chip w-100">All</b-button>
                        <b-button variant="outline-primary" @click="filterBy('created_at')" id="Created" class="chip w-100">Created</b-button>
                        <b-button variant="outline-primary" @click="filterBy('updated_at')" id="Updated" class="chip w-100">Updated</b-button>
                    </b-button-group>
                    </div>
                 
                </div>

                <div class="row">
                    <div class="col-2">
                        <b-button  variant="outline-primary" @click="filterBy('All', 'All type of dates')" id="All" class="chip w-100">All</b-button>

                    </div>

                    <div class="col-2">
                        <b-button variant="outline-primary" @click="filterBy('created_at', 'Created')" id="Created" class="chip w-100">Created</b-button>
                    </div>

                    <div class="col-2">
                        <b-button variant="outline-primary" @click="filterBy('updated_at', 'Updated')" id="Updated" class="chip w-100">Updated</b-button>
                    </div>

                    <div class="col-2">
                        <b-button ref="Canceled" variant="outline-primary" @click="filterBy('canceled_at', 'Canceled')" id="Canceled"
                            class="chip w-100">Canceled</b-button>
                    </div>

                    <div class="col-2">
                        <b-button ref="Declined" variant="outline-primary" @click="filterBy('declined_at', 'Declined')" id="Declined"
                            class="chip w-100">Declined</b-button>
                    </div>
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>
    
    
<script>
import { Vue, Component, Props } from "vue-property-decorator";
export default {
    name: "DateFilter",
    props: {
        title: String,
        selected: String,
    },
    data() {
        return {
            isRangeVisible: false,
            typeSearch: "",
            nameSearch: "All type of dates",
            btnClass: "",
            initialArray: [{
                typeDate: {
                    type: "",
                    from: "",
                    to: "",
                    date: "",
                },
            }],
        };
    },
    methods: {
        filterBy(type, name) {
            if (type !== "All") {
                this.isRangeVisible = true;
            } else {
                this.isRangeVisible = false;
            }
            this.typeSearch = type;
            this.nameSearch = name;
            this.initialArray[0]["typeDate"]["type"] = type;
        },
        onInputChange(event) {
            this.initialArray[0]["typeDate"][event["target"]["id"]] = event["target"]["value"];
            this.$emit("data", this.initialArray);
        },
    },
};
</script>




























































import { Vue, Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import ConfirmationForm from "@/components/claim-money/ConfirmationForm.vue";
import claimMoney from "@/store/modules/claimMoney";
import { completeTransaction } from "@/store/apis/transactions";
import { isTypePaymentAPIError, notifyUser } from "@/helpers/template";

@Component({
  components: { TheMask, ConfirmationForm },
  beforeRouteEnter: StepTwo.preRoute,
  beforeRouteUpdate: StepTwo.preRoute,
})
export default class StepTwo extends Vue {
  static async preRoute(to, from, next) {
    if (from.name === "step-one") {
      next();
    } else {
      next({ name: "home" });
    }
  }

  @Prop() next: () => void;

  claimMoney = claimMoney;
  confirmation: boolean = false;

  submit() {
    this.confirmation = true;
    window.scrollTo(0, 0);
  }

  async complete() {
    const response = await completeTransaction(claimMoney.transaction.id, claimMoney.transaction.jwt, claimMoney.card, claimMoney.contacts.address);
    if (!isTypePaymentAPIError(response)) {
      claimMoney.clearTransaction();
      this.next();
    } else {
      if (response.details && response.details.length > 0) {
        for (const detail of response.details) {
          for (const key of Object.keys(detail.constraints)) {
            notifyUser(response.name, detail.constraints[key], "error");
          }
        }
      } else if (response.message) {
        notifyUser(response.name, response.message, "error");
      } else {
        notifyUser("Error", "Transaction Accept Failed", "error");
      }
    }
  }
}






















































import { Vue, Component, Prop } from "vue-property-decorator";
import { notifyUser, isTypePaymentAPIError } from "@/helpers/template";
import claimMoney from "@/store/modules/claimMoney";
import ConfirmationForm from "@/components/claim-money/ConfirmationForm.vue";
import { TheMask } from "vue-the-mask";

@Component({
  components: { ConfirmationForm, TheMask },
})
export default class StepTwoVGS extends Vue {
  @Prop() next: () => void;

  claimMoney = claimMoney;
  form = {} as any;
  confirmation: boolean = false;
  isValid: boolean = false;

  async mounted() {
    const params = this.$data.APP_CONFIG.vgs.sandbox ? "sandbox" : {};
    this.form = (window as any).VGSCollect.create(
      this.$data.APP_CONFIG.vgs.vaultId,
      params,
      (state) => {
        if (state.PAN && state.PAN.last4) {
          claimMoney.card.number = `**** **** **** ${state.PAN.last4}`;
          this.isValid = state.PAN.isValid;
        }
      },
    );

    const cardBrands = [];
    if (this.$data.APP_CONFIG.enableTabapayCardBrand) {
      cardBrands.push({
        type: "tabapayTest",
        pattern: /^9400/,
        format: /(\d{1,4})/g,
        length: [16],
        cvcLength: [3, 4],
        luhn: false,
      });
    }

    this.form.field("#PAN .form-control", {
      type: "card-number",
      name: "PAN",
      successColor: "#4F8A10",
      errorColor: "#D8000C",
      validations: ["required", "validCardNumber"],
      addCardBrands: cardBrands,
    });
  }

  submit() {
    if (!this.isValid) { return; }
    this.confirmation = true;
  }

  async complete() {
    this.form.submit(
      `/transactions/${claimMoney.transaction.id}/complete`, {
        headers: { Authorization: `Bearer ${claimMoney.transaction.jwt}` },
        data: {
          name_on_card: claimMoney.card.nameOnCard,
          exp_date: claimMoney.card.expDate,
          zip: claimMoney.card.zip,
          address: {
            street: [claimMoney.contacts.address.line1, claimMoney.contacts.address.line2].filter(Boolean),
            city: claimMoney.contacts.address.city,
            state: claimMoney.contacts.address.state,
            country: claimMoney.contacts.address.country,
            postal: claimMoney.contacts.address.zip,
          },
        },
      },
      async (status, response) => {
        if (!isTypePaymentAPIError(response)) {
          claimMoney.clearTransaction();
          this.next();
        } else {
          if (response.details && response.details.length > 0) {
            for (const detail of response.details) {
              for (const key of Object.keys(detail.constraints)) {
                notifyUser(response.name, detail.constraints[key], "error");
              }
            }
          } else if (response.message) {
            notifyUser(response.name, response.message, "error");
          } else {
            notifyUser("Error", "Transaction Accept Failed", "error");
          }
        }
      },
    );
  }
}

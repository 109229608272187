




































































































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ConfirmationForm extends Vue {
  @Prop() submit: () => void;
  @Prop() claimMoney: object;
}

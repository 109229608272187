






























































































































import { Vue, Component } from "vue-property-decorator";
import VueModal from "@/components/VueModal.vue";
import users from "@/store/modules/users";
import {
  notifyUser,
  isTypeUserSuccess,
  isTypePaymentAPIError,
  errorsToAssociative,
} from "@/helpers/template";
import { UserProfile } from "@/store/models/users";
import FormElement from "@/components/FormElement.vue";
import { validateOrReject } from "class-validator";

@Component({ components: { VueModal, FormElement } })
export default class Profile extends Vue {
  isEditModalVisible = false;
  userProfile: UserProfile = new UserProfile();
  userProfileCurrent: UserProfile = new UserProfile();
  userProfileErrors = {};

  get user() {
    return users.profile;
  }

  get username() {
    return (users && users.username) || "Unnamed";
  }

  async onProfileUpdate() {
    try {
      await validateOrReject(this.userProfile);
      this.userProfileErrors = {};
    } catch (errors) {
      notifyUser(
        "Profile Update Failed",
        "Please correct the designated fields.",
        "error",
      );
      this.userProfileErrors = errorsToAssociative(errors);
      return;
    }
    users
      .updateProfile(this.userProfile)
      .then(() => {
        const response = users.updateProfileResponse;
        if (isTypeUserSuccess(response)) {
          this.isEditModalVisible = false;
          this.setUserProfile(response);
          notifyUser("Success", "Profile Updated", "success");
        } else if (isTypePaymentAPIError(response)) {
          notifyUser("Profile Update Failed", response.message, "error");
        }
      })
      .catch((error) => {
        notifyUser(
          "Profile Update Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  showEditModal() {
    this.isEditModalVisible = true;
  }

  closeEditModal() {
    this.isEditModalVisible = false;
    this.resetUserProfile();
  }

  setUserProfile(user) {
    this.userProfile = new UserProfile({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
    });
    Object.assign(this.userProfileCurrent, this.userProfile);
  }

  resetUserProfile() {
    Object.assign(this.userProfile, this.userProfileCurrent);
  }

  mounted() {
    this.setUserProfile(this.user);
  }
}

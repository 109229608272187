



































// CX-2609 fixme import is not working when running tests
import { Vue, Component, Props } from "vue-component-decorator";
@Component
export default class VueDropdown extends Vue {
  @Props()
  props = {
    open: false,
    emptyLabel: "Select",
    options: {
      type: Array,
      required: true,
    },
    divExtraClass: "",
    defaultOption: -1,
    anchorExtraClass: "",
    divStyle: "",
  };

  show = false;
  selectedOption = -1;

  mounted() {
    this.show = this.$props.open;
    this.selectedOption = this.$props.defaultOption;
  }

  created() {
    document.addEventListener("click", this.documentClick);
  }

  destroyed() {
    document.removeEventListener("click", this.documentClick);
  }

  documentClick(e) {
    const el = this.$refs.dropdownMenu;
    const target = e.target;
    if (el !== target) {
      this.show = false;
    }
  }

  handleClick(i) {
    this.selectedOption = i;
    this.show = false;
    this.$emit("onSelect", this.$props.options[i]);
  }

  get selectedValue() {
    return this.selectedOption >= 0
      ? this.$props.options[this.selectedOption].value
      : this.$props.emptyLabel;
  }
}








import { Vue, Component } from "vue-property-decorator";

@Component
export default class SendMoney extends Vue {
  steps = ["send", "send-review", "send-result"];

  next() {
    const currentRoute = this.$router.currentRoute.name;
    const currentRouteIndex = this.steps.findIndex((e) => e === currentRoute);
    const nextRoute = this.steps[currentRouteIndex + 1] || "home";
    this.$router.push({ name: nextRoute });
  }
}

<template>
  <div class="hk-pg-inner">
    <div class="container containerDashboard mt-xl-50 mt-30">
      <div class="hk-pg-header hk-pg-headerCredentials">
        <div class="row w-100 justify-content-between">
          <div class="col-6">
            <h4 class="hk-pg-title">Business Payments</h4>
          </div>
          <div class="col-6">
            <vue-stepper :steps="steps.length" :active="activeStep" v-model="activeStep" :visited="activeStep - 1">
              <div :visited="step.visited" v-for="(step, index) in steps" :key="index" :slot="`step-${index + 1}`"
                :class="{ 'visited': step.visited, 'active': index + 1 === activeStep }">
                <div class="v-step not-clickable" :disabled="step.disabled" tabindex="-1">
                  <p>{{ step.label }}</p>
                </div>
              </div>
            </vue-stepper>
          </div>
        </div>
      </div>
      <div v-if="activeStep === 1">
        <Table :tableData="tableData" :showCheckbox="showCheckbox" @selectedRow="onRowSelected" ref="table" />
      </div>
      <div v-if="activeStep === 2">
        <BusinessForm ref="getQuote" :tsxInformation="tsxInformation" />
      </div>
      <div v-if="activeStep === 3">
        <BusinessCheck ref="getAcceptance" :tsxInformation="tsxInformation" />
      </div>
      <div v-if="activeStep === 4">
        <BusinessSuccess :tsxInformation="tsxInformation" @newTransaction="onNewTransaction" />
      </div>
      <div v-if="activeStep === 1" class="w-100 mt-50"
        style="   justify-content: space-evenly; display: flex;align-items: center;">
        <div style="width: 90px !important;"></div>
        <button class="btn btn-primary" :disabled="disabledButton"
          @click="nextStep()">Proceed</button>
      </div>
      <div v-if="activeStep === 2" class="w-100 mt-50"
        style="   justify-content: space-evenly;display: flex;align-items: center;">
        <button class="btn btn-primary" @click="previousStep()">previous</button>
        <button v-if="loading === false" class="btn btn-primary" :disabled="activeStep === steps.length"
          @click="nextStep()">Proceed</button>
        <button v-if="loading === true" disabled="true" class="btn btn-primary">Loading</button>
      </div>
      <div v-if="activeStep === 3" class="w-100 mt-50"
        style="   justify-content: space-evenly;display: flex;align-items: center;">
        <button class="btn btn-primary" @click="previousStep()">previous</button>
        <button class="btn btn-primary" :disabled="activeStep === steps.length || isLoadingAcceptance" @click="nextStep()">
          <b-spinner small class="mr-2" v-if="isLoadingAcceptance"></b-spinner>
          Confirm and Send
          Money</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import { scheduleOptions } from "@/store/constants";
import Datepicker from "vuejs-datepicker";
import { tableStatus } from "@/store/constants";
import vendors from "@/store/modules/vendors";
import VueDropdown from "@/components/VueDropdown.vue";
import {
  isUserRoleAdmin,
  errorsToAssociative,
} from "@/helpers/template";
import { isTypePaymentAPIError, notifyUser } from "../helpers/template";
import "datatables.net-bs4";
import Table from "@/components/Table.vue";
import VueStepper from "vue-stepper-component";
import BusinessForm from "@/views/BusinessForm";
import BusinessCheck from "@/views/BusinessCheck";
import BusinessSuccess from "@/views/BusinessSuccess";
import partnerSettings from "@/store/modules/partnerSettings";
import csc from "country-state-city";

@Component({ components: { VueDropdown, Datepicker, Table, VueStepper, BusinessForm, BusinessCheck, BusinessSuccess } })
export default class BusinessPayments extends Vue {
  currentSort = "created_at";
  currentSortDir = "desc";
  selectedUsers = "";
  disabledButton = true;
  isLoadingAcceptance =  false;
  allTransactions = [];
  countryList = [];
  tsxInformation = {
    vendor: [],
    quote: [],
  };
  loading = false;
  showCheckbox = true;
  steps = [
    { title: "Step 1", icon: "fa-check-circle", label: "Select Vendor", isVisited: false },
    { title: "Step 2", icon: "fa-check-circle", label: "Payment Info", isVisited: false },
    { title: "Step 3", icon: "fa-check-circle", label: "Review", isVisited: false },
    { title: "Step 4", icon: "fa-check-circle", label: "Confirmation", isVisited: false },
  ];
  activeStep = 1;
  tableData = {
    url: "BusinessPayment",
    header: [
      {
        key: "",
      },
      {
        key: "checkmark",
      },
      {
        key: "vendor_name",
        sortable: true,
      },
      {
        key: "phone",
        sortable: true,
      },
      {
        key: "email",
        sortable: true,
      },
      {
        key: "",
      },
    ],
    data: [],
  };

  mounted() {
    this.getBusinessInfo();
  }

  created() {
    this.getVendors();
  }

  getVendors() {
    vendors
      .getVendors()
      .then(() => {
        const response = vendors.getVendorsResponse;
        if (response instanceof Array) {
          response.forEach((element) => {
            const country = element.address.country;
            this.countryList = csc.getStatesOfCountry(country);
            for (const states of this.countryList) {
              if (states.isoCode === element.address.state) {
                element.address.state = states.name;
              }
            }
          });
          this.tableData.data = response;
        }
      })
      .catch((error) => {
        notifyUser(
          "Users List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  getBusinessInfo() {
    partnerSettings
      .getBusinessInfo()
      .then(() => {
        const response = partnerSettings.getBIResponse;
        const response2 = partnerSettings.getPartnerResponse;
        this.tsxInformation = response;
      })
      .catch((error) => {
        notifyUser(
          "Business Info Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  onRowSelected(row) {
    this.selectedUsers = row;
    this.disabledButton = false;
  }
  async nextStep() {
    if (this.activeStep === 1) {
      this.disabledButton = true;
    }
    if (this.activeStep === 2) {
      const result = this.$refs.getQuote.checkAmount();
      if (!result) {
        return;
      }
    }
    if (this.activeStep === 3) {
      this.isLoadingAcceptance = true;
      const resultAcceptance = await this.$refs.getAcceptance.getAcceptance();
      this.isLoadingAcceptance = false;
      if (!resultAcceptance.created_at) {
        notifyUser(
          "Acceptance Error",
          "Unknown error. Please try again later.",
          "error",
        );
        return;
      }
    }
    this.activeStep++;
    if (this.activeStep === 2) {
      if (!this.tsxInformation.vendor) {
        this.$set(this.tsxInformation, "vendor", []);
        this.tsxInformation.vendor[0] = this.selectedUsers;
        this.$set(this.tsxInformation, "quote", []);
      } else if (this.selectedUsers.id !== this.tsxInformation.vendor[0].id) {
        this.tsxInformation.vendor[0] = this.selectedUsers;
        this.$set(this.tsxInformation, "quote", []);
      }
    } else if (this.activeStep === 3) {
      this.loading = false;
    } else if (this.activeStep === 4) {

    }
    if (this.activeStep > 0) {
      this.steps[this.activeStep - 1].isVisited = true;
    }
  }
  onNewTransaction() {
    this.activeStep = 1;
    this.$set(this.tsxInformation, "quote", []);
    this.$set(this.tsxInformation, "vendor", []);
  }
  previousStep() {
  this.activeStep--;
  if (this.activeStep > 1) {
    this.steps[this.activeStep - 1].visited = true;
  } else {
    this.disabledButton = false;
    this.$nextTick(() => {
      this.$refs.table.setInitialSelectedRows(this.selectedUsers);
    });
  }
}
  finish() {
    alert("Form submitted!");
  }
}
</script>

<style>
.vue-step.is-visited {
  background-color: green;
  color: white;
}
</style>

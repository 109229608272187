<template > 
     <div class="card cardD">
        <div class="card-title detailTitle">
        <p class="ml-3 pt-2">Card Details </p>
        </div>
        <div class="card-body  card-body-action">
            <p class="detailTitles ml-0 pl-0 mt-0 ">Card Number:</p>
            <p class="detailDescription">8570-xxxx-xxxx-xx34</p>

            <p class="detailTitles">Card Holder Name:</p>
            <p class="detailDescription">Jonathan Paul</p>

          
  
        </div>
    </div>
       
  </template>
  
  
  <script>
  import { Vue, Component, Props } from "vue-property-decorator";
  export default {
    name: "CardDetail",
    props: ["data"],
  };
  </script>
<template>
  <div class="hk-pg-inner">
    <div class="container containerDashboard mt-xl-50 mt-30">
      <div class="hk-pg-header hk-pg-headerCredentials">
        <div>
          <h4 class="hk-pg-title">Payment Info</h4>
          <p class="hk-pg-subtitle">
            Please complete and review information and execute the transaction.
          </p>
        </div>
      </div>
      <div class="col-12">
        <div class="card cardD">
          <div class="card-title ">
            <p class="ml-3 pt-2">Transfer Information</p>
          </div>
          <div class="card-body pt-0 card-body-action">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label class="inputLabel" for="method">Delivery Method <span class="text-danger"> * </span></label>
                <div class="input-group mb-3">
                  <select v-model="delivery_type" @change="onChangeMethod" id="method" class="form-control">
                    <option selected value="PickAnything">Pick a method</option>
                    <option v-for="(methods, index) in deliveryList" :value="methods" :key="index">{{ methods }}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">Sender Currency</label>
                <div class="input-group mb-3">
                  <input readonly value="USD" id="method" type="text" class="form-control" placeholder="" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">Vendor Currency</label>
                <div class="input-group mb-3">
                  <input readonly :value="vendorCurrency.currency" id="method" type="text" class="form-control"
                    placeholder="" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="mode">Amount Mode <span class="text-danger"> * </span></label>
                <div class="input-group mb-3">
                  <select v-model="sender_mode" @change="onChangeMode" class="form-control" id="mode" required>
                    <option value="Sender">Sender</option>
                    <option value="Beneficiary">Vendor</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">{{ amountLabel }} <span class="text-danger"> * </span></label>
                <div class="input-group mb-3">
                  <input type="text" v-on:blur="getTaxes" v-model="amount" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">Money transfer fee</label>
                <div class="input-group mb-3">
                  <input readonly class="form-control" type="text" v-model="fee" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">FX Rate</label>
                <div class="input-group mb-3">
                  <input readonly v-model="fxR" id="method" type="text" class="form-control" placeholder="" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">Location</label>
                <div class="input-group mb-3">
                  <input readonly :value="[locationName]" id="method" type="text" class="form-control" placeholder="" />
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">Address</label>
                <div class="input-group mb-3">
                  <input readonly :value="[locationAddress]" id="method" type="text" class="form-control"
                    placeholder="" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" for="method">Invoice Number</label>
                <div class="input-group mb-3">
                  <input id="method" type="text" class="form-control" placeholder="Enter invoice number here" />
                </div>
              </div>

              <div v-for="(field, index) in filteredFieldsAccept" :key="index" class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" :for="field.id">{{
                  field.label
                }}</label>
                <div class="input-group mb-3">
                  <input v-model="fieldsAccept[field.id]" :id="field.id" :type="field.type" :class="field.class"
                    placeholder="" />
                </div>
              </div>
              <div v-for="(field, index) in filteredFieldsCreate" :key="index" class="col-lg-6 col-md-6 col-sm-12">
                <label class="inputLabel" :for="field.id">{{
                  field.label
                }}</label>
                <div class="input-group mb-3">
                  <input v-model="fieldsCreate[field.id]" :id="field.id" :type="field.type" :class="field.class"
                    placeholder="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h4>Sender Information</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="detailTitles">Sender First Name</p>
            <p class="detailDescription">{{ firstName }}</p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Sender Last Name</p>
            <p class="detailDescription">{{ lastName }}</p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Sender Email</p>
            <p class="detailDescription">{{ userEmail }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="detailTitles">Sender Phone Number</p>
            <p class="detailDescription">{{ userPhone }}</p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Sender Address</p>
            <p class="detailDescription">{{ tsxInformation.address }}</p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Sender City</p>
            <p class="detailDescription">{{ tsxInformation.city }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <p class="detailTitles">Sender State</p>
            <p class="detailDescription">{{ tsxInformation.state }}</p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Sender Zip</p>
            <p class="detailDescription">{{ tsxInformation.zip }}</p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Sender Country</p>
            <p class="detailDescription">{{ tsxInformation.country }}</p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h4>Vendor Information</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="detailTitles">Vendor First Name</p>
            <p class="detailDescription">
              {{ tsxInformation.vendor[0].first_name }}
            </p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Vendor Last Name</p>
            <p class="detailDescription">
              {{ tsxInformation.vendor[0].last_name }}
            </p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Vendor Email</p>
            <p class="detailDescription">{{ tsxInformation.vendor[0].email }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="detailTitles">Vendor Phone Number</p>
            <p class="detailDescription">{{ tsxInformation.vendor[0].phone }}</p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Vendor Address</p>
            <p class="detailDescription">
              {{ tsxInformation.vendor[0].address.street[0] }}
            </p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Vendor City</p>
            <p class="detailDescription">
              {{ tsxInformation.vendor[0].address.city }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <p class="detailTitles">Vendor State</p>
            <p class="detailDescription">
              {{ tsxInformation.vendor[0].address.state }}
            </p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Vendor Zip</p>
            <p class="detailDescription">
              {{ tsxInformation.vendor[0].address.postal }}
            </p>
          </div>
          <div class="col-3">
            <p class="detailTitles">Vendor Country</p>
            <p class="detailDescription">
              {{ tsxInformation.vendor[0].address.country }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import csc from "country-state-city";
import partner from "@/store/modules/partnerSettings";
import { notifyUser } from "../helpers/template";
import users from "@/store/modules/users";
import additionalFields from "../additionalFields.json";
export default {
  name: "BusinessForm",
  props: ["tsxInformation"],
  data() {
    return {
      filteredFieldsCreate: [],
      filteredFieldsAccept: [],
      deliveryList: [],
      fieldsCreate: {},
      fieldsAccept: {},
      amountLabel: "Amount to send in USD",
      delivery_type: "PickAnything",
      firstName: "",
      lastName: "",
      quoteOptionIdSaved: "",
      userEmail: "",
      userPhone: "",
      sender_mode: "Sender",
      senderAmount: "0",
      beneficiaryAmount: "0",
      partnerToken: "",
      totalFee: 0,
      locationAddress: "",
      locationName: "",
      vendorCurrency: "",
      balance: 0,
      amountVal: 0,
      fee: "0.00",
      fxR: "0.00",
      quote_query: [],
      feeResponse: [],
      quotesResponse: [],
    };
  },
  created() {
    this.getDeliveryTypes();
    if (this.$props.tsxInformation.quote.length > 0) {
      this.fxR = this.$props.tsxInformation.quote[1];
      this.fee = this.$props.tsxInformation.quote[2];
      this.locationName = this.$props.tsxInformation.quote[9];
      this.locationAddress = this.$props.tsxInformation.quote[8];
      this.sender_mode = this.$props.tsxInformation.quote[10];
      this.delivery_type = this.$props.tsxInformation.quote[12];
      if (this.$props.tsxInformation.quote[13]) {
        this.fieldsCreate = this.$props.tsxInformation.quote[13];
      }
      if (this.$props.tsxInformation.quote[14]) {
        this.fieldsAccept = this.$props.tsxInformation.quote[14];
      }
      if (this.sender_mode === "Sender") {
        this.amountLabel = "Amount to send in USD";
        this.amount = this.$props.tsxInformation.quote[4];
      } else {
        this.amountLabel =
          "Amount to receive in " + this.$props.tsxInformation.quote[7];
        this.amount = this.$props.tsxInformation.quote[3];
      }
    }
    (this.firstName = users.profile.first_name),
      (this.lastName = users.profile.last_name),
      (this.userEmail = users.profile.email),
      (this.userPhone = users.profile.phone),
      (this.vendorCurrency = csc.getCountryByCode(this.tsxInformation.vendor[0].address.country));
    partner
      .getPartner()
      .then(() => {
        const response = partner.getPartnerResponse;
        this.partnerToken = response.partner_token;
        if (this.$props.tsxInformation.quote.length > 0) {
          this.getTaxes();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    /*  this.totalFee = this.apiResponse.gateway_fee.amount * 1 + this.apiResponse.platform_fee.amount * 1; */
  },
  methods: {
    onChangeMode(value) {
      this.sender_mode = value.target.value;
      if (value.target.value === "Sender") {
        this.amountLabel = "Amount to send in USD";
      } else {
        this.amountLabel =
          "Amount to receive in " + this.vendorCurrency.currency;
      }
      this.getTaxes();
    },
    onChangeMethod(value) {
      this.delivery_type = value.target.value;
      if ((this.amountVal / 100).toFixed(2) > 0) {
        this.getTaxes();
      }
    },
    checkAmount() {
      if (this.amountVal / 100 > 0) {
        this.getQuote();
        return true;
      } else {
        notifyUser("Amount has to be higher than 0.");
        return false;
      }
    },
    async getDeliveryTypes() {
      this.vendorCurrency = csc.getCountryByCode(this.tsxInformation.vendor[0].address.country);
      const query = {
        country: this.tsxInformation.vendor[0].address.country,
        currency: this.vendorCurrency.currency,
      };
      await partner
        .onDeliveryList(query)
        .then(() => {
          const response = partner.onDeliveryListResponse;
          this.deliveryList = response;
        })
        .catch((error) => {
          notifyUser("There was an error creating your transaction.");
        });
    },
    getTaxes() {
      if (this.delivery_type === "" || this.delivery_type === "PickAnything") {
        notifyUser("You have to select a valid delivery method.");
      } else {
        const query = {
          delivery_type: this.delivery_type,
          sender_country_code: "US",
          sender_currency_code: "USD",
          [this.sender_mode.toLowerCase() + "_amount"]: (
            this.amountVal / 100
          ).toFixed(2),
          beneficiary_country_code: this.tsxInformation.vendor[0].address.country,
          beneficiary_currency_code: this.vendorCurrency.currency,
          token: this.partnerToken,
        };
        partner
          .onDiscoveryList(query)
          .then(() => {
            const response = partner.onDiscoveryListResponse;
            const acceptedAcceptFields =
              response[0].additional_fields_to_accept_quote || [];
            const acceptedCreateFields =
              response[0].additional_fields_to_create_quote || [];
            const filteredFieldsAccept = Object.keys(additionalFields.additional_fields_to_accept_quote).filter((field) => acceptedAcceptFields.includes(field));
            const filteredFieldsCreate = Object.keys(additionalFields.additional_fields_to_create_quote).filter((field) => acceptedCreateFields.includes(field));
            if (filteredFieldsAccept.length > 0) {
              this.filteredFieldsAccept = filteredFieldsAccept.map((field) => additionalFields.additional_fields_to_accept_quote[field]);
            }
            if (filteredFieldsCreate.length > 0) {
              this.filteredFieldsCreate = filteredFieldsCreate.map((field) => additionalFields.additional_fields_to_create_quote[field]);
            }
            this.senderAmount = response[0].sender_amount;
            this.beneficiaryAmount = response[0].beneficiary_amount;
            this.fee =
              response[0].gateway_fee.amount * 1 +
              response[0].platform_fee.amount * 1;
            this.fxR = response[0].fx_rate.rate * 1;
            this.fee = this.fee
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              .padStart(4, "0.00");
            this.fxR = this.fxR
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              .padStart(4, "0.00");
            this.locationAddress = "N/A";
            if (response[0].location.address !== undefined) {
              if (response[0].location.address.country !== undefined) {
                this.locationAddress = response[0].location.address.country;
                if (response[0].location.address.state !== undefined) {
                  this.locationAddress =
                    response[0].location.address.state +
                    " - " +
                    this.locationAddress;
                } else if (response[0].location.address.city !== undefined) {
                  this.locationAddress =
                    response[0].location.address.city +
                    " - " +
                    this.locationAddress;
                } else if (response[0].location.address.street !== undefined) {
                  this.locationAddress =
                    response[0].location.address.street[0] +
                    " - " +
                    this.locationAddress;
                }
              }
            }

            if (
              response[0].location.name === null ||
              response[0].location.name === undefined
            ) {
              this.locationName = "N/A";
            } else {
              this.locationName = response[0].location.name;
            }
            this.feeResponse = response;
          })
          .catch((error) => {
            notifyUser("There was an error getting your rates.");
          });
      }
    },
    getQuote() {
      const amount = this.amountVal / 100;
      const response = {};
      if (this.$props.tsxInformation.quote.length > 0) {
        this.$props.tsxInformation.quote = [];
      }
      this.quote_query = {
        amount_mode: this.sender_mode,
        sender_country_code: "US",
        sender_currency_code: "USD",
        quote_option_id: this.feeResponse[0].option_id,
        sender_amount: this.senderAmount,
        beneficiary_amount: this.beneficiaryAmount,
        beneficiary_country_code: this.tsxInformation.vendor[0].address.country,
        beneficiary_currency_code: this.vendorCurrency.currency,
        beneficiary_first_name: this.tsxInformation.vendor[0].first_name,
        beneficiary_last_name: this.tsxInformation.vendor[0].last_name,
        sender_first_name: this.firstName,
        sender_last_name: this.lastName,
        ...this.fieldsCreate,
      };
      partner
        .onQuote(this.quote_query)
        .then(() => {
          const response2 = partner.onQuoteResponse;
          this.quotesResponse = response;
          const sums = response2.sender_amount * 1 + this.fee * 1;
          const totalCharge = sums
            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .padStart(4, "0.00");

          const benefAmount = (response2.beneficiary_amount * 1)
            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .padStart(4, "0.00");

          const sendrAmount = (response2.sender_amount * 1)
            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .padStart(4, "0.00");
          this.tsxInformation.quote.push(response2);
          this.tsxInformation.quote.push(this.fxR);
          this.tsxInformation.quote.push(this.fee);
          this.tsxInformation.quote.push(benefAmount);
          this.tsxInformation.quote.push(sendrAmount);
          this.tsxInformation.quote.push(totalCharge);
          this.tsxInformation.quote.push(response2.sender_currency_code);
          this.tsxInformation.quote.push(response2.beneficiary_currency_code);
          this.tsxInformation.quote.push(this.locationAddress);
          this.tsxInformation.quote.push(this.locationName);
          this.tsxInformation.quote.push(this.sender_mode);
          this.tsxInformation.quote.push(this.feeResponse[0].option_id);
          this.tsxInformation.quote.push(this.delivery_type);
          this.tsxInformation.quote.push(this.fieldsCreate);
          this.tsxInformation.quote.push(this.fieldsAccept);
        })
        .catch((error) => {
          notifyUser("There was an error creating your transaction.");
        });
    },
    getter(value) {
      return (value / 100)
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .padStart(4, "0.00");
    },
  },
  computed: {
    amount: {
      // Assuming you are in the same country as toLocalString(en-US), this should work:
      get() {
        return this.getter(this.amountVal);
      },
      // setter
      set(newValue) {
        // This is a cheeky regex, that can remove any non-digit character:
        newValue = parseInt(newValue.replace(/\D+/g, ""));
        // Handle edge cases
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.amountVal = newValue;
      },
    },
  },
};

/* defineExpose({
    getQuote
}); */
</script>

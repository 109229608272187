


















































import { Vue, Component, Watch } from "vue-property-decorator";
import users from "@/store/modules/users";
import { notifyUser, errorsToAssociative } from "@/helpers/template";
import { UserLogin } from "@/store/models/users";
import FormElement from "@/components/FormElement.vue";
import PublicLayout from "@/components/PublicLayout.vue";
import { validateOrReject } from "class-validator";
import { paymentApi } from "@/store/apis/endpoint";
import { togglePasswordField, setPasswordIcon } from "@/helpers/forms";

const EMAIL_VERIFICATION_ERROR = 1001;

@Component({components: { FormElement, PublicLayout }})
export default class Login extends Vue {
  userLogin: UserLogin = new UserLogin();
  userLoginErrors = {};
  showVerificationResend = false;
  passwordFieldType = "password";
  passwordIconURL = "";

  togglePasswordField(e) {
    const toggled = togglePasswordField(this.passwordFieldType);
    this.passwordFieldType = toggled.type;
    this.setPasswordIcon(toggled.name);
  }

  setPasswordIcon(name) {
    this.passwordIconURL = setPasswordIcon(name);
  }

  async resendVerificationEmail() {
    try {
      const result = (await paymentApi()).post("/user/verification/code/resend", {emailOrPhone: this.userLogin.email});
      notifyUser("Verification Email Sent", "Click the link sent to your email.", "success");
      this.showVerificationResend = false;
    } catch (e) {
      notifyUser("", `Unable to send: ${e.response.data.message}`, "error");
    }
  }

  async login() {
    try {
      await validateOrReject(this.userLogin);
      this.userLoginErrors = {};
    } catch (errors) {
      this.userLoginErrors = errorsToAssociative(errors);
      notifyUser("", "Login invalid, check form", "error");
      return;
    }

    try {
      const result = await users.login(this.userLogin);
    } catch (e) {
      notifyUser("Login Failed", "Unable to login.", "error");
    }
    if (this.isLoggedIn) {
      notifyUser("Success", "Login Successful", "success");
      this.$router.push("/dashboard");
    } else {
      if (users.loginResponse["data"]) {
        if (users.loginResponse["data"]["code"] && users.loginResponse["data"]["code"] === EMAIL_VERIFICATION_ERROR) {
          notifyUser("Login Failed", "Please click the link in your verification email.", "error");
          this.showVerificationResend = true;
        } else {
          notifyUser("Login Failed", users.loginResponse["data"].message, "error");
        }
      } else {
        notifyUser("Login Failed", "Login error.", "error");
      }
    }
  }

  handleInput(e) {
    return e.target.value;
  }

  get isLoggedIn() {
    return (users && users.isLoggedIn) || false;
  }

  get useremail() {
    return (users && users.useremail) || "";
  }

  created() {
    this.setPasswordIcon("eye-off");
  }
}

<template >
  <div>
    <div>
      <div v-if="showLoading" class="justify-content-center align-items-center text-center">
        <div class="row justify-content-center align-items-center text-center">
          Getting transaction details
        </div>
        <div class="row justify-content-center align-items-center text-center">
          <b-spinner small type="grow" label="Spinning"></b-spinner>
          <b-spinner small type="grow" label="Spinning"></b-spinner>
          <b-spinner small type="grow" label="Spinning"></b-spinner>
        </div>

      </div>

      <div v-if="!showLoading" class="row">
        <div class="col-md-12 pr-0">
          <ExpensesAddress :details="details" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Vue, Component, Props } from "vue-property-decorator";
import ExpensesAddress from "@/components/ExpensesAddress.vue";
import CardDetail from "@/components/CardDetail.vue";
import transactions from "@/store/modules/transactions";
import claimMoney from "@/store/modules/claimMoney";
import { getTransactionDetails } from "@/store/apis/transactions";

export default {
  name: "TransactionDetails",
  props: ["data"],
  components: { ExpensesAddress, CardDetail },
  data() {
    return {
      details: {
        data: [],
      },
      showLoading: true,
    };
  },
  async mounted() {
    const response = await getTransactionDetails(this.data.id, claimMoney.transaction.jwt);
    this.details = response;
    this.showLoading = false;
    /*  if (!isTypePaymentAPIError(response)) {
       claimMoney.setTransaction(response as TransactionPredebited);
     }
     this.isLoading = false; */
  },

};
</script>
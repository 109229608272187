import {
  VuexModule, Module, getModule, Mutation,
} from "vuex-module-decorators";
import { TransactionPredebited } from "@/store/models/transactions";
import store from "@/store";

@Module({
  namespaced: true,
  name: "claimMoney",
  store,
  dynamic: true,
})
class ClaimMoneyModule extends VuexModule {
  contacts = { address: { state: "Alabama", country: "United States", line1: "", line2: "", city: "", zip: "" } };
  card: { number?: string, zip?: string, expDate?: string, nameOnCard?: string } = {};
  transaction = { id: "", jwt: "" };
  amount = 0;

  @Mutation
  updateState(payload: string | undefined) {
    this.contacts.address.state = payload;
  }

  @Mutation
  updateCountry(payload: string | undefined) {
    this.contacts.address.country = payload;
  }

  @Mutation
  setTransaction(payload: TransactionPredebited) {
    this.transaction = payload;
    this.amount = payload.sender_amount;
  }

  @Mutation
  clearTransaction() {
    this.transaction = { id: "", jwt: "" };
  }
}

export default getModule(ClaimMoneyModule);

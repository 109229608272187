import {
  VuexModule,
  Module,
  getModule,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import { getAvailableBalance } from "../apis/dashboard";
import { dashboardCounts } from "../apis/dashboard";
import { getDailyBalance } from "../apis/dashboard";
import { getGraphData } from "../apis/dashboard";
@Module({
  namespaced: true,
  name: "dashboard",
  store,
  dynamic: true,
})
class DashboardModule extends VuexModule {
  totalCounts = {
    customers: 0,
    instruments: 0,
  };
  availableBalance: object = {};
  dailyBalance: object = {};
  graphData: object = {};

  get dashboardToltalCount() {
    return this.totalCounts;
  }

  @MutationAction({ mutate: ["totalCounts"] })
  async onDashboardCounts() {
    const totalCounts = await dashboardCounts(
      localStorage.getItem("partner_id"),
    );
    return { totalCounts };
  }

  @MutationAction({ mutate: ["availableBalance"] })
  async onAvailableBalance() {
    const availableBalance = await getAvailableBalance();
    return { availableBalance };
  }

  @MutationAction({ mutate: ["dailyBalance"] })
  async onDailyBalance() {
    const dailyBalance = await getDailyBalance();
    return { dailyBalance };
  }

  @MutationAction({ mutate: ["graphData"] })
  async onGraphData(search_start, search_end, from, size) {
    const graphData = await getGraphData(search_start, search_end, from, size);
    return { graphData };
  }
}

export default getModule(DashboardModule);

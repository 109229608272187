import { paymentApi } from "./endpoint";
import { PaymentAPIError } from "@/store/models/partnerSettings";
import { Meta } from "@/store/models/meta";

export async function getMeta(): Promise<Meta | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).get("/meta");
    return response.data as Meta;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

<template>
  <div class="hk-pg-inner">
    <div class="container containerDashboard mt-xl-50 mt-30 justify-content-center align-items-center d-flex">
      <div class="col-8">
        <div class="card cardD" style="padding: 50px !important;">
          <div class="card-title ">
            <h4 class="hk-pg-title">Review</h4>
            <p class="hk-pg-subtitle">
              Please review the payment information and execute the transaction.
              This transaction will expire in approximately 30 minutes.
            </p>
          </div>
          <div class="card-body pt-0 card-body-action">
            <div class="row justify-content-between">
              <p class="detailTitles">Amount You'll Send</p>
              <p class="detailDescription">
                {{ tsxInformation.quote[4] }} {{ tsxInformation.quote[6] }}
              </p>
            </div>
            <hr />
            <div class="row justify-content-between">
              <p class="detailTitles">Money Transfer Fee</p>
              <p class="detailDescription">{{ tsxInformation.quote[2] }} USD</p>
            </div>
            <hr />
            <div class="row justify-content-between">
              <p class="detailTitles">Exchange Rate</p>
              <p class="detailDescription">
                {{ tsxInformation.quote[1] }} ( {{ tsxInformation.quote[6] }} to
                {{ tsxInformation.quote[7] }})
              </p>
            </div>
            <hr />
            <div class="row justify-content-between">
              <p class="detailTitles">Amount Vendor will receive</p>
              <p class="detailDescription">
                {{ tsxInformation.quote[3] }} {{ tsxInformation.quote[7] }}
              </p>
            </div>
            <hr />
            <div class="row justify-content-between">
              <h4>YOUR CHARGE</h4>
              <h4>
                {{ tsxInformation.quote[5] }} {{ tsxInformation.quote[6] }}
              </h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-center align-items-center d-flex">
          <p class="vendortitle">DATE AVAILABLE:</p>
          <p class="vendorsubtitle">
            Your vendor's funds will be available today, approximately 25
            minutes after you confirm the transfer.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import { scheduleOptions } from "@/store/constants";
import Datepicker from "vuejs-datepicker";
import { tableStatus } from "@/store/constants";
import VueDropdown from "@/components/VueDropdown.vue";
import { isTypePaymentAPIError, notifyUser } from "../helpers/template";
import partner from "@/store/modules/partnerSettings";

export default {
  name: "BusinessCheck",
  props: ["tsxInformation"],
  data() {
    return {
      query: [],
    };
  },
  methods: {
    async getAcceptance() {
      this.query = {
        sender: {
          first_name: this.tsxInformation.name,
          last_name: this.tsxInformation.lastname,
          email: this.tsxInformation.contact,
          phone: this.tsxInformation.phone,
          address: {
            street: [this.tsxInformation.address],
            city: this.tsxInformation.city,
            state: this.tsxInformation.state,
            postal: this.tsxInformation.zip,
            country: this.tsxInformation.country,
          },
        },
        beneficiary: {
          first_name: this.tsxInformation.vendor[0].first_name,
          last_name: this.tsxInformation.vendor[0].last_name,
          email: this.tsxInformation.vendor[0].email,
          phone: this.tsxInformation.vendor[0].phone,
          address: {
            street: [this.tsxInformation.vendor[0].address.street[0]],
            city: this.tsxInformation.vendor[0].address.city,
            state: this.tsxInformation.vendor[0].address.state,
            postal: this.tsxInformation.vendor[0].address.postal,
            country: this.tsxInformation.vendor[0].address.country,
          },
        },
        quote: this.tsxInformation.quote[0].id,
        ...this.tsxInformation.quote[14],
      };
      await partner.onQuoteAcceptance(this.query).catch((error) => {
        notifyUser("There was an error accepting this transaction.");
      });
      return partner.onQuoteAcceptanceResponse;
    },
  },
};
</script>

<template >
   <div class="input-group mb-3">
      <input :value="title" @change="onInputChange" id="id" type="text" class="form-control" placeholder="Transaction ID" />
    </div>
</template>
    
    
<script>
import { Vue, Component, Props } from "vue-property-decorator";
export default {
    name: "TransactionId",
    props: {
        title: String,
    },
    data() {
        return {
            initialArray: [{
                id: "",
            }],
        };
    },
    methods: {
        onInputChange(event) {
            this.initialArray[0][event["target"]["id"]] = event["target"]["value"];
            this.$emit("data", this.initialArray);
        },
    },
};
</script>
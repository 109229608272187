























import {Vue, Component, Watch} from "vue-property-decorator";
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";
import users from "@/store/modules/users";
import {Route} from "vue-router";

@Component({ components: { AppNavbar, AppFooter } })
export default class App extends Vue {
  isClaim = false;
  isTermsLayout = false;

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    this.$router.onReady(() => {
      this.setIsClaim();
      this.setIsTermsLayout();
    });
  }

  mounted() {
    if (
      localStorage.getItem("user-token") &&
      localStorage.getItem("user-email")
    ) {
      users.forceLogin().then(() => {
        if (this.isLoggedIn) {
          if (this.$router.currentRoute["path"] === "/login") {
            this.$router.push("/dashboard");
          }
        } else {
          this.$router.push("/login");
        }
      });
    }

    const setHeightWidth = () => {
      const windowHeight = window.innerHeight;
      const footerHeight = $(".hk-footer-wrap").outerHeight();
      $(".hk-pg-wrapper").css("min-height", windowHeight - footerHeight);
    };
    $(window).on("resize", () => {
      setHeightWidth();
    });
    $(window).trigger("resize");
  }

  get isLoggedIn() {
    return (users && users.isLoggedIn) || false;
  }

  setIsClaim() {
    this.isClaim = (this.$router.currentRoute.matched[0]?.name === "claim-money") || false;
  }

  setIsTermsLayout() {
    const pages = ["termsNConditions", "privacyPolicy"];
    this.isTermsLayout = (pages.indexOf(this.$router.currentRoute.matched[0]?.name) !== -1) || false;
  }
}

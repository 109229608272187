import { Module, VuexModule, getModule, MutationAction } from "vuex-module-decorators";
import store from "@/store";
import { Meta } from "@/store/models/meta";
import { PaymentAPIError } from "@/store/models/partnerSettings";
import { getMeta } from "@/store/apis/meta";

@Module({
  namespaced: true,
  name: "meta",
  store,
  dynamic: true,
})
class MetaModule extends VuexModule {
  response: Meta | PaymentAPIError = undefined;

  @MutationAction({ mutate: ["response"] })
  async getMeta() {
    const response: Meta | PaymentAPIError = await getMeta();
    return { response };
  }
}

export default getModule(MetaModule);

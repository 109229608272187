<template >
  <div class="row">
    <div class="col-12">
      <div class="card-title">
        <p class="pt-3 senderDetail">Beneficiary Details </p>
      </div>
    </div>
    <div class="col-12">
      <div class="card-body pt-0 pl-0 mt-0 card-body-action">
        <p class="detailTitles ml-0 pl-0 mt-0 ">Beneficiary name:</p>
        <p class="detailDescription">{{details.beneficiary_given_name}} {{details.beneficiary_family_name}}</p>

        <p class="detailTitles">Beneficiary address:</p>
        <p v-if="details.beneficiary_address" class="detailDescription"> {{details.beneficiary_address.street[0]}}</p>
        <p v-if="details.beneficiary_address" class="detailDescription"> {{details.beneficiary_address.city}} - {{details.beneficiary_address.state}} - {{details.beneficiary_address.postal}}, {{details.beneficiary_address.country}}</p>

        <p class="detailTitles">Beneficiary email:</p>
        <p class="detailDescription">{{details.beneficiary_email}}</p>

        <p class="detailTitles">Beneficiary number:</p>
        <p class="detailDescription">{{details.beneficiary_phone}}</p>

        <p class="detailTitles">Beneficiary ID:</p>
        <p class="detailDescription">{{details.beneficiary_id}}</p>

        <p class="detailTitles">Beneficiary currency code:</p>
        <p class="detailDescription">{{details.beneficiary_currency_code}}</p>

        <p class="detailTitles">Beneficiary country code:</p>
        <p class="detailDescription">{{details.beneficiary_country_code}}</p>

      </div>
    </div>
  </div>
</template>
  
  
<script>
import { Vue, Component, Props } from "vue-property-decorator";
import csc from "country-state-city";

export default {
  name: "BenefDetail",
  props: ["details"],
  data() {
        return {
            vendorState: "",
            countryList: "",
        };
    },
  created() {
        const country = this.$props.details.beneficiary_address.country;
        this.countryList = csc.getStatesOfCountry(country);
        for (const states of this.countryList) {
          if (states.isoCode === this.$props.details.beneficiary_address.state) {
            this.vendorState = states.name;
          }
        }
    },
};
</script>
import Vue from "vue";
import App from "./App.vue";
import Notifications from "vue-notification";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueMoment from "vue-moment";
import { router } from "./router";
import store from "./store";
import { getRuntimeConfig } from "@/store/apis/config";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
import "@/assets/css/custom_theme.scss";
// import "@/assets/css/custom_theme.css";
/* import "bootstrap/dist/css/bootstrap.css"; */
import "bootstrap-vue/dist/bootstrap-vue.css";

getRuntimeConfig().then((json) => {
  const appConfig = { APP_CONFIG: json };

  Vue.config.productionTip = false;
  Vue.use(Notifications);
  Vue.use(VueMoment);
  // Make BootstrapVue available throughout your project
  Vue.use(BootstrapVue);
  // Optionally install the BootstrapVue icon components plugin
  Vue.use(IconsPlugin);

  // expose appConfig to all Vue component instances
  // under self.APP_CONFIG
  Vue.mixin({
    data() {
      return appConfig;
    },
  });

  const app = new Vue({
    router: router(appConfig),
    store,
    render: (h) => h(App),
  });
  app.$mount("#app");

});

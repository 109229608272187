import axios from "axios";
import { paymentApi } from "./endpoint";
import {
  UserLogin,
  UserCreate,
  User,
  UserSuccess,
  ForgotPassword,
  ResendVerification,
  UserProfile,
  PartnerSuccess,
  PaymentAPIError,
  ResetPassword, PartnerCreate, PaymentAPIMessage, OnboardingSection,
} from "../models/users";

export async function setJWT(jwt: string, userEmail: string) {
  (await paymentApi()).defaults.headers = {
    Authorization: `Bearer ${jwt}`,
  };
  localStorage.setItem("user-token", jwt);
  localStorage.setItem("user-email", userEmail);
}

export async function clearJWT() {
  localStorage.removeItem("user-token");
  localStorage.removeItem("user-email");
  localStorage.removeItem("user-role");
  localStorage.removeItem("partner_id");
  localStorage.removeItem("external_id");
}

export async function loginUser(userLogin: UserLogin): Promise<any> {
  // Temp solution until client-edge switches to email-only
  const request = {
    emailOrPhone: userLogin.email,
    password: userLogin.password,
  };

  return axios.post("/user/login", request)
    .then((response) => response ? response.data : {});
}

export async function getUser(userPartnerId: string, userEmail: string): Promise<User> {
  const response = await (await paymentApi()).get(`/user/${userPartnerId}/${userEmail}/info`);
  localStorage.setItem("partner_id", response.data.partner_id);
  localStorage.setItem("external_id", response.data.id);
  localStorage.setItem("user-role", response.data.role);
  return response.data as User;
}

export async function getUsers(partnerID: string): Promise<User[] | PaymentAPIError> {
  return (await paymentApi()).get(`/partner/${partnerID}/users`)
    .then((response) => response.data as User[])
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function createUser(partnerID: string, user: UserCreate): Promise<UserSuccess | PaymentAPIError> {
  return (await paymentApi()).post(`/user/partner/${partnerID}`, user)
    .then((response) => response.data as UserSuccess)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function verifyUser(code: string): Promise<PaymentAPIMessage | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).get(`/user/verification/${code}`);
    return response.data as PaymentAPIMessage;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function resendVerificationCode(requestBody: ResendVerification): Promise<PaymentAPIMessage | PaymentAPIError> {
  // Temp solution until client-edge switches to email-only
  const request = {
    emailOrPhone: requestBody.email,
  };

  try {
    const response = await axios.post("/user/verification/code/resend", request);
    return response.data as PaymentAPIMessage;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function sendResetCode(requestBody: ForgotPassword): Promise<PaymentAPIMessage | PaymentAPIError> {
  // Temp solution until client-edge switches to email-only
  const request = {
    emailOrPhone: requestBody.email,
  };

  try {
    const response = await axios.post("/password/code/send", request);
    return response.data as PaymentAPIMessage;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function resetPassword(requestBody: ResetPassword): Promise<any> {
  // Temp solution until client-edge switches to email-only
  const request = {
    code: requestBody.code,
    emailOrPhone: requestBody.email,
    password: requestBody.password,
  };

  return axios.post("/password/reset", request)
    .then((response) => response.data as User)
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function updateUser(updateProfile: UserProfile): Promise<User | PaymentAPIError> {
  const url = `/user/${localStorage.getItem("external_id")}/partner/${localStorage.getItem("partner_id")}`;
  return (await paymentApi()).post(url, updateProfile)
    .then((response) => {
      localStorage.setItem("user-email", updateProfile.email);
      return response.data as User;
    })
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function createPartner(partner: PartnerCreate): Promise<PartnerSuccess | PaymentAPIError> {
  try {
    const response = await axios.post("/partner", partner);
    return response.data as PartnerSuccess;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function createOnboarding(onboarding: { [key: string]: any }): Promise<boolean | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).post("/onboarding", onboarding);
    return response.data;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function updateOnboarding(onboarding: { [key: string]: any }): Promise<boolean | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).post(`/onboarding/${localStorage.getItem("partner_id")}`, onboarding);
    return response.data;
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function getOnboardingUI(): Promise<OnboardingSection[] | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).get(`/onboarding/${localStorage.getItem("partner_id")}/ui-spec`);
    return response.data as OnboardingSection[];
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function getOnboardingData(): Promise<{ [key: string]: any } | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).get(`/onboarding/${localStorage.getItem("partner_id")}`);
    return response.data as { [key: string]: any };
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}

export async function createFileUploadUrl(data: any): Promise<{ [key: string]: any } | PaymentAPIError> {
  try {
    const response = await (await paymentApi()).post(`/onboarding/${localStorage.getItem("partner_id")}/file-upload-url`, data);
    return response.data as { [key: string]: any };
  } catch (error) {
    return error.response.data as PaymentAPIError;
  }
}
































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import users from "@/store/modules/users";
import {
  notifyUser,
  isTypePartnerSuccess,
  isTypePaymentAPIError,
  errorsToAssociative,
} from "@/helpers/template";
import { PartnerCreate } from "@/store/models/users";
import FormElement from "@/components/FormElement.vue";
import PublicLayout from "@/components/PublicLayout.vue";
import { validateOrReject } from "class-validator";
import { togglePasswordField, setPasswordIcon } from "@/helpers/forms";

@Component({ components: { FormElement, PublicLayout } })
export default class Register extends Vue {
  regiUser = new PartnerCreate();
  regiUserErrors = this.blankErrors();
  passwordFieldType = "password";
  passwordIconURL = "";

  @Watch("regiUser.administrator.email")
  setPartnerEmail(value) {
    this.regiUser.email = value;
  }

  @Watch("regiUser.administrator.phone")
  setPartnerPhone(value) {
    this.regiUser.phone = value;
  }

  @Watch("regiUser.administrator.confirm_password")
  confirmPasswordCheck(value) {
    this.regiUserErrors["administrator"]["confirm_password"] = [];
    if (value !== this.regiUser.administrator.password) {
      this.regiUserErrors["administrator"]["confirm_password"] = ["confirm_password does not match"];
    }
  }

  @Watch("regiUser.terms")
  termsCheck(value) {
    this.regiUserErrors["terms"] = [];
    if (!value) {
      this.regiUserErrors["terms"] = ["terms Please check terms and conditions"];
    }
  }

  togglePasswordField(e) {
    const toggled = togglePasswordField(this.passwordFieldType);
    this.passwordFieldType = toggled.type;
    this.setPasswordIcon(toggled.name);
  }

  setPasswordIcon(name) {
    this.passwordIconURL = setPasswordIcon(name);
  }

  async register() {
    try {
      await validateOrReject(this.regiUser);
      this.regiUserErrors = this.blankErrors();
    } catch (errors) {
      notifyUser(
        "Registration Failed",
        "Please correct the designated fields.",
        "error",
      );
      const output = errorsToAssociative(errors);
      Object.assign(this.regiUserErrors, output);
      return;
    }

    if (
      this.regiUser.administrator.confirm_password !==
      this.regiUser.administrator.password
    ) {
      this.regiUserErrors = this.blankErrors();
      this.regiUserErrors.administrator.confirm_password = ["confirm_password does not match"];
      notifyUser(
        "Registration Failed",
        "Please correct the designated fields.",
        "error",
      );
      return;
    }

    if ( !this.regiUser.terms) {
      this.regiUserErrors = this.blankErrors();
      this.regiUserErrors.terms = ["terms Please check terms and conditions"];
      notifyUser(
        "Registration Failed",
        "Please correct the designated fields.",
        "error",
      );
      return;
    }

    users
      .registerPartner(this.regiUser)
      .then(() => {
        const response = users.registerResponse;
        if (isTypePartnerSuccess(response)) {
          notifyUser("Success", "Registration Successful", "success");
          this.$router.push("/email_verify");
        } else if (isTypePaymentAPIError(response)) {
          if (response.details) {
            const assoc = errorsToAssociative(response.details);
            const firstError = Object.values(assoc)[0][0];
            notifyUser("Registration Failed", firstError, "error");
          } else {
            notifyUser("Registration Failed", response.message, "error");
          }
        }
      })
      .catch((error) => {
        notifyUser(
          "Registration Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  blankErrors() {
    return {
      name: "",
      email: "",
      phone: "",
      administrator: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: [],
      },
      terms: [],
    };
  }

  created() {
    this.setPasswordIcon("eye-off");
  }
}

<template >
  <div class="col-12">
    <div class="card cardD">
      <div class="card-title detailTitle">
        <p class="ml-3 pt-2">Expense Details </p>
      </div>
      <div class="card-body pt-0 card-body-action">
        <p class="detailTitles">Transaction ID:</p>
        <p class="detailDescription">{{ details.id }}</p>

        <p class="detailTitles">Transaction Mode:</p>
        <p class="detailDescription">{{ tableStatus.get(details.status).label }}</p>

        <p class="detailTitles">Quote ID:</p>
        <p class="detailDescription">{{ details.quote_id }}</p>

        <p class="detailTitles">Transaction Date:</p>
        <p class="detailDescription">{{ new Date(details.created_at * 1000).toLocaleDateString("en-US") }}</p>

        <p v-if="details.folio_id !== ''" class="detailTitles">Folio:</p>
        <p v-if="details.folio_id !== ''" class="detailDescription">{{ details.folio_id }}</p>
        <SenderDetail :details="details"/>
        <BenefDetail :details="details"/>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import { Vue, Component, Props } from "vue-property-decorator";
import SenderDetail from "@/components/SenderDetail.vue";
import BenefDetail from "@/components/BenefDetail.vue";
import {tableStatus} from "@/store/constants";

export default {
  name: "ExpensesAddress",
  props: ["details"],
  components: { SenderDetail, BenefDetail },
  watch: {
    details(newVal, oldVal) { // watch it
      this.createdDate = new Date(this.details.created_at * 1000).toLocaleDateString("en-US");
    },
  },
  data() {
    return {
      createdDate :  "-",
      tableStatus,
    };
  },
};
</script>
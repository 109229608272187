











import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelectCountry extends Vue {
  @Prop() param: string;
  @Prop() onChange: void;
  @Prop() index: number;
  @Prop() country_list: object;
}

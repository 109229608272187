


































































































import { Vue, Component } from "vue-property-decorator";
import {
  notifyUser,
  isTypePaymentAPIError,
  isTypePartner,
} from "@/helpers/template";
import partnerSettings from "@/store/modules/partnerSettings";
import VueModalCentered from "@/components/VueModalCentered.vue";
import RegenerateToken from "@/components/RegenerateToken.vue";
@Component({ components: {VueModalCentered, RegenerateToken} })
export default class APICredentialsTab extends Vue {
  apiCredentials = {};
  apiCreatedAt = 0;
  apiCredentialsUpdate = {};
  apiCredentialsUpdated = [];
  isModalVisible = false;
  showFooter = true;
  data() {
    return {
      data: {
        image: "Alert",
        key: {
          value: "",
          showInput: false,
          msg: "Are you sure you want to regenerate your token?",
          msg2: "Your current token will no longer be valid, and you will need to update your API access with the new token.",
          msg3: "",
          msg4: "",
        },
      },
    };
  }
  async getAPICredentials() {
    try {
      await partnerSettings.getPartner();
      const response = partnerSettings.getPartnerResponse;
      if (isTypePartner(response)) {
        this.apiCredentials = response.token_suffix;
        this.apiCreatedAt = response.token_created_at;

      } else if (isTypePaymentAPIError(response)) {
        notifyUser("API Credentials Failed", response.message, "error");
      }
    } catch (error) {
      notifyUser(
        "API Credentials Error",
        "Unknown error. Please try again.",
        "error",
      );
    }
  }

    showModal() {
      this.isModalVisible = true;
      this.showFooter = true;
    }
    closeModal() {
      this.isModalVisible = false;
      this.showFooter = true;
      this.$data.data.key.value = "";
      this.$data.data.image = "Alert";
      this.$data.data.key.msg = "Are you sure you want to regenerate your token?";
      this.$data.data.key.showInput = false;
      this.$data.data.key.msg2 = "Your current token will no longer be valid, and you will need to update your API access with the new token.";
      this.$data.data.key.msg3 = "";
      this.$data.data.key.msg4 = "";
      caches.keys().then(function(names) {
    for (const name of names) {
        caches.delete(name);
    }
});
    }
    async regenerateToken() {
      try {
      await partnerSettings.regenerateToken()
      .then();
      const response = partnerSettings.regenerateTokenResponse;
      this.apiCredentialsUpdate = JSON.parse(JSON.stringify(response));
      if (response) {
        this.getAPICredentials();
        this.showFooter = false;
        this.$data.data.key.value = this.apiCredentialsUpdate;
        this.$data.data.key.msg = "Your partner token has been created";
        this.$data.data.image = "Lock";
        this.$data.data.key.showInput = true;
        this.$data.data.key.msg2 = "Please copy it and keep it secure, as it will not be displayed again.";
        this.$data.data.key.msg3 = "If you lose your token, you will need to generate a new one.";
        this.$data.data.key.msg4 = "Your partner token will be stored securely on our servers using advanced encryption techniques to ensure your data is protected.";
      } else if (isTypePaymentAPIError(response)) {
        notifyUser("API Credentials Failed", response.message, "error");
      }
    } catch (error) {
      notifyUser(
        "API Credentials Error",
        "Unknown error. Please try again.",
        "error",
      );
    }
    }
  mounted() {
    this.getAPICredentials();
  }
}

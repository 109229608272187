import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import {Partner, PaymentAPIError, BusinessInfo, PartnerBase, PartnerUpdate, DiscoveryQuery, AcceptanceQuery, OptionsResponse, QuoteResponse, AcceptanceResponse, DeliveryQuery} from "../models/partnerSettings";
import {
  getPartner,
  updatePartner,
  regenerateToken,
  quoteAcceptance,
} from "../apis/partnerSettings";
import {
  isTypePartner, isTypePartnerUpdate,
} from "@/helpers/template";
import { discoveryList, discoveryQuote, deliveryList  } from "../apis/partnerSettings";

@Module({
  namespaced: true,
  name: "partnerSettings",
  store,
  dynamic: true,
})
class PartnerSettingsModule extends VuexModule {
  getBIResponse: object = {};
  updateBIResponse: object = {};
  getPartnerResponse: object = {};
  regenerateTokenResponse: object = {};
  onDiscoveryListResponse: object = {};
  onDeliveryListResponse: object = {};
  onQuoteResponse: object = {};
  onQuoteAcceptanceResponse: object = {};

  @MutationAction({ mutate: ["getBIResponse"] })
  async getBusinessInfo() {
    const fromApi: Partner | PaymentAPIError = await getPartner(localStorage.getItem("partner_id"));
    let getBIResponse: BusinessInfo | PaymentAPIError;
    if (isTypePartner(fromApi)) {
      getBIResponse = {
        name: fromApi.name || "",
        lastname: fromApi.lastname || "",
        contact: fromApi.email || "",
        phone: fromApi.phone || "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      };
      if (fromApi.address) {
        const address = fromApi.address;
        if (address.street && address.street.length > 0) {
          getBIResponse.address = address.street.join(", ");
        }
        getBIResponse.city = address.city || "";
        getBIResponse.zip = address.postal || "";
        getBIResponse.state = address.state || "";
        getBIResponse.country = address.country || "";
      }
    } else {
      getBIResponse = fromApi;
    }
    return { getBIResponse };
  }

  @MutationAction({ mutate: ["updateBIResponse"] })
  async updateBusinessInfo(newValues: PartnerBase) {
    const fromApi: PartnerUpdate | PaymentAPIError = await updatePartner(localStorage.getItem("partner_id"), newValues);
    let updateBIResponse: BusinessInfo | PaymentAPIError;
    if (isTypePartnerUpdate(fromApi)) {
      updateBIResponse = {
        name: fromApi.name,
        lastname: fromApi.lastname || "",
        contact: fromApi.email,
        phone: fromApi.phone,
        address: fromApi.address.street.join(", "),
        city: fromApi.address.city,
        state: fromApi.address.state,
        zip: fromApi.address.postal,
        country: fromApi.address.country,
      };
    } else {
      updateBIResponse = fromApi;
    }
    return { updateBIResponse };
  }

  @MutationAction({ mutate: ["getPartnerResponse"] })
  async getPartner() {
    const getPartnerResponse: Partner | PaymentAPIError = await getPartner(localStorage.getItem("partner_id"));
    return { getPartnerResponse };
  }

  @MutationAction({ mutate: ["onDiscoveryListResponse"] })
  async onDiscoveryList(query: DiscoveryQuery) {
    const onDiscoveryListResponse: OptionsResponse | PaymentAPIError = await discoveryList(localStorage.getItem("user-token"), query);
    return { onDiscoveryListResponse };
  }

  @MutationAction({ mutate: ["onDeliveryListResponse"] })
  async onDeliveryList(query: DeliveryQuery) {
    const onDeliveryListResponse: [] | PaymentAPIError = await deliveryList(localStorage.getItem("user-token"), query);
    return { onDeliveryListResponse };
  }

  @MutationAction({ mutate: ["onQuoteResponse"] })
  async onQuote(query: DiscoveryQuery) {
    const onQuoteResponse: QuoteResponse | PaymentAPIError = await discoveryQuote(localStorage.getItem("user-token"), query);
    return { onQuoteResponse };
  }

  @MutationAction({ mutate: ["onQuoteAcceptanceResponse"] })
  async onQuoteAcceptance(query: AcceptanceQuery) {
    const onQuoteAcceptanceResponse: AcceptanceResponse | PaymentAPIError = await quoteAcceptance(localStorage.getItem("user-token"), query);
    return { onQuoteAcceptanceResponse };
  }

  @MutationAction({ mutate: ["regenerateTokenResponse"] })
  async regenerateToken() {
    const regenerateTokenResponse: Partner | PaymentAPIError = await regenerateToken(localStorage.getItem("partner_id"));
    return { regenerateTokenResponse };
  }
}

export default getModule(PartnerSettingsModule);

import axios from "axios";
import { paymentApi } from "./endpoint";
import {
  Vendor,
  PaymentAPIError,
} from "../models/vendors";

export async function getVendors(partnerID: string): Promise<Vendor[] | PaymentAPIError> {
  return (await paymentApi()).get(`/partners/${partnerID}/vendors`)
    .then((response) => response.data as Vendor[])
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function addVendors(partnerID: string, data) {
  return (await paymentApi()).post(`/partners/${partnerID}/vendors`, data)
    .then((response) => response as unknown as [])
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function editVendors(partnerID: string, data) {
  return (await paymentApi()).post(`/partners/${partnerID}/vendors/${data.id}`, data)
    .then((response) => response as unknown as [])
    .catch((error) => error.response.data as PaymentAPIError);
}

export async function deleteVendors(partnerID: string, data) {
  return (await paymentApi()).delete(`/partners/${partnerID}/vendors/${data}`)
    .then((response) => response as unknown as [])
    .catch((error) => error.response.data as PaymentAPIError);
}
















import { Vue, Component, Prop } from "vue-property-decorator";
import claimMoney from "@/store/modules/claimMoney";

@Component({
  beforeRouteEnter: Result.preRoute,
  beforeRouteUpdate: Result.preRoute,
})
export default class Result extends Vue {
  static async preRoute(to, from, next) {
    if (from.name === "step-two") {
      next();
    } else {
      next({ name: "home" });
    }
  }

  @Prop() next: () => void;

  claimMoney = claimMoney;
}














































import { Vue, Component, Prop } from "vue-property-decorator";
import { notifyUser } from "@/helpers/template";
import { token } from "@/store/apis/endpoint";
import users from "@/store/modules/users";
import onboarding from "@/store/modules/onboarding";

@Component
export default class Card extends Vue {
  @Prop() next: () => void;

  onboarding = onboarding;
  form = {} as any;
  async mounted() {
    const params = this.$data.APP_CONFIG.vgs.sandbox ? "sandbox" : {};
    this.form = (window as any).VGSCollect.create(
      this.$data.APP_CONFIG.vgs.vaultId,
      params,
      (state) => {},
    );
    this.form.field("#firstName .form-control", {
      type: "text",
      name: "first_name",
      validations: ["required"],
    });

    this.form.field("#lastName .form-control", {
      type: "text",
      name: "last_name",
      validations: ["required"],
    });

    this.form.field("#cardNumber .form-control", {
      type: "card-number",
      name: "card_number",
      successColor: "#4F8A10",
      errorColor: "#D8000C",
      validations: ["required", "validCardNumber"],
    });

    this.form.field("#cvv .form-control", {
      type: "card-security-code",
      name: "card_cvc",
      validations: ["required", "validCardSecurityCode"],
    });

    this.form.field("#expDate .form-control", {
      type: "card-expiration-date",
      name: "card_exp",
      yearLength: 2,
      validations: ["required", "validCardExpirationDate"],
    });
  }
  async submit(saveOnly) {
    this.form.submit(
      `/onboarding/${localStorage.getItem("partner_id")}/card`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          type: onboarding.card.type,
        },
        mapDotToObject: "merge",
      },
      async (status, response) => {
        if (typeof response === "boolean" && response) {
          notifyUser("Success", "Onboarding Update Successful", "success");
          await users.getOnboardingData();
          if (saveOnly) { return; }
          this.next();
        } else {
          notifyUser("Error", "Onboarding Update Failed", "error");
        }
      },
    );
  }
}

































































import { Vue, Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import AddressForm from "@/components/AddressForm.vue";
import claimMoney from "@/store/modules/claimMoney";

@Component({
  components: { AddressForm, TheMask },
  beforeRouteEnter: StepOne.preRoute,
  beforeRouteUpdate: StepOne.preRoute,
})
export default class StepOne extends Vue {
  static async preRoute(to, from, next) {
    if (from.name === "claim") {
      next();
    } else {
      next({ name: "home" });
    }
  }

  @Prop() next: () => void;

  claimMoney = claimMoney;

  async changeState(index, value) {
    await claimMoney.updateState(value);
  }
  async changeCountry(index, value) {
    await claimMoney.updateCountry(value);
  }
}

import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetInstruction from "@/views/ResetInstruction.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import EmailVerify from "@/views/EmailVerify.vue";
import ResendVerification from "@/views/ResendVerification.vue";
import Dashboard from "@/views/Dashboard.vue";
import Profile from "@/views/Profile.vue";
import Transactions from "@/views/Transactions.vue";
import Users from "@/views/Users.vue";
import BusinessInfo from "@/views/BusinessInfo.vue";
import APICredentials from "@/views/APICredentials.vue";
import TermsNConditions from "@/views/pages/TermsNConditions.vue";
import PrivacyPolicy from "@/views/pages/PrivacyPolicy.vue";
import Onboarding from "@/views/Onboarding.vue";
import OnboardingProducts from "@/components/onboarding/Products.vue";
import OnboardingCardVGS from "@/components/onboarding/CardVGS.vue";
import OnboardingCard from "@/components/onboarding/Card.vue";
import OnboardingBank from "@/components/onboarding/Bank.vue";
import OnboardingRepresentative from "@/components/onboarding/Representative.vue";
import OnboardingBusiness from "@/components/onboarding/Business.vue";
import OnboardingIncorporation from "@/components/onboarding/Incorporation.vue";
import OnboardingCompliance from "@/components/onboarding/Compliance.vue";
import OnboardingDocuments from "@/components/onboarding/Documents.vue";
import OnboardingSummary from "@/components/onboarding/Summary.vue";
import SendMoney from "@/views/SendMoney.vue";
import Send from "@/components/send-money/Send.vue";
import SendReview from "@/components/send-money/Review.vue";
import SendResult from "@/components/send-money/Result.vue";
import ClaimMoney from "@/views/ClaimMoney.vue";
import Claim from "@/components/claim-money/Claim.vue";
import ClaimStepOne from "@/components/claim-money/StepOne.vue";
import ClaimStepTwoVGS from "@/components/claim-money/StepTwoVGS.vue";
import ClaimStepTwo from "@/components/claim-money/StepTwo.vue";
import ClaimResult from "@/components/claim-money/Result.vue";
import Vendors from "@/views/Vendors.vue";
import BusinessPayments from "@/views/BusinessPayments.vue";

Vue.use(Router);

export const router = (config) => {
  const instance = new Router({
    routes: [
      {
        path: "/",
        name: "home",
        redirect: { name: "dashboard" },
      },
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/register",
        name: "register",
        component: Register,
      },
      {
        path: "/bpayments",
        name: "businessPayments",
        component: BusinessPayments,
      },
      {
        path: "/forgot_password",
        name: "forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/reset_instruction",
        name: "resetInstruction",
        component: ResetInstruction,
      },
      {
        path: "/reset_password/:code",
        name: "resetPassword",
        component: ResetPassword,
      },
      {
        path: "/email_verify",
        name: "emailVerify",
        component: EmailVerify,
      },
      {
        path: "/resend_verification",
        name: "resendVerification",
        component: ResendVerification,
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
      },
      // {
      //   path: "/apiKeys",
      //   name: "apiKeys",
      //   component: () => import("@/views/ApiKeys.vue")
      // },
      {
        path: "/transactions",
        name: "transactions",
        component: Transactions,
      },
      // {
      //   path: "/customers",
      //   name: "customers",
      //   component: () => import("@/views/Customers.vue")
      // },
      // {
      //   path: "/gateways",
      //   name: "gateways",
      //   component: () => import("@/views/Gateways.vue")
      // },
      // {
      //   path: "/payment/dashboard",
      //   name: "payment-dashboard",
      //   component: PaymentDashboard,
      // },
      // {
      //   path: "/payment/transactions",
      //   name: "merchant-transactions",
      //   component: MerchantTransactions,
      // },
      // {
      //   path: "/payment/billing",
      //   name: "recurring-billing",
      //   component: RecurringBilling,
      // },
      // {
      //   path: "/payment/invoicing",
      //   name: "invoicing",
      //   component: Invoicing,
      // },
      // {
      //   path: "/payment/terminal",
      //   name: "virtual-terminal",
      //   component: VirtualTerminal,
      // },
      // {
      //   path: "/payment/customers",
      //   name: "paypro-customers",
      //   component: PayProCustomers,
      // },
      {
        path: "/account/users",
        name: "users",
        component: Users,
      },
      {
        path: "/account/business_info",
        name: "businessInfo",
        component: BusinessInfo,
      },
      {
        path: "/account/api_credentials",
        name: "apiCredentials",
        component: APICredentials,
      },
      {
        path: "/vendors",
        name: "vendors",
        component: Vendors,
      },
      // {
      //   path: "/account/billing",
      //   name: "billing",
      //   component: () => import("@/views/Billing.vue"),
      // },
      {
        path: "/terms_and_conditions",
        name: "termsNConditions",
        component: TermsNConditions,
      },
      {
        path: "/privacy_policy",
        name: "privacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/onboarding",
        name: "onboarding",
        component: Onboarding,
        children: [
          { path: "choose-products", name: "choose-products", component: OnboardingProducts },
          { path: "card", name: "card", component: config.APP_CONFIG.vgs.enabled ? OnboardingCardVGS : OnboardingCard },
          { path: "bank", name: "bank", component: OnboardingBank },
          { path: "representative", name: "representative", component: OnboardingRepresentative },
          { path: "business-details", name: "business-details", component: OnboardingBusiness },
          { path: "incorporation-details", name: "incorporation-details", component: OnboardingIncorporation },
          { path: "compliance-details", name: "compliance-details", component: OnboardingCompliance },
          { path: "business-documents", name: "business-documents", component: OnboardingDocuments },
          { path: "summary", name: "summary", component: OnboardingSummary },
        ],
      },
      {
        path: "/send-money",
      name: "send-money",
        component: SendMoney,
        children: [
          { path: "/", name: "send", component: Send },
          { path: "review", name: "send-review", component: SendReview },
          { path: "result", name: "send-result", component: SendResult },
        ],
      },
      {
        path: "/claim-money",
      name: "claim-money",
        component: ClaimMoney,
        children: [
          { path: "/", name: "claim", component: Claim },
          { path: "step-one", name: "step-one", component: ClaimStepOne },
          { path: "step-two", name: "step-two", component: config.APP_CONFIG.vgs.enabled ? ClaimStepTwoVGS : ClaimStepTwo },
          { path: "result", name: "claim-result", component: ClaimResult },
        ],
      },
      {
        path: "*",
        redirect: { name: "dashboard" },
      },
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    // to not append "#" to the URL
    mode: "history",
  });

  instance.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = [
      "login",
      "register",
      "claim",
      "step-one",
      "step-two",
      "claim-result",
      "forgotPassword",
      "resetInstruction",
      "resetPassword",
      "registerConfirmed",
      "emailVerify",
      "resendVerification",
      "termsNConditions",
      "privacyPolicy",
    ];
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = localStorage.getItem("user-token");
    if (loggedIn && to.name === "home") {
      return next("/dashboard");
    }
    if (authRequired && !loggedIn && to.name !== "home") {
      return next("/login");
    }
    if (loggedIn && to.name === "login") {
      return next("/dashboard");
    }

    next();
  });

  return instance;
};

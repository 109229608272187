<template>
  <public-layout>
    <template slot="right-content">
      <form v-on:submit.prevent="onSubmit">
        <h2 class="display-4 mb-10 font-44 pt-20">Resend code</h2>
        <p class="mb-30">Enter your user profile email to receive a new verification code</p>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            :class="resendVerificationErrors.email && resendVerificationErrors.email.length ? 'invalid-input' : ''"
            placeholder="Email"
            name="email"
            v-model="resendVerification.email"
            required
          />
          <div
            class="error"
            v-for="e in resendVerificationErrors.email"
            :key="e"
          >{{e.substring(e.indexOf(' '))}}</div>
        </div>
        <button class="btn btn-green btn-block" type="submit">Submit</button>
        <br />
        <p class="text-center">
          Already verified?
          <router-link to="/login">Login</router-link>
        </p>
        <p class="text-center">
          Don't have an account?
          <router-link to="/register">Sign up</router-link>
        </p>
      </form>
    </template>
  </public-layout>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { notifyUser, errorsToAssociative } from "@/helpers/template";
import users from "@/store/modules/users";
import { validateOrReject } from "class-validator";
import FormElement from "@/components/FormElement.vue";
import PublicLayout from "@/components/PublicLayout.vue";
import { ResendVerification } from "@/store/models/users";
import {
  isTypePaymentAPIError,
  isTypePaymentAPIMessage,
} from "../helpers/template";

@Component({ components: { FormElement, PublicLayout } })
export default class ResendVerificationVue extends Vue {
  resendVerification = new ResendVerification();
  resendVerificationErrors = {};

  async onSubmit() {
    try {
      await validateOrReject(this.resendVerification);
      this.resendVerificationErrors = {};
    } catch (errors) {
      this.resendVerificationErrors = errorsToAssociative(errors);
      notifyUser("", "Please check form data", "error");
      return;
    }

    try {
      await users.resendVerificationCode(this.resendVerification);
      const response = users.resendVerificationResponse;
      if (isTypePaymentAPIMessage(response)) {
        notifyUser("Verification Code Sent", response.message, "success");
        this.$router.push("/email_verify");
      } else if (isTypePaymentAPIError(response)) {
        notifyUser("Verification Code Failed", response.message, "error");
      }
    } catch (error) {
      notifyUser(
        "Verification Code Error",
        "Unknown error. Please try again.",
        "error",
      );
    }
  }
}
</script>

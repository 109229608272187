











































import { Vue, Component, Prop } from "vue-property-decorator";
import onboarding from "@/store/modules/onboarding";

@Component
export default class ChooseProducts extends Vue {
  @Prop() submit: (saveOnly: boolean) => void;

  onboarding = onboarding;
}

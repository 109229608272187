import { IsFQDN, IsEmail, IsNotEmpty, IsPhoneNumber } from "class-validator";

export interface PartnerBase {
  name: string;
  email: string;
  phone: string;
  status_id?: number;
  address: Address;
}

export interface DiscoveryQuery {
  delivery_type: string;
  sender_country_code: string;
  sender_currency_code: string;
  sender_amount: string;
  beneficiary_country_code: string;
  beneficiary_currency_code: string;
  token: string;
}

export interface AcceptanceQuery {
  sender: object;
  beneficiary: object;
  quote: string;
  cuit?: string;
  cbu?: string;
  account_type?: string;
}

export interface DeliveryQuery {
  country: string;
  currency: string;
}

export interface AcceptanceResponse {
  id: string;
  folio_id: string;
  status: string;
  quote_id: string;
  quote_expiration: number;
  quote_instant: number;
  sender_amount: string;
  sender_currency_code: string;
  sender_country_code: string;
  sender_family_name: string;
  sender_given_name: string;
  sender_email: string;
  sender_phone: string;
  sender_address: object;
  beneficiary_amount: string;
  beneficiary_currency_code: string;
  beneficiary_country_code: string;
  beneficiary_family_name: string;
  beneficiary_given_name: string;
  beneficiary_email: string;
  beneficiary_phone: string;
  beneficiary_address: object;
  beneficiary_acceptance_expiration: number;
  created_at: number;
  updated_at: number;
  message: string;
  anticipated_settlement_time: string;
}

export interface QuoteResponse {
  id: string;
  sender_amount: string;
  sender_currency_code: string;
  sender_country_code: string;
  beneficiary_amount: string;
  beneficiary_currency_code: string;
  beneficiary_country_code: string;
  partner_id: string;
  partner_name: string;
  expiration: number;
  platform_fee: object;
  gateway_fee: object;
}

export interface OptionsResponse {
  sender_amount: string;
  sender_currency_code: string;
  sender_country_code: string;
  beneficiary_amount: string;
  beneficiary_currency_code: string;
  beneficiary_country_code: string;
  partner_id: string;
  delivery_type: string;
  platform_fee: object;
  gateway_fee: object;
  fx_rate: object;
  location: object;
  option_id: string;
}

export interface PartnerUpdate extends PartnerBase {
  id: string;
  partner_token: string;
  lastname: string;
}
export interface Partner extends PartnerUpdate {
  partner_gateways: Array<string>;
  customers: number;
  instruments: number;
  lastname: string;
  partner_email_providers: Array<string>;
  partner_sms_providers: Array<string>;
  token_suffix: Array<string>;
  token_created_at: number;
}

export interface Address {
  street: Array<string>;
  city: string;
  state: string;
  postal: string;
  country: string;
}

export class BusinessInfo {
  @IsNotEmpty() name: string;
  @IsNotEmpty() lastname: string;
  @IsEmail() contact: string;
  @IsPhoneNumber("US") phone: string;
  @IsNotEmpty() address: string;
  @IsNotEmpty() city: string;
  @IsNotEmpty() state: string;
  @IsNotEmpty() zip: string;
  @IsNotEmpty() country: string;
  constructor(params: {} = {}) {
    Object.assign(this, params);
  }
}

export class MailConfig {
  id: string | undefined;
  @IsNotEmpty() api_key: string;
  @IsEmail() from_who: string;
  @IsFQDN() domain: string;
  readonly name: "My Mailgun Account";
  readonly service_provider: "mailgun";
}

export class SMSConfig {
  id: string | undefined;
  @IsNotEmpty() account_sid: string;
  @IsNotEmpty() auth_token: string;
  @IsPhoneNumber("US") from_number: string;
  readonly name: "My Twilio Account";
  readonly service_provider: "twilio";
}

export interface PaymentAPIError {
  name: string;
  message: string;
  stack: number;
  code?: number;
}

export interface PaymentAPIError {
  name: string;
  message: string;
  stack: number;
  code?: number;
}

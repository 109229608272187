export const scheduleOptions = [
  { value: "Weekly" },
  { value: "Monthly" },
  { value: "Annually" },
];
export const helpOptions = [
  {
    value: "Support@PaymentApproved.com",
    href: "mailto:Support@PaymentApproved.com",
  },
  { value: "(512) 716-8770", href: "tel:(512) 716-8770" },
];
export const transactionTypeOptions = [
  { value: "Request" },
  { value: "Payout" },
];
export const transactionStatusOptions = [
  { value: "Success" },
  { value: "Fail" },
];
export const profileOptions = [
  { value: "Profile" },
  { value: "Logout" },
];
export const footerFixedRoutes = [
  "/login",
  "/email_confirmed",
  "/email_verify",
  "/forgot_password",
  "/reset_instruction",
];
export const bootstrapPaginationClasses = {
  ul: "pagination",
  li: "page-item",
  liActive: "active",
  liDisable: "disabled",
  button: "page-link",
};
export const paginationAnchorTexts = {
  first: "First",
  prev: "Previous",
  next: "Next",
  last: "Last",
};

export const tableStatus = new Map([
  ["Unexamined", {color: "#777777", label: "Pending"}],
  ["Unprepared", {color: "#777777", label: "Pending"}],
  ["Examining", {color: "#FFD700", label: "Processing"}],
  ["Prepared", {color: "#FFD700", label: "Processing"}],
  ["Creditable", {color: "#FFD700", label: "Processing"}],
  ["Crediting", {color: "#FFD700", label: "Processing"}],
  ["Credited", {color: "#03AD00", label: "Completed"}],
  ["Denied", {color: "#B22222", label: "Failed"}],
  ["Declined", {color: "#B22222", label: "Failed"}],
  ["Erred", {color: "#B22222", label: "Failed"}],
  ["TimedOut", {color: "#B22222", label: "Failed"}],
  ["Cancelled", {color: "#B22222", label: "Failed"}],
  ["Failed", {color: "#B22222", label: "Failed"}],
]);

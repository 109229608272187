<template>
    <div class="hk-pg-inner">
        <div class="container containerDashboard mt-xl-50 mt-30 justify-content-center align-items-center d-flex">
            <div class="col-12 justify-content-center align-items-center d-flex">
                <div class="card cardD cardSuccess" style="padding: 20px !important;">

                    <div class="card-body pt-0 card-body-action justify-content-center align-items-center text-center">
                        <img class="gif pt2 align-self-center justify-self-center"
                            src="../assets/images/success.gif" alt="icon" />
                        <h2>Success!</h2>
                        <p class="successText"> You've successfully sent $ {{tsxInformation.quote[3]}} {{tsxInformation.quote[0].beneficiary_currency_code}} to {{tsxInformation.vendor[0].email}}.
                            <br>
                            See the transaction details under </p> <p class="successActivity"><a  href="transactions">activity </a></p>
                            <br>
                            <button class="btn btn-primary"  @click="newTransaction()">Make Another Payment</button>
                            <br>
                            <!-- <a class="successDownload mt-5" href="">Download Receipt</a> -->

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import { scheduleOptions } from "@/store/constants";
import Datepicker from "vuejs-datepicker";
import { tableStatus } from "@/store/constants";
import VueDropdown from "@/components/VueDropdown.vue";
import { isTypePaymentAPIError, notifyUser } from "../helpers/template";

export default  {
    name: "BusinessCheck",
    props: ["tsxInformation"],
    data() {
        return {};
    },
    methods: {
        newTransaction() {
            this.$emit("newTransaction", []);
        },
    },
};
</script>
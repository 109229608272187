<template>
  <div class="hk-pg-inner">
    <div class="container containerDashboard mt-xl-50 mt-30">
      <div class="hk-pg-header hk-pg-headerCredentials">
        <div>
          <h4 class="hk-pg-title">Transactions</h4>
          <p class="hk-pg-subtitle">View and search through a complete record of money transfer transactions.</p>
        </div>
      </div>
      <Table @getTransactions="getTransactions" :tableData="tableData" :showCheckbox="false" />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import { scheduleOptions } from "@/store/constants";
import Datepicker from "vuejs-datepicker";
import transactions from "@/store/modules/transactions";
import { tableStatus } from "@/store/constants";
import users from "@/store/modules/users";
import VueDropdown from "@/components/VueDropdown.vue";
import { isTypePaymentAPIError, notifyUser } from "../helpers/template";
import "datatables.net-bs4";
import Table from "@/components/Table.vue";
@Component({ components: { VueDropdown, Datepicker, Table } })
export default class Transactions extends Vue {
  scheduleSelectOptions = scheduleOptions;
  endDate = new Date();
  // Hours / Minutes / Seconds / MS
  txnIntervalInMs = 480 * 60 * 60 * 1000;
  startDate = new Date(this.endDate.getTime() - this.txnIntervalInMs);
  currentSort = "created_at";
  currentSortDir = "desc";
  allTransactions = [];
  data() {
    return {
      tableData: {
        url: "Transactions",
        header: [{
          key: "",
        },
        {
          key: "sender_amount",
          sortable: true,
        },
        {
          key: "sender_given_name",
          sortable: true,
        },
        {
          key: "beneficiary_amount",
          sortable: true,
        },
        {
          key: "beneficiary_given_name",
          sortable: true,
        },
        {
          key: "beneficiary_family_name",
          sortable: true,
        },
        {
          key: "created_at",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        }],
        data: [""],
      },
      availableBalance: {
        title: "Your Available Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Available",
      },
      unsettledBalance: {
        title: " Daily Unsettled Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Unsettled",
      },
      spendBalance: {
        title: "Daily Spend Balance",
        amount: "00.00",
        percent: "5.31",
        icon: "Spend",
      },
    };
  }
  methods() {
    return {
      getTransactions() {
        this.getTransactions();
      },
    };
  }
  onScheduleSelect(selected) {
  }

  async getTransactions() {
    this.startDate = new Date(this.endDate.getTime() - 840 * 60 * 60 * 1000);
    const query = {
      start_date: parseInt(this.startDate.getTime() / 1000),
      end_date: parseInt(this.endDate.getTime() / 1000),
      from: 0,
      size: 999,
    };
    await transactions
      .onTransactionList(query)
      .then(() => {
        let response = transactions.onTransactionListResponse;
        this.allTransactions = response;
        const timeOutms = 100;
        if (response instanceof Array) {
          response = response.map((transaction) => ({
            ...transaction,
            status: tableStatus.get(transaction.status),
            created_at: new Date(transaction.created_at * 1000).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            beneficiary_full_name: `${transaction.beneficiary_given_name} ${transaction.beneficiary_family_name}`,
            beneficiary_amount_currency: `${transaction.beneficiary_currency_code} ${transaction.beneficiary_amount}`,
            sender_full_name: `${users.profile.first_name} ${users.profile.last_name}`,
            sender_amount_currency: `${transaction.sender_currency_code} ${transaction.sender_amount}`,
            semder_given_name: `${users.profile.first_name} ${users.profile.last_name}`,
          }));
          this.tableData.data = response;
          setTimeout(() => {

            this.updatePagination();
          }, timeOutms);
        } else if (isTypePaymentAPIError(response)) {
          notifyUser("Transactions List Failed", response.message, "error");
        }
      })
      .catch((error) => {
        notifyUser(
          "Transactions List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  created() {
    this.getTransactions();
  }
  updated() {
  }

  updatePagination() {
  }

  // get dashboardToltalCount() {
  //   return dashboard.dashboardToltalCount;
  // }

  get recentTransactions() {
    const filtered =
      this.allTransactions.length <= 7
        ? this.allTransactions
        : this.allTransactions.slice(0, 7);
    return filtered;
  }
  compareValues(key, order = "asc") {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
      const comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }
  sort(field) {
    if (this.currentSort === field) {
      this.currentSortDir = this.currentSortDir === "desc" ? "asc" : "desc";
    } else {
      this.currentSort = field;
      this.currentSortDir = "desc";
    }
  }
  sortIcon(field) {
    return this.currentSort === field && this.currentSortDir === "desc"
      ? "arrow_drop_down"
      : "arrow_drop_up";
  }
}
</script>

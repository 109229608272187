


































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import VueDropdown from "@/components/VueDropdown.vue";
import users from "@/store/modules/users";
import { Route } from "vue-router";

@Component({ components: { VueDropdown } })
export default class AppNavbar extends Vue {
  showDropdown = false;
  navButtonClass = { collapsed: true };
  navButtonAriaExpanded = "false";
  navbarClass = { collapse: true, collapsing: false, show: false };

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    this.navButtonClass["collapsed"] = true;
    this.navButtonAriaExpanded = "false";
    this.navbarClass["collapse"] = true;
    this.navbarClass["show"] = false;
  }

  logout() {
    users.logout().then(() => this.$router.push("/login"));
  }
  showDropdownMenu() {
    this.showDropdown = !this.showDropdown;
  }
  created() {
    document.addEventListener("click", this.documentClick);
  }
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  }
  documentClick(e) {
    const el = this.$refs.dropdownMenu;
    const target = e.target;
    if (el !== target) {
      this.showDropdown = false;
    }
  }
  toggleMobileNav() {
    /* document.getElementById("overlay").style.display = "block" */
    this.navButtonClass["collapsed"] = !this.navButtonClass["collapsed"];
    this.navButtonAriaExpanded = this.navButtonAriaExpanded === "false" ? "true" : "false";
    this.navbarClass["collapse"] = !this.navbarClass["collapse"];
    this.navbarClass["show"] = !this.navbarClass["show"];
    if (this.navbarClass["show"] === true) {
      document.getElementById("overlay").style.display = "none";
    } else {
      document.getElementById("overlay").style.display = "block";
    }
  }
  get username() {
    return (users && users.username) || "Unnamed";
  }
  get shortname() {
    if (!users) {
      return "";
    }
    const matches = users.username.match(/\b(\w)/g);
    return matches.join("").toUpperCase();
  }
  get isLoggedIn() {
    return (users && users.isLoggedIn) || false;
  }
}





























































































































import { Vue, Component, Watch } from "vue-property-decorator";
import VueModal from "@/components/VueModal.vue";
import users from "@/store/modules/users";
import {
  isTypeUserSuccess,
  isTypePaymentAPIError,
  notifyUser,
  isUserRoleAdmin,
  errorsToAssociative,
} from "@/helpers/template";
import { validateOrReject } from "class-validator";
import FormElement from "@/components/FormElement.vue";
import { UserCreate } from "@/store/models/users";
import "datatables.net-bs4";
import $ from "jquery";

@Component({ components: { VueModal, FormElement } })
export default class UsersTab extends Vue {
  formUser: UserCreate = new UserCreate();
  formUserErrors = {};
  isUserModalVisible = false;
  usersList = [];
  isUserAdmin = false;
  tableKey = 0;

  @Watch("formUser.confirm_password")
  confirmPasswordCheck(value) {
    this.formUserErrors["confirm_password"] = [];
    if (value !== this.formUser.password) {
      this.formUserErrors["confirm_password"] = ["confirm_password does not match"];
    }
  }

  showUserModal() {
    this.formUser = new UserCreate();
    this.isUserModalVisible = true;
  }

  closeUserModal() {
    this.isUserModalVisible = false;
  }

  getUsers() {
    users
      .getUsers()
      .then(() => {
        const response = users.getUsersResponse;
        if (response instanceof Array) {
          this.usersList = response;
        } else if (isTypePaymentAPIError(response)) {
          notifyUser("Users List Failed", response.message, "error");
        }
      })
      .catch((error) => {
        notifyUser(
          "Users List Error",
          "Unknown error. Please try again.",
          "error",
        );
      });
  }

  async addUser() {
    try {
      await validateOrReject(this.formUser);
      this.formUserErrors = {};
    } catch (errors) {
      notifyUser(
        "Add User Failed",
        "Please correct the designated fields.",
        "error",
      );
      this.formUserErrors = errorsToAssociative(errors);
      return;
    }
    if (this.formUser.confirm_password !== this.formUser.password) {
      this.formUserErrors = { confirm_password: ["confirm_password does not match"] };
      return;
    }

    try {
      await users.registerUser(this.formUser);
      const response = users.registerResponse;
      if (isTypeUserSuccess(response)) {
        this.closeUserModal();
        await this.getUsers();
        this.tableKey += 1; // re-renders the initial table markup
        notifyUser("Success", "Added new user.", "success");
      } else if (isTypePaymentAPIError(response)) {
        notifyUser("Add User Failed", response.message, "error");
      }
    } catch (error) {
      notifyUser(
          "Add User Error",
          "Unknown error. Please try again.",
          "error",
      );
    }
  }

  created() {
    this.isUserAdmin = isUserRoleAdmin();
    this.getUsers();
  }

  updated() {
    const timeOutms = 300;
    setTimeout(() => {
      ($("#datable_1") as any).DataTable({
        responsive:  {
          details: {
              type: "column",
          },
        },
        autoWidth: false,
        language: {
          search: "",
          searchPlaceholder: "Search",
          lengthMenu: "_MENU_items",
        },
        retrieve: true,
      });
    }, timeOutms);
  }

  get allUsers() {
    return this.usersList;
  }
}
